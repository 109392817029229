import axios, { AxiosRequestConfig } from "axios"

type DeliveryInformation = {
  date: Date
  statusOnDelivery: string
  targetEmail: string
}

type AdditionalService = {
  name?: string
  price?: number
}

type User = {
  firstName?: string
  lastName?: string
}

type ContactPerson = {
  name?: string
  phone?: string
  email?: string
}

export type WorkData = {
  assignmentNumber?: string
  propertyOwner?: string
  objectAddress?: string
  propertyDesignation?: string
  municipality?: string
  objectCity?: string
  objectZipCode?: string
  clientName?: string
  clientPhone?: string
  clientMail?: string
  clientAddress?: string
  clientCity?: string
  clientZipCode?: string
  clientIdNumber?: string
  price?: number
  inspection?: string
  inspector?: any
  timeOfInspection?: Date
  dateOfInspection?: null | Date
  endDateOfInspection?: null | Date
  lead?: string
  otherInformation?: string
  report?: any | any[]
  internalDocuments?: any | any[]
  status?: string
  createdAt?: Date
  updatedAt?: Date
  _id?: string
  inspectorFee?: number
  service?: string
  servicePrice?: number
  clientEmailSent?: boolean
  inspectorEmailSent?: boolean
  additionalService?: AdditionalService[]
  invoiceReceived?: boolean
  emailToInspectorDeliveryInformation?: DeliveryInformation[]
  emailToCustomerDeliveryInformation?: DeliveryInformation[]
  createdBy?: User
  updatedBy?: User
  contactPerson?: ContactPerson
}

const addWork = async (data: WorkData, saveToCustomerRegistry: boolean) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/work?saveToCustomerRegistry=${saveToCustomerRegistry}`,
    data,
    config
  )

  return response.data
}

export default addWork
