import React, { FC, useEffect, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import colors from "ui"
import DialogContent from "@material-ui/core/DialogContent"
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  Switch,
  Theme,
} from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import JobInput from "components/JobInput"
import DateTimePicker from "components/BlockedDateModal/DateTimePicker"
import UserStore from "store/UserStore"
import useStore from "global-hook-store"

import AllowedRole from "components/AllowedRole"
import authStore from "store/authStore"
import blockedDateStore from "store/blockedDateStore"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up("md")]: {
        maxHeight: "700px",
        minHeight: "700px",
        height: "700px",
        padding: "8px 24px",
      },

      padding: "8px 12px",
      display: "flex",
      width: "100%",
      flex: 1,
      "&::-webkit-scrollbar": {
        width: 0,
        background: "transparent",
      },
    },
    form: {
      height: "100%",
      width: "100%",

      display: "flex",
      flexDirection: "column",
    },
    switchContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    switchLabel: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      color: colors.zodiac,
    },
    switchTrack: {
      backgroundColor: "lightgray",
    },
    switchBase: {
      color: "lightgray",

      "&.Mui-checked": {
        color: colors.picton,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: colors.picton,
        opacity: 0.5,
      },
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      flex: 1,
      alignItems: "flex-end",
      padding: "15px 0 10px",
    },
    submitButton: {
      backgroundColor: colors.picton,
      color: colors.white,
      textTransform: "none",
      fontFamily: "Montserrat",
      marginLeft: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      borderRadius: "2px",
    },
    cancelButton: {
      color: colors.picton,
      textTransform: "none",
      fontFamily: "Montserrat",
      padding: "6px 12px",
      fontSize: "14px",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#F5F7F9",
      margin: "7.5px 0",
      maxHeight: "56px",
    },
    select: {
      width: "100%",
      maxHeight: "56px",
      padding: "18.5px 14px",
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      color: "gray",
      fontSize: "14px",
    },
    sectionContainer: {
      marginTop: "20px",
      width: "100%",
    },
    errorMessage: {
      color: "red",
      fontFamily: "Montserrat",
      margin: 0,
    },
    button: {
      width: "100%",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
    selectMenu: { maxHeight: "300px" },
    deleteContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "25px",
    },
    deleteAction: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "15px",
      borderRadius: "50%",
      cursor: "pointer",
      border: "1px solid lightgray",
      "&:hover": {
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      },
    },
    confirmDelete: {
      margin: 0,
      fontFamily: "Montserrat",
    },
    deleteButtonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    deleteButton: {
      backgroundColor: "red",
      borderRadius: 0,
      color: "white",
      marginLeft: "5px",
      textTransform: "none",
    },
    cancelDeleteButton: {
      backgroundColor: "transparent",
      borderRadius: 0,
      color: "red",
      marginRight: "5px",
      border: `1px solid red`,
      textTransform: "none",
    },
    loadingContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
)

type RequestState = { success: boolean; error: boolean; errorMessage: string }

type Props = { blockedDateId: string; close: () => void }

const AddBlockedDate: FC<Props> = ({ blockedDateId, close }) => {
  const classes = useStyles()
  const { register, handleSubmit, errors, control } = useForm<any>()

  const {
    state: { users },
    actions: { setUsers },
  } = useStore(UserStore)

  const {
    state: { blockedDate, updatedBlockedDate, deletedBlockedDate },
    actions: {
      setBlockedDate,
      resetBlockedDate,
      updateBlockedDate,
      deleteBlockedDate,
    },
  } = useStore(blockedDateStore)

  const {
    state: { role, id },
  } = useStore(authStore)

  const [requestState, setRequestState] = useState<RequestState>({
    success: false,
    error: false,
    errorMessage: "",
  })

  const [isDeleteState, setIsDeleteState] = useState<boolean>(false)

  const initialDate: any = new Date()

  // ---- START TIME OF INSPECTION
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())

  // ---- END TIME OF INSPECTION
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date())

  useEffect(() => {
    // Get available users
    setBlockedDate(blockedDateId)
    setUsers()

    return () => {
      resetBlockedDate()
    }
  }, [])

  useEffect(() => {
    if (blockedDate?.data?.from) {
      const dynamicDate = new Date(blockedDate?.data?.from)

      setSelectedDate(dynamicDate)
    }
  }, [blockedDate?.data?.from])

  useEffect(() => {
    if (blockedDate?.data?.to) {
      const dynamicDate = new Date(blockedDate?.data?.to)

      setSelectedEndDate(dynamicDate)
    }
  }, [blockedDate?.data?.to])

  const deleteAndCloseModal = async (id: string) => {
    const res = await deleteBlockedDate(id)

    const deletedRes = res.deletedBlockedDate

    // IF ERROR - RETURN
    if (deletedRes.error) {
      // Set error messsage here if needed
      return
    }
    close()
  }
  // Creates jobs and redirects to correct job
  const addBlockedDateAndRedirect = async (id: string, data: any) => {
    const res = await updateBlockedDate({ id: blockedDateId, data })

    const updatedRes = res.updatedBlockedDate

    // IF ERROR - RETURN
    if (updatedRes.error) {
      // Set error messsage here if needed
      return
    }

    close()
  }

  // SUBMIT DATA
  const onSubmit = (data: any) => {
    if (selectedDate && selectedEndDate) {
      if (selectedEndDate > selectedDate) {
        const body = {
          inspectorId: role === "inspector" ? id : data.user,
          description: data.description,
          to: selectedEndDate,
          from: selectedDate,
        }
        addBlockedDateAndRedirect(id, body)
      } else {
        // SET ERROR STATE
        setRequestState({
          success: false,
          error: true,
          errorMessage: "Slutdatum måste vara senare än startdatum",
        })
      }
    } else {
      // SET ERROR STATE
      setRequestState({
        success: false,
        error: true,
        errorMessage: "Ange både start- och sluttid",
      })
    }
  }

  return (
    <>
      <DialogContent classes={{ root: classes.content }}>
        {!blockedDate.loading ? (
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            {/* TODO: MAKE COMPONENT OUT OF THIS */}
            <AllowedRole roles={["admin", "sales"]}>
              <div className={classes.sectionContainer}>
                <p className={classes.label}>Användare</p>
                <FormControl
                  className={classes.formControl}
                  error={errors.user && true}
                >
                  <Controller
                    name="user"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={blockedDate.data?.inspectorId || []}
                    render={({ onChange, value, name }) => (
                      <Select
                        disableUnderline
                        value={value || []}
                        multiple
                        classes={{
                          root: classes.select,
                        }}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        name={name}
                        input={<Input />}
                        renderValue={(selected) => {
                          var findCorrectInspectors = (selected as any[]).map(
                            (value) => {
                              let inspector = users?.data.find(
                                (x) => x._id === value
                              )
                              return `${inspector?.firstName} ${inspector?.lastName}`
                            }
                          )
                          return (findCorrectInspectors as string[]).join(", ")
                        }}
                        // IFALL TIDSINTERVALLEN FRÅN API STÄMMER MED DEN TIDSINTERVALLEN I KLIENTEN SÅ FÅR "DEFAULT VÄRDE VARA AKTIVA" - ANNARS GÖR EN VANLIGA CHECK
                      >
                        {users &&
                          users.data?.map((inspector: any) => (
                            <MenuItem
                              key={inspector._id}
                              value={inspector._id}
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ margin: 0 }}>
                                  {`${inspector.firstName} ${inspector.lastName}`}
                                </p>
                              </div>

                              <p style={{ margin: 0, color: "gray" }}>
                                {inspector.city}
                              </p>
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </AllowedRole>
            <JobInput
              name="description"
              label="Notering"
              register={register()}
              multiline
              defaultValue={blockedDate.data?.description}
            />
            <DateTimePicker
              title="Startdatum"
              id="start-date"
              initialDate={initialDate}
              selectedDate={selectedDate}
              changeDate={setSelectedDate}
            />

            <DateTimePicker
              title="Slutdatum"
              id="end-date"
              initialDate={initialDate}
              selectedDate={selectedEndDate}
              changeDate={setSelectedEndDate}
            />
            {requestState.error && (
              <p className={classes.errorMessage}>
                {requestState.errorMessage}
              </p>
            )}
            <div className={classes.deleteContainer}>
              {isDeleteState ? (
                <div className={classes.confirmDelete}>
                  {deletedBlockedDate.loading ? (
                    <CircularProgress size="35px" />
                  ) : (
                    <>
                      <p>Vill du ta bort den här tiden permanent?</p>
                      <div className={classes.deleteButtonContainer}>
                        <Button
                          className={classes.cancelDeleteButton}
                          onClick={() => setIsDeleteState(false)}
                        >
                          Nej
                        </Button>
                        <Button
                          className={classes.deleteButton}
                          onClick={() => deleteAndCloseModal(blockedDateId)}
                        >
                          Ja
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className={classes.deleteAction}
                  onClick={() => setIsDeleteState(true)}
                >
                  <DeleteOutlineOutlinedIcon htmlColor="red" />
                </div>
              )}
            </div>
            {!isDeleteState && (
              <div className={classes.buttonContainer}>
                <Button
                  onClick={close}
                  style={{
                    marginRight: "10px",
                    backgroundColor: colors.white,
                    border: `1px solid ${colors.picton}`,
                    color: colors.picton,
                  }}
                  className={classes.button}
                >
                  Avbryt
                </Button>
                <Button
                  type="submit"
                  disabled={updatedBlockedDate.loading}
                  className={classes.button}
                  classes={{ disabled: classes.addDisabled }}
                  key={`${updatedBlockedDate.loading}`}
                >
                  {updatedBlockedDate.loading ? (
                    <CircularProgress size="25px" style={{ color: "white" }} />
                  ) : (
                    "Spara"
                  )}
                </Button>
              </div>
            )}
          </form>
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress size="35px" />
          </div>
        )}
      </DialogContent>
    </>
  )
}

export default AddBlockedDate
