import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"

import DeliveryInformation from "components/EmailDeliveryInformation/DeliveryInformationItem"

const useStyles = makeStyles({
  deliveryInformationContainer: {
    marginBottom: "20px",
  },
})

type DeliveryInformation = {
  date: Date
  statusOnDelivery: string
  targetEmail: string
}

type Props = { emails: DeliveryInformation[] }

const EmailDeliveryInformation: FC<Props> = ({ emails }) => {
  const classes = useStyles()

  return (
    <div className={classes.deliveryInformationContainer}>
      {emails.map((information, index) => (
        <DeliveryInformation
          key={`${information.targetEmail} + ${index}`}
          information={information}
        />
      ))}
    </div>
  )
}

export default EmailDeliveryInformation
