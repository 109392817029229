import axios, { AxiosRequestConfig } from "axios"
import { Inspector } from "./addUser"

const updateUser = async (id: string, user: Inspector) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }

  return await axios.put(
    `${process.env.REACT_APP_API_URL}/user/${id}`,
    user,
    config
  )
}

export default updateUser
