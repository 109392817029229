import { asyncState, createStore } from "global-hook-store"
import getEmailFiles from "../api/getEmailFiles"
import sendEmail from "../api/sendEmail"
type emailFile = {
  createdAt: string
  name: string
  updatedAt: string
  _id: string
}
const fileStore = createStore(
  {
    emailFiles: asyncState<emailFile[]>([]),
  },
  {
    setEmailFiles: async (_, __: null, { asyncAction }) =>
      asyncAction("emailFiles", getEmailFiles()),
  }
)

export default fileStore
