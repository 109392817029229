import axios, { AxiosRequestConfig } from "axios"

const deleteUser = async (id: string) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }

  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/user/${id}`,
    config
  )
}

export default deleteUser
