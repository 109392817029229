import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { Theme, IconButton, Button, Hidden, TextField } from "@material-ui/core"
import colors from "../ui"
import { history } from "../pages/Admin"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import MenuIcon from "@material-ui/icons/Menu"
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined"
import { useForm } from "react-hook-form"
import authStore from "../store/authStore"
import useStore from "global-hook-store"
import workStore from "../store/workStore"
import InspectorStore from "../store/InspectorStore"
import usePrevious from "utils/usePrevious"

type Props = {
  handleSearchDrawer?: () => void
  handleDrawerToggle?: () => void
  users?: boolean
  path: string
} & WithStyles<typeof styles>

const JobHeaderContent: React.FC<Props> = ({
  classes,
  users = false,
  path,
  handleSearchDrawer,
  handleDrawerToggle,
}) => {
  const { register, handleSubmit, errors, control } = useForm()

  const [nameValue, setNameValue] = useState<string>("")
  const [addressValue, setAddressValue] = useState<string>("")
  const {
    state: { isSearching },
    actions: { setSearch, setWorks, setLoadingWorks },
  } = useStore(workStore)

  const {
    state: { sortedInspector },
  } = useStore(InspectorStore)

  const {
    state: { isAdmin },
  } = useStore(authStore)

  const prevNameValue = usePrevious(nameValue)
  const prevAddressValue = usePrevious(nameValue)

  useEffect(() => {
    if (prevNameValue !== undefined && prevAddressValue !== undefined) {
      if (nameValue.length < 1 && addressValue.length < 1) {
        setSearch({ isSearching: false, name: "", address: "" })
      }
    }
  }, [nameValue, addressValue])

  const onSubmit = (data: any): void => {
    if (data.name.length > 0 || data.address.length > 0) {
      setSearch({ isSearching: true, name: data.name, address: data.address })
      setWorks({
        clientName: data.name,
        objectAddress: data.address,
        inspector: "",
      })
    }
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      {!users && (
        <Hidden smDown>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
            <TextField
              inputRef={register({ minLength: 1 })}
              margin="normal"
              name="name"
              type="text"
              variant="outlined"
              placeholder="Namn"
              autoComplete="off"
              onChange={(e) => setNameValue(e.target.value)}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.labelFocused,
                },
              }}
            />
            <TextField
              inputRef={register({ minLength: 1 })}
              margin="normal"
              name="address"
              type="text"
              variant="outlined"
              placeholder="Adress"
              autoComplete="off"
              onChange={(e) => setAddressValue(e.target.value)}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.labelFocused,
                },
              }}
            />
            <Button type="submit" className={classes.button}>
              <SearchOutlinedIcon
                fontSize="small"
                className={classes.SearchIcon}
              />
            </Button>
          </form>
        </Hidden>
      )}

      <div style={{ display: "flex" }}>
        {isAdmin && (
          <Button
            onClick={() => history.push(`${path}/new`)}
            // edge="start"
            // color="inherit"
            className={users ? classes.addUserBtn : classes.addJobBtn}
          >
            <AddOutlinedIcon fontSize="small" />
            <p className={classes.btnText}>{users ? "användare" : "arbete"}</p>
          </Button>
        )}
        {!users && (
          <Hidden mdUp>
            <IconButton
              onClick={handleSearchDrawer}
              edge="start"
              color="inherit"
              className={classes.addButton}
            >
              <SearchOutlinedIcon />
            </IconButton>
          </Hidden>
        )}
      </div>
    </>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {},
    menuButton: {
      marginRight: spacing(2),
      color: colors.zodiac,

      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    addJobBtn: {
      padding: "10px",
      marginRight: "25px",
      "& svg": {
        fontSize: "16px",
      },
      [breakpoints.up("md")]: {
        marginRight: 0,
      },
    },
    addUserBtn: {
      padding: "10px",
      "& svg": {
        fontSize: "16px",
      },
    },
    addButton: { color: colors.zodiac },
    btnText: {
      fontSize: "14px",
      letterSpacing: "1px",
      margin: 0,
      paddingLeft: "5px",
      fontFamily: "montserrat",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      margin: "0 auto",
      "*:focus": {
        outline: "none",
      },
    },
    textField: {
      width: "275px",
      margin: 0,
      backgroundColor: "#F5F7F9",
      fontFamily: "Montserrat",
      // borderRadius: "5px 0 0 5px",
      borderRadius: 0,

      "input:focus": {
        outline: "none",
      },
    },
    input: {
      fontFamily: "Montserrat",
      height: "30px",
      fontSize: "14px",
    },
    rootInput: {
      height: "40px",
      outline: "none",
      border: "1px solid #E4E4E4",
      borderRight: "none",
      // borderRadius: "5px 0 0 5px",
      borderRadius: 0,
    },
    notchedOutline: {
      border: "none",
    },
    outline: {
      borderRadius: "none",
    },
    label: {
      fontFamily: "Montserrat",
    },
    labelFocused: {},
    button: {
      width: "50px",
      minWidth: "50px",
      height: "40px",
      backgroundColor: colors.picton,
      margin: "0",
      color: "white",
      textTransform: "capitalize",
      fontSize: "16px",
      fontFamily: "Montserrat",
      // borderRadius: "0 5px 5px 0",
      borderRadius: 0,
    },
    SearchIcon: {
      fontSize: "24px",
      color: colors.white,
    },
  })

export default withStyles(styles)(JobHeaderContent)
