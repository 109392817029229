import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { Theme, Drawer, TextField, Button } from "@material-ui/core"
import { useForm } from "react-hook-form"
import SearchIcon from "@material-ui/icons/Search"
import colors from "../ui"
import useStore from "global-hook-store"
import navStore from "../store/navStore"
import workStore from "../store/workStore"
import usePrevious from "../utils/usePrevious"

type Props = {} & WithStyles<typeof styles>

const Dropdown: React.FC<Props> = ({ classes }) => {
  const {
    state: { searchOpen },
    actions: { setSearchOpen },
  } = useStore(navStore)

  const { register, handleSubmit, errors, control } = useForm()
  const [nameValue, setNameValue] = useState<string>("")
  const [addressValue, setAddressValue] = useState<string>("")

  const {
    state: { isSearching },
    actions: { setSearch, setWorks },
  } = useStore(workStore)

  const handleClose = () => {
    setSearchOpen(false)
  }

  const prevNameValue = usePrevious(nameValue)
  const prevAddressValue = usePrevious(nameValue)

  useEffect(() => {
    if (prevNameValue !== undefined && prevAddressValue !== undefined) {
      if (nameValue.length < 1 && addressValue.length < 1) {
        setSearch({ isSearching: false, name: "", address: "" })
      }
    }
  }, [nameValue, addressValue])

  const onSubmit = (data: any): void => {
    if (data.name.length > 0 || data.address.length > 0) {
      setSearch({ isSearching: true, name: data.name, address: data.address })
      setWorks({
        clientName: data.name,
        objectAddress: data.address,
        inspector: "",
      })

      handleClose()
    }
  }

  return (
    <Drawer anchor="top" open={searchOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
        <TextField
          onChange={(e) => setNameValue(e.target.value)}
          inputRef={register({ minLength: 1 })}
          margin="normal"
          name="name"
          type="text"
          variant="outlined"
          placeholder="Namn"
          autoComplete="off"
          classes={{ root: classes.textField }}
          InputProps={{
            classes: {
              notchedOutline: classes.outline,
              input: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.labelFocused,
            },
          }}
        />
        <TextField
          onChange={(e) => setAddressValue(e.target.value)}
          inputRef={register({ minLength: 1 })}
          margin="normal"
          name="address"
          type="text"
          variant="outlined"
          placeholder="Adress"
          autoComplete="off"
          classes={{ root: classes.textField }}
          InputProps={{
            classes: {
              notchedOutline: classes.outline,
              input: classes.input,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.labelFocused,
            },
          }}
        />
        <Button type="submit" className={classes.button}>
          <SearchIcon className={classes.SearchIcon} />
        </Button>
      </form>
    </Drawer>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {},
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px 10px",
      [breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    textField: {
      width: "90%",
      margin: 0,
      backgroundColor: "#F5F7F9",
      fontFamily: "Montserrat",
      [breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: "5px",
      },
    },
    input: {
      fontFamily: "Montserrat",
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    outline: {
      border: "1px solid #E4E4E4",
      borderRadius: 0,
    },
    label: {
      fontFamily: "Montserrat",
    },
    labelFocused: {},
    button: {
      width: "56px",
      height: "56px",
      backgroundColor: colors.picton,
      margin: "0",
      borderRadius: 0,
      color: "white",
      textTransform: "capitalize",
      fontSize: "16px",
      fontFamily: "Montserrat",
      [breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "100%",
      },
    },
    SearchIcon: {
      fontSize: "28px",
      color: colors.white,
    },
  })

export default withStyles(styles)(Dropdown)
