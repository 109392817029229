import * as React from "react"
import colors from "../../ui"
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
  ListItem,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined"

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined"
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined"
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined"
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined"
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined"
import authStore from "../../store/authStore"
import useStore from "global-hook-store"
import AllowedRole from "../AllowedRole"

type Props = { onClose?: () => void } & WithStyles<typeof styles>

const NavListItem: React.FC<Props> = ({ classes, onClose }) => {
  const {
    state: { isAdmin },
  } = useStore(authStore)
  return (
    <>
      <AllowedRole roles={["admin", "sales", "inspector"]}>
        <Link to="/jobs" onClick={onClose} className={classes.link}>
          <ListItem
            style={{ borderTop: "0.5px solid #f1f1f1" }}
            button={true}
            className={classes.listItem}
          >
            <div className={classes.btnInfo}>
              <AssignmentOutlinedIcon />

              <p>Arbeten</p>
            </div>
          </ListItem>
        </Link>
      </AllowedRole>

      <AllowedRole roles={["admin", "sales", "inspector"]}>
        <Link to="/calender" onClick={onClose} className={classes.link}>
          <ListItem button={true} className={classes.listItem}>
            <div className={classes.btnInfo}>
              <CalendarTodayOutlinedIcon />
              <p>Kalender</p>
            </div>
          </ListItem>
        </Link>
      </AllowedRole>

      <AllowedRole roles={["admin", "sales"]}>
        <Link to="/statistics" onClick={onClose} className={classes.link}>
          <ListItem button={true} className={classes.listItem}>
            <div className={classes.btnInfo}>
              <ShowChartOutlinedIcon />

              <p>Statistik</p>
            </div>
          </ListItem>
        </Link>
      </AllowedRole>

      <AllowedRole roles={["admin", "sales"]}>
        <Link to="/users" onClick={onClose} className={classes.link}>
          <ListItem button={true} className={classes.listItem}>
            <div className={classes.btnInfo}>
              <PeopleAltOutlinedIcon />

              <p>Användare</p>
            </div>
          </ListItem>
        </Link>
      </AllowedRole>

      <AllowedRole roles={["admin", "sales"]}>
        <Link
          to="/customer-registry"
          onClick={onClose}
          className={classes.link}
        >
          <ListItem button={true} className={classes.listItem}>
            <div className={classes.btnInfo}>
              <SubjectOutlinedIcon />

              <p>Kundregister</p>
            </div>
          </ListItem>
        </Link>
      </AllowedRole>
    </>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      justifyContent: "center",
      borderBottom: "0.5px solid #f1f1f1",
      padding: "20px 0",
      fontFamily: "Montserrat",
      [breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "flex-start",
      },
    },
    btnInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        fontSize: "25px",
      },
      "& p": {
        fontSize: "12px",
        margin: 0,
        paddingTop: "5px",
      },
      [breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "flex-start",
        paddingLeft: "15px",
        "& p": {
          paddingLeft: "10px",
        },
      },
    },
    link: {
      textDecoration: "none",
      color: colors.zodiac,
    },
  })

export default withStyles(styles)(NavListItem)
