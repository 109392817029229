import axios, { AxiosRequestConfig } from "axios"

const getWorksByMonth = async (
  month?: number,
  year?: number,
  sortedInspector?: string,
  statusCalender?: string
) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  if (sortedInspector && statusCalender) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/year/${year}/month/${month}?inspectorId=${sortedInspector}&status=${statusCalender}`,
      config
    )
    return res.data
  } else if (sortedInspector) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/year/${year}/month/${month}?inspectorId=${sortedInspector}`,
      config
    )
    return res.data
  } else if (statusCalender) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/year/${year}/month/${month}?status=${statusCalender}`,
      config
    )
    return res.data
  } else {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/year/${year}/month/${month}`,
      config
    )
    return res.data
  }
}
export default getWorksByMonth
