import React, { useEffect, useMemo, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { Calendar, Views, momentLocalizer } from "react-big-calendar"
import debounce from "lodash/debounce"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import "react-big-calendar/lib/css/react-big-calendar.css"

import {
  Button,
  Theme,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core"
import moment from "moment"
import "moment/locale/sv"
import colors from "../ui"

import useStore from "global-hook-store"
import workStore from "../store/workStore"
import InspectorStore from "../store/InspectorStore"
import CalendarPopover from "./CalendarPopover"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import AllowedRole from "./AllowedRole"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import serviceToColor from "../utils/serviceToColor"
import completeCalenderTitle from "../utils/completeCalenderTitle"
import ToolbarContainer from "./ToolbarContainer"
import DateContainer from "./DateContainer"
// OLD MODAL
import InspectorModal from "./InspectorModal"

// NEW MODAL
import CalendarModal from "components/CalendarModal"
import blockedDateStore from "store/blockedDateStore"
import BlockedDateModal from "components/BlockedDateModal"
import usePrevious from "utils/usePrevious"

type Props = {} & WithStyles<typeof styles>
type AdditionalService = {
  name?: string
  price?: number
}

moment.locale("sv")

const Event = (data: any) => {
  const convertDateToLocaleTimeString = (date: Date) =>
    new Date(date).toLocaleTimeString("sv-SE", {
      hour: "2-digit",
      minute: "2-digit",
    })

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p style={{ margin: "0 5px 0 2px", fontSize: "12px" }}>
        {`${convertDateToLocaleTimeString(data.event.start)}`}
      </p>
      <p style={{ margin: 0, fontWeight: 500 }}>{data.title}</p>
    </div>
  )
}

const CustomToolbar = (toolbar: any) => {
  const {
    state: { inspectors, sortedInspector, statusCalender },
    actions: { setSortedInspector, setStatus, setInspectors },
  } = useStore(InspectorStore)

  // INIT
  useEffect(() => {
    setInspectors()
  }, [])

  const statuses = [
    { dbName: "unprocessed", svName: "obehandlad" },
    { dbName: "booked", svName: "bokad" },
    { dbName: "ongoing", svName: "pågående" },
    { dbName: "sent", svName: "skickad" },
    { dbName: "completed", svName: "klar" },
    { dbName: "blocked", svName: "Upptagen" },
  ]

  const handleChange = (event: any) => {
    setSortedInspector(event.target.value)
  }

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value)
  }

  const changeView = (event: any) => {
    const view = event.target.value
    toolbar.onView(view)
  }

  const goToBack = () => {
    toolbar.onNavigate("PREV")
  }

  const goToNext = () => {
    toolbar.onNavigate("NEXT")
  }

  const goToCurrent = () => {
    toolbar.onNavigate("TODAY")
  }

  const label = () => {
    const date = moment(toolbar.date)
    return (
      <span>
        <b>{date.format("MMMM")}</b>
        <span>
          {" "}
          {date.format(`YYYY${toolbar.view === "day" ? "-DD" : ""}`)}
        </span>
      </span>
    )
  }
  const theme = useTheme()

  const belowTablet = useMediaQuery(theme.breakpoints.down("xs"))

  const desktop = { display: "flex" }
  const mobile = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
  }
  return (
    <ToolbarContainer>
      <DateContainer>
        <div style={{ marginRight: "20px", display: "flex" }}>
          <Button onClick={goToBack}>
            <ArrowBackIosOutlinedIcon style={{ fontSize: "14px" }} />
          </Button>
          <Button
            style={{ textTransform: "capitalize", fontFamily: "montserrat" }}
            onClick={goToCurrent}
          >
            Idag
          </Button>
          <Button onClick={goToNext}>
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "14px" }} />
          </Button>
        </div>
        <label
          style={{
            textTransform: "capitalize",
            fontSize: "18px",
            marginRight: "15px",
          }}
        >
          {label()}
        </label>
      </DateContainer>

      <AllowedRole roles={["admin", "sales", "inspector"]}>
        <div style={belowTablet ? mobile : desktop}>
          <Select
            displayEmpty
            disableUnderline
            defaultValue="month"
            onChange={changeView}
            style={{
              width: `${belowTablet ? "100%" : "150px"}`,
              padding: "0 10px",
              marginRight: "10px",
              marginTop: `${belowTablet ? "5px" : "0"}`,
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F5F7F9",
              border: "1px solid lightgray",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              fontSize: "14px",
            }}
          >
            <MenuItem
              style={{
                width: `${belowTablet ? "100%" : "200px"}`,
                textTransform: "capitalize",
                fontFamily: "Montserrat",
                fontSize: "14px",
              }}
              value="month"
            >
              <p style={{ margin: 0 }}>Månad</p>
            </MenuItem>
            <MenuItem
              style={{
                width: `${belowTablet ? "100%" : "200px"}`,
                textTransform: "capitalize",
                fontFamily: "Montserrat",
                fontSize: "14px",
              }}
              value="week"
            >
              <p style={{ margin: 0 }}>Vecka</p>
            </MenuItem>
            <MenuItem
              style={{
                width: `${belowTablet ? "100%" : "200px"}`,
                textTransform: "capitalize",
                fontFamily: "Montserrat",
                fontSize: "14px",
              }}
              value="day"
            >
              <p style={{ margin: 0 }}>Dag</p>
            </MenuItem>
          </Select>
          <Select
            displayEmpty
            disableUnderline
            value={statusCalender}
            onChange={handleChangeStatus}
            style={{
              width: `${belowTablet ? "100%" : "150px"}`,
              padding: "0 10px",
              marginRight: "10px",
              marginTop: `${belowTablet ? "5px" : "0"}`,
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F5F7F9",
              border: "1px solid lightgray",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              fontSize: "14px",
            }}
          >
            <MenuItem
              style={{
                width: `${belowTablet ? "100%" : "200px"}`,
                fontFamily: "Montserrat",
                fontSize: "14px",
                color: "gray",
              }}
              value=""
            >
              Status
            </MenuItem>
            {statuses.map((status: any) => (
              <MenuItem
                style={{
                  width: `${belowTablet ? "100%" : "200px"}`,
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
                key={status.dbName}
                value={status.dbName}
              >
                <p style={{ margin: 0 }}>{status.svName}</p>
              </MenuItem>
            ))}
          </Select>
          <Select
            displayEmpty
            disableUnderline
            value={sortedInspector}
            onChange={handleChange}
            style={{
              width: `${belowTablet ? "100%" : "200px"}`,
              marginTop: `${belowTablet ? "5px" : "0"}`,
              padding: "0 10px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F5F7F9",
              border: "1px solid lightgray",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              fontSize: "14px",
            }}
          >
            <MenuItem
              style={{
                width: `${belowTablet ? "100%" : "450px"}`,
                fontFamily: "Montserrat",
                fontSize: "14px",
                color: "gray",
              }}
              value=""
            >
              Besiktningsmän
            </MenuItem>
            {inspectors &&
              inspectors.data.map((inspector: any) => (
                <MenuItem
                  style={{
                    width: `${belowTablet ? "100%" : "450px"}`,
                    textTransform: "capitalize",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={inspector?._id}
                  value={inspector._id}
                >
                  <p style={{ margin: 0 }}>
                    {`${inspector.firstName} ${inspector.lastName}`}
                  </p>
                  <p style={{ margin: 0, color: "gray" }}>{inspector.city}</p>
                </MenuItem>
              ))}
          </Select>
        </div>
      </AllowedRole>
    </ToolbarContainer>
  )
}

const localizer = momentLocalizer(moment)
const NewCalendar: React.FC<Props> = ({ classes }) => {
  const [currentDate, setCurrentDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  })

  const [bookingDate, setBookingDate] = useState<Date | undefined>()

  const [calendarEvents, setCalendarEvents] = useState<any[]>([])

  const [open, setOpen] = useState(false)

  const [blockedDateOpen, setBlockedDateOpen] = useState({
    open: false,
    id: "",
  })

  const {
    state: {
      calendarWorks: { data: calendarWorks, loading: isWorksLoading, error },
    },
    actions: { setCalendarWorks },
  } = useStore(workStore)

  const {
    state: { sortedInspector, statusCalender },
  } = useStore(InspectorStore)

  const {
    state: { createdBlockedDate, updatedBlockedDate, deletedBlockedDate },
  } = useStore(blockedDateStore)

  useEffect(() => {
    if (statusCalender !== "blocked") {
      setCalendarWorks({
        month: currentDate.month + 1,
        year: currentDate.year,
        sortedInspector,
        statusCalender,
      })
    }
  }, [
    currentDate,
    setCalendarWorks,
    sortedInspector,
    statusCalender,
    createdBlockedDate,
    updatedBlockedDate,
    deletedBlockedDate,
  ])

  const totPrice = (price?: number, additionalServices?: any) => {
    const calcAdditionalPrice = (nein: any) => {
      let sum = 0

      nein?.length > 0 &&
        nein.map((x: any) => {
          sum = sum + x.price
        })

      return sum
    }
    const additionalServicePrice = calcAdditionalPrice(additionalServices)

    let parsedPrice = price ? price : 0
    let parsedAdditionalServicePrice = additionalServicePrice
      ? additionalServicePrice
      : 0

    return parsedPrice + parsedAdditionalServicePrice
  }

  let formatter = new Intl.NumberFormat("se-SE", {
    style: "currency",
    currency: "SEK",
  })

  useEffect(() => {
    const tja: any[] = []
    const jobs = calendarWorks.jobs
    const blockedDates = calendarWorks.blockedDates

    let currentEvents: any[] = []

    if (statusCalender === "") {
      currentEvents = [...jobs, ...blockedDates]
    } else if (statusCalender === "blocked") {
      currentEvents = [...blockedDates]
    } else {
      currentEvents = [...jobs]
    }

    currentEvents?.length > 0 &&
      currentEvents.map((x) =>
        x.status
          ? tja.push({
              type: "work",
              title: completeCalenderTitle(x.inspection, x.objectCity),
              start: x.dateOfInspection && new Date(x.dateOfInspection),
              color: serviceToColor(x.inspection),
              clientName: x.clientName,
              inspection: x.inspection,
              objectAddress: x.objectAddress,
              objectCity: x.objectCity,
              price: formatter.format(totPrice(x.price, x.additionalService)),
              status: x.status,
              timeOfInspection: moment(x.dateOfInspection).format("lll"),
              endTimeOfInspection: moment(x.endDateOfInspection).format("lll"),
              id: x._id,
              end: x.endDateOfInspection
                ? new Date(x.endDateOfInspection)
                : new Date(moment(x.dateOfInspection).add(60, "m").toDate()),
            })
          : tja.push({
              type: "blocked",
              title: `Upptagen`,
              description: x.description,
              inspector: x.inspector,
              color: "black",
              blockedFrom: moment(x.from).format("lll"),
              blockedTo: moment(x.to).format("lll"),
              id: x.id,
              start: new Date(x.from),
              end: new Date(x.to),
            })
      )

    setCalendarEvents(tja)
  }, [calendarWorks, statusCalender])

  const getPos = (event: any) => {
    event.start && setBookingDate(event.start)
    setOpen(true)
  }

  // Debounce to make sure excessive spamming is avoided
  const debounceUpdateMonths = debounce(
    (payload) => setCurrentDate(payload),
    400
  )

  const prevMonth = usePrevious(currentDate.month)
  const getMonthYear = (date: Date) => {
    if (prevMonth !== date.getMonth()) {
      debounceUpdateMonths({ year: date.getFullYear(), month: date.getMonth() })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }
  const eventColors = (event: any) => {
    return {
      style: { backgroundColor: event.color, borderRadius: "5px" },
    }
  }

  const openBlockedDate = (id: string) => {
    setBlockedDateOpen({ open: true, id })
  }

  const closeBlockedDate = () => {
    setBlockedDateOpen({ open: false, id: "" })
  }

  // ### DETTA FUNGERAR MEN GÖR OM OCH GÖR RÄTT ###
  const WeekNumber = ({ week }: any) => <span>{`v${week}`}</span>

  const WeekDay = ({ weekDay }: any) => <span>{weekDay}</span>

  const DateHeader = ({ date }: any) => {
    const day = moment(date)

    return (
      <div className={day.weekday() === 0 ? classes.dayAndWeek : classes.day}>
        {day.weekday() === 0 && <WeekNumber week={day.week()} />}
        <WeekDay weekDay={day.format("D")} />
      </div>
    )
  }
  // ### DETTA FUNGERAR MEN GÖR OM OCH GÖR RÄTT ###

  return (
    <div className={classes.root}>
      {!error ? (
        <div style={{ width: "100%" }}>
          {isWorksLoading && (
            <div className={classes.loadingSpinner}>
              <CircularProgress />
            </div>
          )}

          {open && (
            <CalendarModal
              open={open}
              handleClose={handleClose}
              date={bookingDate}
            />
          )}

          {blockedDateOpen.open && (
            <BlockedDateModal
              open={blockedDateOpen.open}
              blockedDateId={blockedDateOpen.id}
              handleClose={closeBlockedDate}
            />
          )}

          <Calendar
            popup
            selectable
            localizer={localizer}
            events={calendarEvents && calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "85vh" }}
            className={classes.calendar}
            defaultDate={new Date()}
            views={{ month: true, week: true, day: true }}
            onSelectSlot={(event) => getPos(event)}
            longPressThreshold={150}
            eventPropGetter={eventColors}
            onNavigate={(date) => {
              getMonthYear(date)
            }}
            components={{
              event: Event,
              toolbar: CustomToolbar,
              month: {
                dateHeader: DateHeader,
              },
              eventWrapper: (props) => (
                <CalendarPopover
                  {...props}
                  data={props.event}
                  openBlockedDate={(id) => openBlockedDate(id)}
                />
              ),
            }}
          />
        </div>
      ) : (
        <div className={classes.error}>
          <ErrorOutlineOutlinedIcon style={{ fontSize: "28px" }} />
          <p>Error: Hämtning av kalender misslyckades.</p>
        </div>
      )}
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      [breakpoints.down("xs")]: {
        width: "100vw",
      },
    },
    calendar: {},
    pos: {
      position: "absolute",
      top: "331px",
      left: "965px",

      backgroundColor: colors.white,
      zIndex: 5,
      boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.1)",
      border: "1px solid #E4E4E4",
    },
    loadingSpinner: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",

      zIndex: 5,
    },
    error: {
      display: "flex",
      width: "100%",
      height: "50vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
      fontSize: "18px",
      fontFamily: "Montserrat",
      color: "red",
      "& p": {
        margin: 0,
        marginTop: "5px",
      },
    },
    dayAndWeek: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "5px",
    },
    day: {},
  })

export default withStyles(styles)(NewCalendar)
