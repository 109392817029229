import axios, { AxiosRequestConfig } from "axios"

const getUsers = async () => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/user`, config)
  return res.data
}

export default getUsers
