import React, { useEffect } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"

import {
  Theme,
  withWidth,
  isWidthUp,
  CircularProgress,
} from "@material-ui/core"

import { compose } from "recompose"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import UserItem from "../components/UserItem"
import useStore from "global-hook-store"
import navStore from "../store/navStore"
import UserStore from "../store/UserStore"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"

type Data = {
  id: number
  kund: string
  adress: string
  ort: string
  status: string
  module: string
  datum: string
  pris: string
}

type Row = {
  index: number
}
type OwnProps = {
  rowGetter: (row: Row) => Data
}

type Props = { width: Breakpoint } & OwnProps & WithStyles<typeof styles>

const Users: React.FC<Props> = ({ classes, width }) => {
  const {
    state: { addInspectorOpen },
    actions: { setAddInspectorOpen },
  } = useStore(navStore)

  const {
    state: { users },
    actions: { setUsers },
  } = useStore(UserStore)

  useEffect(() => {
    setUsers()
  }, [])

  return (
    <div className={classes.container}>
      {users?.loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {!users.error ? (
            <div className={classes.userContainer}>
              {users?.data?.length === 0 && <p>Inga tillgängliga användare</p>}

              {users?.data.map((user) => (
                <UserItem
                  key={user._id}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  phone={user.phone}
                  email={user.email}
                  city={user.city}
                  id={user._id}
                />
              ))}
            </div>
          ) : (
            <div className={classes.error}>
              <ErrorOutlineOutlinedIcon style={{ fontSize: "28px" }} />
              <p>Error: Hämtning av användare misslyckades.</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "85vh",
      width: "100%",
      // boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
      color: colors.zodiac,
      // backgroundColor: colors.white,
    },
    sortcontainer: {
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      width: "100%",
      height: "50px",
      backgroundColor: "#F8F8F8",
    },
    button: {
      backgroundColor: colors.picton,
      color: colors.white,
      textTransform: "initial",
      fontFamily: "Montserrat",
      padding: "5px 15px",
      fontSize: "12px",
      textDecoration: "none",
    },
    table: {
      minWidth: 650,
    },
    link: {
      textDecoration: "none",
      fontSize: "inherit",
    },
    itemContainer: {
      display: "flex",
      alignItems: "center",
      height: "125px",
      backgroundColor: "transparent",
    },
    item: {
      display: "flex",
      width: "99%",
      height: "100px",
      backgroundColor: colors.white,
      borderRadius: "2px",
    },
    status: {
      width: "7.5px",
      backgroundColor: "#1EE21A",
      height: "100%",
    },
    userContainer: {
      overflow: "auto",
      overflowAnchor: "none",
    },
    error: {
      display: "flex",
      width: "100%",
      height: "50vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
      fontSize: "18px",
      fontFamily: "Montserrat",
      color: "red",
      "& p": {
        margin: 0,
        marginTop: "5px",
      },
    },
  })
export default compose<Props, {}>(withWidth(), withStyles(styles))(Users)
