import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"
import debounce from "lodash/debounce"
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined"
import {
  Theme,
  Button,
  withWidth,
  Tabs,
  Tab,
  CircularProgress,
  MenuItem,
  Select,
  Drawer,
  List,
  ListItem,
  Hidden,
} from "@material-ui/core"

import JobItem from "../components/JobItem"
import { compose } from "recompose"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import useStore from "global-hook-store"
import workStore from "../store/workStore"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import moment from "moment"
import "moment/locale/sv"
import usePrevious from "../utils/usePrevious"
import InspectorStore from "../store/InspectorStore"

type Props = { width: Breakpoint } & WithStyles<typeof styles>

moment.locale("sv")

const drawerWidth = 100

const Jobs: React.FC<Props> = ({ classes, width }) => {
  const {
    state: {
      works: { data: works, loading: isWorksLoading, error },
      isSearching,
      searchValueName,
      searchValueAddress,
      loadMore,
    },
    actions: { setWorks, loadMoreWorks, setLoadMore, setSearch, resetWorks },
  } = useStore(workStore)

  const {
    state: { inspectors, sortedInspector },
    actions: { setSortedInspector, setInspectors },
  } = useStore(InspectorStore)

  const handleChangeInspector = (event: any) => {
    setSortedInspector(event.target.value)
  }

  const [value, setValue] = useState("booked")
  const [openStatus, setOpenStatus] = useState(false)
  const [openInspector, setOpenInspector] = useState(false)

  const [loadMoreIndex, setLoadMoreIndex] = useState(20)

  // INIT
  useEffect(() => {
    const runAsync = async () => {
      await setWorks({ status: value, inspector: sortedInspector })
      await setInspectors()
      setLoadMore(true)
      setLoadMoreIndex(20)
    }
    runAsync()

    return () => {
      const runUnmountAsync = async () => {
        await setSearch({ isSearching: false, name: "", address: "" })
        await resetWorks()
      }
      runUnmountAsync()
    }
  }, [])

  const prevValue = usePrevious(value)

  // WHEN CHANGING STATUS
  useEffect(() => {
    if (prevValue !== undefined) {
      if (prevValue !== value) {
        setWorks({ status: value, inspector: sortedInspector })
        setLoadMore(true)
        setLoadMoreIndex(20)
      }
    }
  }, [value])

  const prevInspector = usePrevious(sortedInspector)
  // WHEN CHANGING STATUS
  useEffect(() => {
    if (prevInspector !== undefined) {
      if (prevInspector !== sortedInspector) {
        setWorks({ status: value, inspector: sortedInspector })
        setLoadMore(true)
        setLoadMoreIndex(20)
      }
    }
  }, [sortedInspector])

  // WHEN SEARCH DIFFS
  const prevSearchValue = usePrevious(isSearching)
  useEffect(() => {
    if (!isSearching && prevSearchValue) {
      setWorks({ status: value, inspector: sortedInspector })
      setLoadMore(true)
      setLoadMoreIndex(20)
    }
  }, [isSearching])

  // WHEN SEARCH DIFFS
  useEffect(() => {
    if (searchValueName || searchValueAddress) {
      setLoadMore(true)
      setLoadMoreIndex(20)
    }
  }, [searchValueName, searchValueAddress])

  const datess = (k: Date) => {
    let hej = new Date(k)
    return hej.toLocaleDateString("sv-SE")
  }
  // Just to make sure excessive spamming is avoided
  const debounceChangeTab = debounce((payload) => setValue(payload), 100)

  const handleChangeTab = (event: any, newValue: string) => {
    debounceChangeTab(newValue)
  }

  const handleChangeCustom = async (value: string) => {
    await debounceChangeTab(value)
    setOpenStatus(false)
  }

  const handleInspectorChangeCustom = async (value: string) => {
    await setSortedInspector(value)
    setOpenInspector(false)
  }

  const handleClick = () => {
    if (isSearching) {
      loadMoreWorks({
        clientName: searchValueName,
        objectAddress: searchValueAddress,
        fromIndex: loadMoreIndex,
        inspector: "",
      })
      setLoadMoreIndex(loadMoreIndex + 20)
    } else {
      loadMoreWorks({
        status: value,
        fromIndex: loadMoreIndex,
        inspector: sortedInspector,
      })
      setLoadMoreIndex(loadMoreIndex + 20)
    }
  }
  const calcAdditionalPrice = (nein: any) => {
    let sum = 0

    nein?.length > 0 &&
      nein.map((x: any) => {
        sum = sum + x.price
      })

    return sum
  }

  return (
    <div className={classes.container}>
      {!isSearching && (
        <div className={classes.tabContainer}>
          <Hidden lgUp>
            {/* #### STATUS */}
            <button
              onClick={() => setOpenStatus(true)}
              style={{ marginRight: "2.5px" }}
              className={classes.toggleButton}
            >
              Status
            </button>
            <Drawer
              variant="temporary"
              anchor="left"
              open={openStatus}
              onClose={() => setOpenStatus(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <p className={classes.listHeading}>Status</p>
              <div className={classes.divider} />
              <List className={classes.list}>
                {[
                  { label: "Avbokad", value: "canceled" },
                  { label: "Bokad", value: "booked" },
                  { label: "Pågående", value: "ongoing" },
                  { label: "Utskickad", value: "sent" },
                  { label: "Klar", value: "completed" },
                ].map((item) => (
                  <ListItem
                    button
                    style={
                      value == item.value
                        ? {
                            color: "black",
                            textDecoration: "underline",
                            fontSize: "17px",
                          }
                        : { color: "#000000bf" }
                    }
                    onClick={() => handleChangeCustom(item.value)}
                    key={item.value}
                    className={classes.listItem}
                  >
                    <p>{item.label}</p>
                  </ListItem>
                ))}
              </List>
            </Drawer>

            {/* ##### INSPECTOR */}
            <button
              onClick={() => setOpenInspector(true)}
              style={{ marginLeft: "2.5px" }}
              className={classes.toggleButton}
            >
              Besiktningsman
            </button>
            <Drawer
              variant="temporary"
              anchor="right"
              open={openInspector}
              onClose={() => setOpenInspector(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <p className={classes.listHeading}>Besiktningsmän</p>
              <div
                className={classes.divider}
                style={{
                  borderBottom: "1px solid lightgray",
                  marginBottom: "15px",
                }}
              />

              <List className={classes.list}>
                <ListItem
                  button
                  style={
                    sortedInspector == ""
                      ? {
                          color: "black",
                          textDecoration: "underline",
                        }
                      : { color: "#000000bf" }
                  }
                  onClick={() => handleInspectorChangeCustom("")}
                  className={classes.listItem}
                >
                  <p>Alla besiktningsmän</p>
                </ListItem>
                {inspectors.data.map((inspector) => (
                  <ListItem
                    button
                    style={
                      sortedInspector == inspector._id
                        ? {
                            color: "black",
                            textDecoration: "underline",
                            fontSize: "15px",
                          }
                        : { color: "#000000bf" }
                    }
                    onClick={() => handleInspectorChangeCustom(inspector._id)}
                    key={inspector._id}
                    className={classes.listItemInspector}
                  >
                    <p>{`${inspector.firstName} ${inspector.lastName}`}</p>
                    <p style={{ color: "gray" }}>{inspector.city}</p>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Hidden>
          <Hidden mdDown>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab className={classes.tabs} label="Avbokad" value="canceled" />
              <Tab
                className={classes.tabs}
                label="Obehandlad"
                value="unprocessed"
              />
              <Tab className={classes.tabs} label="Bokad" value="booked" />
              <Tab className={classes.tabs} label="Pågående" value="ongoing" />
              <Tab className={classes.tabs} label="Utskickad" value="sent" />
              <Tab className={classes.tabs} label="Klar" value="completed" />
            </Tabs>

            <Select
              displayEmpty
              disableUnderline
              value={sortedInspector}
              onChange={handleChangeInspector}
              className={classes.select}
            >
              <MenuItem className={classes.menuItem} value="">
                Besiktningsmän
              </MenuItem>
              {inspectors &&
                inspectors.data.map((inspector: any) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={inspector?._id}
                    value={inspector._id}
                  >
                    <p style={{ margin: 0 }}>
                      {`${inspector.firstName} ${inspector.lastName}`}
                    </p>
                    <p style={{ margin: 0, color: "gray" }}>{inspector.city}</p>
                  </MenuItem>
                ))}
            </Select>
          </Hidden>
        </div>
      )}

      {isWorksLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {!error ? (
            <div className={classes.worksContainer}>
              {works?.length == 0 && <p>Inga tillgängliga arbeten</p>}
              {works?.length > 0 &&
                works.map((work, index) => (
                  <JobItem
                    key={index}
                    id={work?._id}
                    client={work.clientName}
                    address={work.objectAddress}
                    city={work.objectCity}
                    status={work.status}
                    inspection={work.inspection}
                    timeOfInspection={
                      work?.dateOfInspection &&
                      moment(work.dateOfInspection).format("LT")
                    }
                    dateOfInspection={
                      work?.dateOfInspection && datess(work.dateOfInspection)
                    }
                    price={work?.price}
                    additionalServicePrice={calcAdditionalPrice(
                      work?.additionalService
                    )}
                  />
                ))}
              {works.length > 19 && loadMore && (
                <div className={classes.btnContainer}>
                  <Button onClick={handleClick} className={classes.button}>
                    Ladda fler
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className={classes.error}>
              <ErrorOutlineOutlinedIcon style={{ fontSize: "28px" }} />
              <p>Error: Hämtning av arbeten misslyckades.</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "85vh",
      width: "100%",
      color: colors.zodiac,
      // backgroundColor: colors.white,
    },
    sortcontainer: {
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      width: "100%",
      height: "50px",
      backgroundColor: "#F8F8F8",
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      height: "50px",
      width: "100%",
      backgroundColor: colors.picton,
      color: colors.white,
      textTransform: "initial",
      fontFamily: "Montserrat",
      fontWeight: 500,
      letterSpacing: "1px",
      padding: "5px 15px",
      fontSize: "14px",
      textDecoration: "none",
      borderRadius: 0,
    },
    table: {
      minWidth: 650,
    },
    link: {
      textDecoration: "none",
      fontSize: "inherit",
    },
    itemContainer: {
      display: "flex",
      alignItems: "center",
      height: "125px",
      backgroundColor: "transparent",
    },
    item: {
      display: "flex",
      width: "99%",
      height: "100px",
      backgroundColor: colors.white,
      borderRadius: "2px",
    },
    status: {
      width: "7.5px",
      backgroundColor: "#1EE21A",
      height: "100%",
    },
    tabContainer: {
      backgroundColor: "white",
      borderTopLeftRadius: "2.5px",
      borderTopRightRadius: "2.5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [breakpoints.down("sm")]: {
        width: "100%",
        paddingTop: "10px",
        marginBottom: "5px",
      },
      [breakpoints.down("xs")]: {
        width: "100%",
        paddingTop: "20px",
        marginBottom: "5px",
      },
    },
    tabs: {
      fontFamily: "Montserrat",
    },
    worksContainer: {
      overflow: "auto",
      overflowAnchor: "none",
    },
    loading: {
      display: "flex",
      width: "100%",
      height: "50vh",
      justifyContent: "center",
      alignItems: "center",
    },
    error: {
      display: "flex",
      width: "100%",
      height: "50vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
      fontSize: "18px",
      fontFamily: "Montserrat",
      color: "red",
      "& p": {
        margin: 0,
        marginTop: "5px",
      },
    },
    menuItem: {
      width: "450px",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      fontSize: "14px",
      display: "flex",
      justifyContent: "space-between",
    },
    select: {
      width: "200px",
      padding: "0 10px",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#F5F7F9",
      border: "1px solid lightgray",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    drawerPaper: {
      borderRight: "none",
      padding: "0 20px",
      width: "30%",
      flexShrink: 0,
      [breakpoints.down("md")]: {
        width: "50%",
        flexShrink: 0,
      },
      [breakpoints.down("xs")]: {
        width: "75%",
        flexShrink: 0,
      },
    },
    listHeading: {
      margin: 0,
      fontFamily: "Montserrat",
      fontSize: "18px",
      colors: "black",
      padding: "15px 0",
      fontWeight: 500,
    },
    list: {
      padding: "0 0 20px",
    },
    listItem: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      // borderBottom: "1px solid gray",
      padding: "7.5px 0 7.5px 0",
      "& p": {
        margin: 0,
      },
    },
    listItemInspector: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      padding: "7.5px 0 7.5px 0",
      "& p": {
        margin: 0,
        textTransform: "capitalize",
      },
    },
    toggleButton: {
      width: "100%",
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      color: colors.zodiac,
      fontSize: "12px",
      letterSpacing: "1px",
      padding: "15px 0",
      backgroundColor: colors.white,
      borderRadius: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
      border: "1px solid lightgray",
    },
    divider: {
      height: "1px",
      backgroundColor: "lightgray",
      width: "100%",
      marginBottom: "15px",
    },
  })
export default compose<Props, {}>(withWidth(), withStyles(styles))(Jobs)
