const mailStatusTranslation = (status?: string) => {
  switch (status) {
    case "DELIVERED":
      return "Levererad"

    case "BOUNCED":
      return "Ej levererad"

    case "COMPLAINT":
      return "Complaint"

    default:
      return status
  }
}

export default mailStatusTranslation
