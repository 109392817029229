import React from "react"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import Typography from "@material-ui/core/Typography"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import DesktopItem from "components/UserItem/DesktopItem"
import MobileItem from "components/UserItem/MobileItem"

type User = {
  firstName: string
  lastName: string
  phone?: string
  email?: string
  city?: string
  id: string
  onClick?: () => void
}
type Props = { width: Breakpoint } & User

const UserItem: React.FC<Props> = ({
  width,
  id,
  firstName,
  lastName,
  phone,
  email,
  city,
}) => {
  return (
    <>
      {isWidthUp("md", width) ? (
        <DesktopItem
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          email={email}
          city={city}
          id={id}
        />
      ) : (
        <MobileItem
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          email={email}
          city={city}
          id={id}
        />
      )}
    </>
  )
}

export default withWidth()(UserItem)
