import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"
import DateFnsUtils from "@date-io/date-fns"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import usePrevious from "../utils/usePrevious"
import firstLetterUppercase from "utils/firstLetterUppercase"

import {
  Theme,
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Hidden,
  Popover,
  FormControlLabel,
  Checkbox,
  Input,
  Tooltip,
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form"
import JobInput from "../components/JobInput"
import FormSection from "../components/FormSection"
import Divider from "../components/Divider"
import { useParams } from "react-router-dom"
import { WorkData } from "../api/addWork"
import useStore from "global-hook-store"
import workStore from "../store/workStore"
import InspectorStore from "../store/InspectorStore"
import axios, { AxiosRequestConfig } from "axios"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import AllowedRole from "../components/AllowedRole"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import classNames from "classnames"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import AdditionalServices from "../data/AdditionalServices"
import Services from "../data/Services"
import moment from "moment"
import "moment/locale/sv"
import Leads from "../data/Leads"
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined"
import FileLoading from "../components/FileLoading"
import MailModal from "../components/MailModal"
import ErrorDisplay from "components/ErrorDisplay"
import EmailDeliveryInformation from "components/EmailDeliveryInformation"
import DeleteWorkDialog from "components/DeleteWorkDialog"
import authStore from "../store/authStore"

type DateError = {
  error: boolean
  message: string
}

type AdditionalService = {
  price?: number
  service?: string
}
type ParamTypes = {
  job: string
}

type Props = {} & WithStyles<typeof styles>

moment.locale("sv")

const Job: React.FC<Props> = ({ classes }) => {
  const {
    state: {
      work: { data: work, loading: isWorkLoading, error },
      updatedWork,
      deleteWorkResponse,
    },
    actions: { setWork, updateWork, resetWork },
  } = useStore(workStore)

  const {
    state: { availableInspectors },
    actions: { setAvailableInspectors },
  } = useStore(InspectorStore)

  const {
    state: { role },
  } = useStore(authStore)

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm<WorkData>()

  // <------ STATES
  // ADDITIONAL SERVICE STATE
  const [additionalServices, setAdditionalServices] = useState<
    AdditionalService[]
  >([])

  // OPEN DELETE DIALOG
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false)

  // INVOICE STATE
  const [invoiceReceived, setInvoiceReceived] = useState<boolean>(false)

  // MAIL STATES
  const [openMailClient, setOpenMailClient] = useState(false)
  const [openMailInspector, setOpenMailInspector] = useState(false)

  // START TIME OF INSPECTION
  const [selectedDate, setSelectedDate] = useState<null | Date>(null)

  // END TIME OF INSPECTION
  const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null)

  // DATE ERROR STATE
  const [dateError, setDateError] = useState<DateError>({
    message: "",
    error: false,
  })

  // EDIT
  const [edit, setEdit] = useState(false)

  // POPOVER ANCHOR STATES
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [anchorElEnd, setAnchorElEnd] = useState<HTMLButtonElement | null>(null)

  // HOUR / MINUTE STATES
  const [hour, setHour] = useState<number>(
    selectedDate ? selectedDate?.getHours() : 12
  )

  const [minute, setMinute] = useState<number>(
    selectedDate ? selectedDate?.getMinutes() : 0
  )

  const [endHour, setEndHour] = useState<number>(
    selectedEndDate ? selectedEndDate?.getHours() : 12
  )

  const [endMinute, setEndMinute] = useState<number>(
    selectedEndDate ? selectedEndDate?.getMinutes() : 0
  )

  // FILE STATES
  const [reportFiles, setReportFiles] = useState<string[]>([])
  const [reportFilesLoading, setReportFilesLoading] = useState<boolean>(false)

  const [internalFiles, setInternalFiles] = useState<string[]>([])
  const [internalFilesLoading, setInternalFilesLoading] = useState<boolean>(
    false
  )
  // ------> STATES

  useEffect(() => {
    const reports = work?.report
    const internalDocuments = work?.internalDocuments
    reports && setReportFiles(reports)
    internalDocuments && setInternalFiles(internalDocuments)
  }, [work?.report, work?.internalDocuments])

  const addAdditionalService = () => {
    const watchFields = getValues(["servicePrice", "service"])
    const price = watchFields["servicePrice"]
    const service = watchFields["service"]
    const additionalService: AdditionalService = { price, service }
    setAdditionalServices([...additionalServices, additionalService])
    setValue("servicePrice", "")
    setValue("service", "")
  }

  const clientToObject = () => {
    const watchFields = getValues([
      "clientName",
      "clientAddress",
      "clientZipCode",
      "clientCity",
    ])

    watchFields["clientName"]?.length &&
      setValue("propertyOwner", watchFields["clientName"])

    watchFields["clientAddress"]?.length &&
      setValue("objectAddress", watchFields["clientAddress"])

    watchFields["clientZipCode"]?.length &&
      setValue("objectZipCode", watchFields["clientZipCode"])

    watchFields["clientCity"]?.length &&
      setValue("objectCity", watchFields["clientCity"])
  }

  const objectToClient = () => {
    const watchFields = getValues([
      "propertyOwner",
      "objectAddress",
      "objectZipCode",
      "objectCity",
    ])

    watchFields["propertyOwner"]?.length &&
      setValue("clientName", watchFields["propertyOwner"])

    watchFields["objectAddress"]?.length &&
      setValue("clientAddress", watchFields["objectAddress"])

    watchFields["objectZipCode"]?.length &&
      setValue("clientZipCode", watchFields["objectZipCode"])

    watchFields["objectCity"]?.length &&
      setValue("clientCity", watchFields["objectCity"])
  }

  useEffect(() => {
    if (work?.dateOfInspection) {
      const dynamicDate = new Date(work.dateOfInspection)

      setSelectedDate(dynamicDate)
      setMinute(dynamicDate.getMinutes())
      setHour(dynamicDate.getHours())
    }
  }, [work?.dateOfInspection])

  useEffect(() => {
    if (work?.endDateOfInspection) {
      const dynamicDate = new Date(work.endDateOfInspection)

      setSelectedEndDate(dynamicDate)
      setEndMinute(dynamicDate.getMinutes())
      setEndHour(dynamicDate.getHours())
    }
  }, [work?.endDateOfInspection])

  let { job } = useParams<ParamTypes>()

  // ON MOUNT
  useEffect(() => {
    setWork(job)

    return () => {
      resetWork()
    }
  }, [])

  // Convert date to string so that useEffect triggers on all changes
  const selectedDateString = selectedDate?.toString()
  const selectedEndDateString = selectedEndDate?.toString()

  useEffect(() => {
    if (selectedDate && selectedEndDate) {
      // IF A START AND END DATE IS CHOOSEN THEN GET INSPECTORS FOR THAT PERIOD
      setAvailableInspectors({
        fromDate: selectedDate,
        toDate: selectedEndDate,
      })
    } else {
      // IF A START AND END DATE IS NOT CHOOSEN THEN GET ALL INSPECTORS
      setAvailableInspectors({
        fromDate: null,
        toDate: null,
      })
    }
  }, [selectedDateString, selectedEndDateString])

  const initialDate: any = new Date()
  initialDate.setHours(12, 0)

  useEffect(() => {
    if (!isWorkLoading) {
      if (work?.additionalService) {
        setAdditionalServices(work?.additionalService)
      } else {
        setAdditionalServices([])
      }
    }
  }, [work?.additionalService])

  useEffect(() => {
    if (!isWorkLoading) {
      if (work?.invoiceReceived) {
        setInvoiceReceived(work?.invoiceReceived)
      }
    }
  }, [isWorkLoading])

  // Creates jobs and redirects to correct job
  const updateWorkAndResetEdit = async (data: WorkData) => {
    // Reset error state if there is any active error
    setDateError({ error: false, message: "" })
    await updateWork({ id: job, job: data })

    // IF ERROR - RETURN
    if (updatedWork.error) {
      // Set error messsage here if needed
      return
    }

    await setWork(job)
    setEdit(false)
    // -- IF ANY STATE NEEDS TO BE RESET DO IT HERE
  }

  const onSubmit = async (data: WorkData) => {
    data["additionalService"] = additionalServices
    data["invoiceReceived"] = invoiceReceived

    data["dateOfInspection"] = selectedDate
    data["endDateOfInspection"] = selectedEndDate

    if (selectedDate && selectedEndDate) {
      // If a date is choosen the end date must be greater than the start date
      if (selectedEndDate > selectedDate) {
        await updateWorkAndResetEdit(data)
      } else {
        // User must apply an end date that is greater than the start date
        setDateError({
          message: "Slutdatum måste vara senare än startdatum",
          error: true,
        })
      }
    } else {
      // If no date is applied
      await updateWorkAndResetEdit(data)
    }
  }

  const handleInternalUpload = (event: any) => {
    const internalUrl = `${process.env.REACT_APP_API_URL}/documents/internal/${job}`
    const target = event.target as HTMLInputElement
    const file: any = target.files

    // UPLOAD INTERNAL FILE

    if (file) {
      setInternalFilesLoading(true)
      getPresignedPostData(file, internalUrl)
        .then((res) => {
          // TODO: REBUILD TO RUN ASYNC ALONG SIDE EACH OTHER
          const forLoop = async () => {
            let uploadedFiles: any[] = []
            for (let index = 0; index < res.data.length; index++) {
              await uploadFileToS3(res.data[index], file[index]).then(() => {
                uploadedFiles.push(res.data[index].fields.Key)
              })
            }

            setInternalFiles([...internalFiles, ...uploadedFiles])
            setInternalFilesLoading(false)
          }
          forLoop()
        })

        .catch((err) => {
          setInternalFilesLoading(false)
          console.log(err)
        })
    }
  }

  const handleReportUpload = (event: any) => {
    // REPORT URL
    const reportUrl = `${process.env.REACT_APP_API_URL}/documents/reports/${job}`
    const target = event.target as HTMLInputElement
    const file: any = target.files

    // UPLOAD INTERNAL FILE
    if (file) {
      setReportFilesLoading(true)
      getPresignedPostData(file, reportUrl)
        .then((res) => {
          // TODO: REBUILD TO RUN ASYNC ALONG SIDE EACH OTHER
          const forLoop = async () => {
            let uploadedFiles: any[] = []
            for (let index = 0; index < res.data.length; index++) {
              await uploadFileToS3(res.data[index], file[index]).then(() => {
                uploadedFiles.push(res.data[index].fields.Key)
              })
            }

            setReportFiles([...reportFiles, ...uploadedFiles])
            setReportFilesLoading(false)
          }
          forLoop()
        })
        .catch((err) => {
          setReportFilesLoading(false)
          console.log(err)
        })
    }
  }

  const handleDateChange = (date: Date) => {
    const year: number = date?.getFullYear()
    const month: number = date?.getMonth()
    const day: number = date?.getDate()

    // When selectedDate is changed we set the end date to selectedDate +1 hour
    const changedDate = new Date(year, month, day, hour, minute)
    const changedEndDate = new Date(year, month, day, hour + 1, minute)

    setSelectedDate(changedDate)

    // Only change endDate at the same time as startDate if endDate is null
    if (!selectedEndDate) {
      setEndHour(hour + 1)
      setSelectedEndDate(changedEndDate)
    }
  }

  const deleteService = (index: number) => {
    const s = [...additionalServices]
    s.splice(index, 1)
    setAdditionalServices(s)
  }

  // GET REPORT FROM S3
  // TODO: SHOULD BE MORE GENERAL AND RE-NAMED TO GETFILE
  // TODO: SET URL AS PARAMETER
  const getReport = async (filename: string, report: boolean) => {
    let getUrl
    if (report) {
      getUrl = `${process.env.REACT_APP_API_URL}/documents/reports/${job}/${filename}`
    } else {
      getUrl = `${process.env.REACT_APP_API_URL}/documents/internal/${job}/${filename}`
    }
    report ? setReportFilesLoading(true) : setInternalFilesLoading(true)

    const token = localStorage.getItem("token")
    // Headers
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    // If token, add to headers
    if (token) {
      config.headers["x-auth-token"] = token
    }

    const presigned: any = await axios.get(getUrl, config)
    await axios
      .get(presigned.data, presigned.config)
      .then((res) => {
        report ? setReportFilesLoading(false) : setInternalFilesLoading(false)

        window.open(res.request.responseURL, "_blank")
      })
      .catch((err) => {
        report ? setReportFilesLoading(false) : setInternalFilesLoading(false)

        console.log(err)
      })
  }

  const deleteReport = async (filename: string, report: boolean) => {
    let getUrl
    if (report) {
      getUrl = `${process.env.REACT_APP_API_URL}/documents/reports/${job}/${filename}`
    } else {
      getUrl = `${process.env.REACT_APP_API_URL}/documents/internal/${job}/${filename}`
    }
    report ? setReportFilesLoading(true) : setInternalFilesLoading(true)

    const token = localStorage.getItem("token")
    // Headers
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    // If token, add to headers
    if (token) {
      config.headers["x-auth-token"] = token
    }

    try {
      await axios.delete(getUrl, config)
      const currFiles = report ? reportFiles : internalFiles

      const updatedFiles = currFiles.filter((e) => e !== filename)
      report ? setReportFiles(updatedFiles) : setInternalFiles(updatedFiles)
      report ? setReportFilesLoading(false) : setInternalFilesLoading(false)
    } catch (err) {
      report ? setReportFilesLoading(false) : setInternalFilesLoading(false)
      console.error(err)
    }
  }

  // GET PRESIGNED DATA
  const getPresignedPostData = async (selectedFile: any, url: string) => {
    const token = localStorage.getItem("token")
    // Headers
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    // If token, add to headers
    if (token) {
      config.headers["x-auth-token"] = token
    }

    const arr = []
    for (const value of selectedFile) {
      let tjooo = { name: value.name, type: value.type }
      arr.push(tjooo)
    }

    return await axios.post(url, JSON.stringify(arr), config)
  }

  // UPLOAD FILE TO S3 AFTER PRESIGNED DATA
  const uploadFileToS3 = async (presignedPostData: any, file: any) => {
    var formData: any = new FormData()

    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key])
      // formData[key] = presignedPostData.fields[key]
    })
    // Actual file has to be appended last.
    formData.append("file", file)

    await axios.post(presignedPostData.url, formData)
  }

  const timeFromDate = moment(selectedDate).format("LT")

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    const date = selectedDate && selectedDate
    date?.setHours(hour, minute)
    setSelectedDate(date)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const increaseHours = () => {
    const maxHour = 23
    if (hour < maxHour) {
      setHour(hour + 1)
    } else {
      setHour(0)
    }
  }

  const decreaseHours = () => {
    const minHour = 0
    if (hour > minHour) {
      setHour(hour - 1)
    } else {
      setHour(23)
    }
  }

  const increaseMinute = () => {
    const maxMinute = 45
    if (minute < maxMinute) {
      setMinute(minute + 15)
    } else {
      setMinute(0)
    }
  }

  const decreaseMinute = () => {
    const minMinute = 0
    if (minute > minMinute) {
      setMinute(minute - 15)
    } else {
      setMinute(45)
    }
  }

  // END TIME ---- OBV ALL OF THIS SHOULD BE BETTER

  const openEnd = Boolean(anchorElEnd)
  const idEnd = openEnd ? "simple-popover-end" : undefined

  const timeFromEndDate = moment(selectedEndDate).format("LT")

  const handleClickEnd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElEnd(event.currentTarget)
  }

  const handleEndClose = () => {
    setAnchorElEnd(null)
    const date = selectedEndDate && selectedEndDate
    date?.setHours(endHour, endMinute)
    setSelectedEndDate(date)
  }

  const handleEndDateChange = (date: Date) => {
    const year: number = date?.getFullYear()
    const month: number = date?.getMonth()
    const day: number = date?.getDate()

    const changedDate = new Date(year, month, day, endHour, endMinute)
    setSelectedEndDate(changedDate)
  }

  const increaseEndHours = () => {
    const maxHour = 23
    if (endHour < maxHour) {
      setEndHour(endHour + 1)
    } else {
      setEndHour(0)
    }
  }

  const decreaseEndHours = () => {
    const minHour = 0
    if (endHour > minHour) {
      setEndHour(endHour - 1)
    } else {
      setEndHour(23)
    }
  }

  const increaseEndMinute = () => {
    const maxMinute = 45
    if (endMinute < maxMinute) {
      setEndMinute(endMinute + 15)
    } else {
      setEndMinute(0)
    }
  }

  const decreaseEndMinute = () => {
    const minMinute = 0
    if (endMinute > minMinute) {
      setEndMinute(endMinute - 15)
    } else {
      setEndMinute(45)
    }
  }

  const resetDates = () => {
    setSelectedDate(null)
    setSelectedEndDate(null)
  }

  const defaultInspectorIds = work?.inspector?.map((x: any) => x._id) || []

  const currentInspector = watch("inspector")
  const currentStatus = watch("status")
  const prevStatus = usePrevious(currentStatus)

  useEffect(() => {
    const hasCurrentInspector = currentInspector?.length > 0

    // A previous state need to exists so this piece does not run directly
    if (prevStatus) {
      // If not inspector
      if (role !== "inspector") {
        if (
          prevStatus === "unprocessed" &&
          hasCurrentInspector &&
          selectedDate &&
          selectedEndDate
        ) {
          // Set booked if date and inspector is set
          setValue("status", "booked")
        }

        if (!hasCurrentInspector || !selectedDate || !selectedEndDate) {
          // Set booked if date and inspector is not set
          setValue("status", "unprocessed")
        }
      }

      // Inspector
      if (role === "inspector") {
        // Set booked if date is set
        if (prevStatus === "unprocessed" && selectedDate && selectedEndDate) {
          setValue("status", "booked")
        }

        // Set booked if date is not set
        if (!selectedDate || !selectedEndDate) {
          setValue("status", "unprocessed")
        }
      }
    }
  }, [currentInspector, selectedDate, selectedEndDate])

  // THIS SHOULD BE A UTIL LATER
  const convertDateToLocaleDateString = (date: Date) =>
    new Date(date).toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
  return (
    <>
      {isWorkLoading ? (
        <div
          style={{
            width: "100%",
            height: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        work && (
          <>
            {!error ? (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.container}
              >
                {updatedWork.error && <ErrorDisplay />}
                <Grid container>
                  <FormSection paddingTop={true} title="Beställare">
                    <Hidden smDown>
                      {edit && (
                        <Button
                          style={{
                            zIndex: 5,
                            width: "32px",
                            minWidth: "32px",
                            position: "absolute",
                            top: "100px",
                            right: "-15px",
                          }}
                          onClick={() => clientToObject()}
                        >
                          <ArrowForwardIosOutlinedIcon />
                        </Button>
                      )}
                    </Hidden>

                    <Hidden mdUp>
                      {edit ? (
                        <Button
                          className={classes.editBtn}
                          style={{
                            backgroundColor: colors.picton,
                            color: colors.white,
                          }}
                          onClick={() => clientToObject()}
                        >
                          <ArrowForwardIosOutlinedIcon
                            style={{
                              transform: "rotate(90deg) ",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button
                          className={classes.editBtn}
                          onClick={() => setEdit(true)}
                        >
                          <EditOutlinedIcon />
                        </Button>
                      )}
                    </Hidden>
                    <JobInput
                      capitalize={true}
                      defaultValue={work?.clientName}
                      name="clientName"
                      label="Namn"
                      disabled={!edit}
                      error={errors.clientName && true}
                      register={register()}
                    />

                    <JobInput
                      capitalize
                      defaultValue={work?.clientAddress}
                      name="clientAddress"
                      label="Adress"
                      disabled={!edit}
                      error={errors.clientAddress && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.clientZipCode}
                      name="clientZipCode"
                      label="Postnummer"
                      disabled={!edit}
                      error={errors.clientZipCode && true}
                      register={register({
                        minLength: 1,
                        setValueAs: (value) =>
                          value && value.replace(/\s/g, ""),
                      })}
                      // register={register({
                      //   minLength: 1,
                      //   setValueAs: (value) =>
                      //     value && parseInt(value.replace(/\s/g, "")),
                      // })}
                    />
                    <JobInput
                      capitalize
                      defaultValue={work?.clientCity}
                      name="clientCity"
                      label="Ort"
                      disabled={!edit}
                      error={errors.clientCity && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.clientPhone}
                      name="clientPhone"
                      label="Telefon"
                      disabled={!edit}
                      error={errors.clientPhone && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.clientMail}
                      name="clientMail"
                      label="Mail"
                      disabled={!edit}
                      error={errors.clientMail && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.clientIdNumber}
                      name="clientIdNumber"
                      label="OrgNr / PersNr"
                      disabled={!edit}
                      error={errors.clientIdNumber && true}
                      register={register()}
                    />
                  </FormSection>
                  <FormSection paddingTop={true} title="Objekt">
                    <Hidden smDown>
                      {edit ? (
                        <Button
                          style={{
                            zIndex: 5,
                            width: "32px",
                            minWidth: "32px",
                            position: "absolute",
                            top: "205px",
                            left: "-15px",
                          }}
                          onClick={() => objectToClient()}
                        >
                          <ArrowBackIosOutlinedIcon />
                        </Button>
                      ) : (
                        <Button
                          className={classes.editBtn}
                          onClick={() => setEdit(true)}
                        >
                          <EditOutlinedIcon />
                        </Button>
                      )}
                    </Hidden>
                    <Hidden mdUp>
                      {edit && (
                        <Button
                          className={classes.editBtn}
                          style={{
                            backgroundColor: colors.picton,
                            color: colors.white,
                          }}
                          onClick={() => clientToObject()}
                        >
                          <ArrowForwardIosOutlinedIcon
                            style={{
                              transform: "rotate(-90deg) ",
                            }}
                          />
                        </Button>
                      )}
                    </Hidden>

                    <JobInput
                      capitalize
                      defaultValue={work?.propertyOwner}
                      name="propertyOwner"
                      label="Fastighetsägare"
                      disabled={!edit}
                      error={errors.propertyOwner && true}
                      register={register()}
                    />
                    <JobInput
                      capitalize
                      defaultValue={work?.objectAddress}
                      name="objectAddress"
                      label="Adress"
                      disabled={!edit}
                      error={errors.objectAddress && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.objectZipCode}
                      name="objectZipCode"
                      label="Postnummer"
                      disabled={!edit}
                      error={errors.objectZipCode && true}
                      register={register({
                        minLength: 1,
                        setValueAs: (value) =>
                          value && value.replace(/\s/g, ""),
                      })}
                    />
                    <JobInput
                      capitalize
                      defaultValue={work?.objectCity}
                      name="objectCity"
                      label="Ort"
                      disabled={!edit}
                      error={errors.objectCity && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.propertyDesignation}
                      name="propertyDesignation"
                      label="Fastighetsbeteckning"
                      disabled={!edit}
                      uppercase
                      error={errors.propertyDesignation && true}
                      register={register()}
                    />
                    <JobInput
                      defaultValue={work?.assignmentNumber}
                      name="assignmentNumber"
                      label="Uppdragsnummer"
                      disabled={!edit}
                      error={errors.assignmentNumber && true}
                      register={register()}
                    />
                  </FormSection>
                  <Divider />

                  <FormSection title="Kontaktperson">
                    <JobInput
                      name="contactPerson.name"
                      label="Namn"
                      defaultValue={work?.contactPerson?.name}
                      disabled={!edit}
                      register={register()}
                    />
                    <JobInput
                      name="contactPerson.phone"
                      label="Telefon"
                      defaultValue={work?.contactPerson?.phone}
                      disabled={!edit}
                      register={register()}
                    />
                    <JobInput
                      name="contactPerson.email"
                      label="Mail"
                      defaultValue={work?.contactPerson?.email}
                      disabled={!edit}
                      register={register()}
                    />
                  </FormSection>
                  <Divider />
                  <FormSection title="Besiktning">
                    <JobInput
                      defaultValue={work?.price}
                      name="price"
                      label="Pris"
                      disabled={!edit}
                      error={errors.price && true}
                      register={register({
                        setValueAs: (value) =>
                          parseInt(value.replace(/\s/g, "")),
                      })}
                    />
                    <div className={classes.leadContainer}>
                      <p className={classes.label}>Tjänst</p>
                      <FormControl
                        className={classes.formControl}
                        error={errors.inspection && true}
                      >
                        <Controller
                          as={
                            <Select
                              disableUnderline
                              value=""
                              disabled={!edit}
                              classes={{
                                root: classes.select,
                              }}
                            >
                              {Services &&
                                Services.map((service: any) => (
                                  <MenuItem
                                    style={{
                                      textTransform: "capitalize",
                                      fontFamily: "Montserrat",
                                    }}
                                    key={service.service}
                                    value={service.service}
                                  >
                                    {service.service}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                          name="inspection"
                          control={control}
                          defaultValue={work?.inspection}
                        />
                      </FormControl>
                    </div>
                    <AllowedRole roles={["admin", "sales"]}>
                      <div className={classes.leadContainer}>
                        <p className={classes.label}>Besiktningsmän</p>
                        <FormControl
                          className={classes.formControl}
                          error={errors.inspector && true}
                        >
                          <Controller
                            name="inspector"
                            control={control}
                            defaultValue={defaultInspectorIds}
                            render={({ onChange, value, name }) => (
                              <Select
                                disableUnderline
                                value={value}
                                disabled={!edit}
                                multiple
                                classes={{
                                  root: classes.select,
                                }}
                                onChange={(e) => {
                                  onChange(e)
                                }}
                                name={name}
                                input={<Input />}
                                renderValue={(selected) => {
                                  var findCorrectInspectors = (selected as any[]).map(
                                    (value) => {
                                      let inspector = availableInspectors?.data.find(
                                        (x) => x._id === value
                                      )
                                      return `${inspector?.firstName} ${inspector?.lastName}`
                                    }
                                  )
                                  return (findCorrectInspectors as string[]).join(
                                    ", "
                                  )
                                }}
                                // IFALL TIDSINTERVALLEN FRÅN API STÄMMER MED DEN TIDSINTERVALLEN I KLIENTEN SÅ FÅR "DEFAULT VÄRDE VARA AKTIVA" - ANNARS GÖR EN VANLIGA CHECK
                              >
                                {availableInspectors &&
                                  availableInspectors.data.map(
                                    (inspector: any) => {
                                      const isAvailableInObject = inspector.hasOwnProperty(
                                        "available"
                                      )
                                      const isInspectorAvailable = () => {
                                        if (isAvailableInObject) {
                                          if (inspector?.available) {
                                            return true
                                          } else {
                                            return false
                                          }
                                        } else {
                                          return true
                                        }
                                      }
                                      return (
                                        <MenuItem
                                          key={inspector._id}
                                          value={inspector._id}
                                          style={{
                                            textTransform: "capitalize",
                                            fontFamily: "Montserrat",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <p style={{ margin: 0 }}>
                                              {`${inspector.firstName} ${inspector.lastName}`}
                                            </p>
                                            {!isInspectorAvailable() && (
                                              <Tooltip title="Besiktningsmannen är antingen upptagen eller redan utbokad på arbete den här perioden">
                                                <ErrorOutlineOutlinedIcon
                                                  fontSize="small"
                                                  htmlColor={colors.error}
                                                  style={{ marginLeft: "5px" }}
                                                />
                                              </Tooltip>
                                            )}
                                          </div>

                                          <p
                                            style={{ margin: 0, color: "gray" }}
                                          >
                                            {inspector.city}
                                          </p>
                                        </MenuItem>
                                      )
                                    }
                                  )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </div>
                      <JobInput
                        defaultValue={work?.inspectorFee}
                        name="inspectorFee"
                        label="Arvode"
                        error={errors.inspectorFee && true}
                        disabled={!edit}
                        register={register({
                          setValueAs: (value) =>
                            parseInt(value.replace(/\s/g, "")),
                        })}
                      />
                    </AllowedRole>
                    {/* Make a component of date and time */}
                    <div style={{ marginTop: "20px" }}>
                      <p className={classes.label}>Startdatum</p>
                      <Button
                        disabled={!edit || !selectedDate}
                        aria-describedby={id}
                        onClick={handleClick}
                        className={classes.pickTimeBtn}
                      >
                        {selectedDate ? timeFromDate : "Tid"}
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <div className={classes.timepickerPopoverContainer}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button onClick={() => increaseHours()}>
                              <ArrowForwardIosOutlinedIcon
                                style={{
                                  transform: "rotate(-90deg) ",
                                }}
                              />
                            </Button>
                            <div>{hour}</div>
                            <Button onClick={() => decreaseHours()}>
                              <ArrowForwardIosOutlinedIcon
                                style={{
                                  transform: "rotate(90deg) ",
                                }}
                              />
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button onClick={() => increaseMinute()}>
                              <ArrowForwardIosOutlinedIcon
                                style={{
                                  transform: "rotate(-90deg) ",
                                }}
                              />
                            </Button>
                            <div>{minute}</div>
                            <Button onClick={() => decreaseMinute()}>
                              <ArrowForwardIosOutlinedIcon
                                style={{
                                  transform: "rotate(90deg) ",
                                }}
                              />
                            </Button>
                          </div>
                        </div>
                      </Popover>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          initialFocusedDate={initialDate}
                          value={selectedDate}
                          emptyLabel="Datum"
                          disabled={!edit}
                          onChange={(date: any) => handleDateChange(date)}
                          className={classes.timePicker}
                          style={{ paddingLeft: "10px" }}
                          InputProps={{
                            classes: {
                              root: classes.inputPicker,
                              input: classes.input,
                              notchedOutline: classes.outline,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    {/* ----- END TIME OF INSPECTION -----*/}
                    {/* Make a component of date and time */}
                    {selectedDate && (
                      <div style={{ marginTop: "20px" }}>
                        <p className={classes.label}>Slutdatum</p>
                        <Button
                          aria-describedby={idEnd}
                          onClick={handleClickEnd}
                          className={classes.pickTimeBtn}
                          disabled={!edit || !selectedEndDate}
                        >
                          {selectedEndDate ? timeFromEndDate : "Tid"}
                        </Button>
                        <Popover
                          id={idEnd}
                          open={openEnd}
                          anchorEl={anchorElEnd}
                          onClose={handleEndClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <div className={classes.timepickerPopoverContainer}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Button onClick={() => increaseEndHours()}>
                                <ArrowForwardIosOutlinedIcon
                                  style={{
                                    transform: "rotate(-90deg) ",
                                  }}
                                />
                              </Button>
                              <div>{endHour}</div>
                              <Button onClick={() => decreaseEndHours()}>
                                <ArrowForwardIosOutlinedIcon
                                  style={{
                                    transform: "rotate(90deg) ",
                                  }}
                                />
                              </Button>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Button onClick={() => increaseEndMinute()}>
                                <ArrowForwardIosOutlinedIcon
                                  style={{
                                    transform: "rotate(-90deg) ",
                                  }}
                                />
                              </Button>
                              <div>{endMinute}</div>
                              <Button onClick={() => decreaseEndMinute()}>
                                <ArrowForwardIosOutlinedIcon
                                  style={{
                                    transform: "rotate(90deg) ",
                                  }}
                                />
                              </Button>
                            </div>
                          </div>
                        </Popover>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            initialFocusedDate={initialDate}
                            value={selectedEndDate}
                            emptyLabel="Datum"
                            disabled={!edit}
                            onChange={(date: any) => handleEndDateChange(date)}
                            className={classes.timePicker}
                            style={{ paddingLeft: "10px" }}
                            InputProps={{
                              classes: {
                                root: classes.inputPicker,
                                input: classes.input,
                                notchedOutline: classes.outline,
                              },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          color: colors.error,
                          margin: 0,
                        }}
                      >
                        {dateError.error && dateError.message}
                      </p>

                      {selectedDate && edit && (
                        <p
                          onClick={() => resetDates()}
                          style={{
                            alignSelf: "flex-end",
                            color: colors.azure,
                            margin: 0,
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Rensa datum
                        </p>
                      )}
                    </div>
                  </FormSection>
                  <FormSection paddingTop={true} title="Tilläggstjänst">
                    <div className={classes.additionalServiceContainer}>
                      <div
                        className={classNames(
                          classes.leadContainer,
                          classes.additionalServiceSelect
                        )}
                      >
                        <p className={classes.label}>Tilläggstjänst</p>
                        <FormControl
                          className={classes.formControl}
                          error={errors.service && true}
                        >
                          <Controller
                            as={
                              <Select
                                disabled={!edit}
                                disableUnderline
                                value=""
                                classes={{
                                  root: classes.select,
                                }}
                              >
                                {AdditionalServices &&
                                  AdditionalServices.map((service: any) => (
                                    <MenuItem
                                      key={service.service}
                                      value={service.service}
                                      className={classes.additionalServiceItem}
                                    >
                                      {firstLetterUppercase(service.service)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            }
                            name="service"
                            control={control}
                            defaultValue=""
                          />
                        </FormControl>
                      </div>
                      <JobInput
                        disabled={!edit}
                        name="servicePrice"
                        label="Pris"
                        error={errors.servicePrice && true}
                        register={register({
                          setValueAs: (value) =>
                            parseInt(value.replace(/\s/g, "")),
                        })}
                      />
                      <Button
                        disabled={!edit}
                        onClick={() => addAdditionalService()}
                        className={classes.additionalServiceBtn}
                      >
                        <AddOutlinedIcon style={{ color: colors.white }} />
                      </Button>
                    </div>
                    {additionalServices.length > 0 &&
                      additionalServices.map((x, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                marginRight: "10px",
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {x.service}
                            </p>
                            <p>{x.price}</p>
                          </div>
                          <Button
                            disabled={!edit}
                            onClick={(e) => deleteService(index)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </Button>
                        </div>
                      ))}
                  </FormSection>
                  {/* Förslag på besiktningsman ska vara här */}
                  {/* <FormSection title=""></FormSection> */}
                  <Divider />
                  <FormSection title="Info">
                    <div className={classes.leadContainer}>
                      <p className={classes.label}>Lead</p>
                      <FormControl
                        className={classes.formControl}
                        error={errors.service && true}
                      >
                        <Controller
                          as={
                            <Select
                              disableUnderline
                              value=""
                              disabled={!edit}
                              classes={{
                                root: classes.select,
                              }}
                            >
                              {Leads &&
                                Leads.map((lead: any) => (
                                  <MenuItem
                                    key={lead.lead}
                                    value={lead.lead}
                                    style={{
                                      textTransform: "capitalize",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    {lead.lead}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                          name="lead"
                          control={control}
                          defaultValue={work?.lead}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.leadContainer}>
                      <p className={classes.label}>Status</p>
                      <FormControl className={classes.formControl}>
                        <Controller
                          as={
                            <Select
                              disableUnderline
                              value="bokad"
                              classes={{
                                root: classes.select,
                              }}
                              disabled={!edit}
                            >
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "Montserrat",
                                }}
                                value="canceled"
                              >
                                Avbokad
                              </MenuItem>
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "Montserrat",
                                }}
                                value="unprocessed"
                              >
                                Obehandlad
                              </MenuItem>
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "Montserrat",
                                }}
                                value="booked"
                              >
                                Bokad
                              </MenuItem>
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "Montserrat",
                                }}
                                value="ongoing"
                              >
                                Pågående
                              </MenuItem>
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "Montserrat",
                                }}
                                value="sent"
                              >
                                Utskickad
                              </MenuItem>
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "Montserrat",
                                }}
                                value="completed"
                              >
                                Klar
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={work?.status}
                        />
                      </FormControl>
                    </div>
                    <JobInput
                      defaultValue={work?.otherInformation}
                      name="otherInformation"
                      label="Övrigt"
                      multiline
                      disabled={!edit}
                      register={register()}
                    />
                  </FormSection>
                  <FormSection paddingTop={true} title="Övrigt">
                    <div className={classes.leadContainer}>
                      {reportFilesLoading && <FileLoading />}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p className={classes.label}>Rapport</p>
                        <>
                          {edit && (
                            <input
                              name="report"
                              type="file"
                              id="contained-button-file"
                              style={{ display: "none" }}
                              onChange={(event) => handleReportUpload(event)}
                              multiple
                            />
                          )}

                          <label htmlFor="contained-button-file">
                            <Button
                              disabled={!edit}
                              component="span"
                              className={classes.uploadBtn2}
                              classes={{ disabled: classes.addFileDisabled }}
                            >
                              <AddOutlinedIcon fontSize="small" />
                            </Button>
                          </label>
                        </>
                      </div>
                      {reportFiles.map((x: any) => (
                        <div className={classes.file}>
                          <div className={classes.fileName}>
                            <p style={{ margin: 0 }}>{x.split("_")[2]}</p>
                          </div>
                          {edit ? (
                            <Button
                              onClick={() => deleteReport(x, true)}
                              className={classes.fileDownloadBtn}
                            >
                              <DeleteOutlineOutlinedIcon fontSize="small" />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => getReport(x, true)}
                              className={classes.fileDownloadBtn}
                            >
                              <CloudDownloadOutlinedIcon fontSize="small" />
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className={classes.leadContainer}>
                      {internalFilesLoading && <FileLoading />}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p className={classes.label}>Interna dokument</p>

                        <>
                          {edit && (
                            <input
                              name="internalDocuments"
                              type="file"
                              id="contained-button-file-2"
                              style={{ display: "none" }}
                              onChange={(event) => handleInternalUpload(event)}
                              multiple
                            />
                          )}

                          <label htmlFor="contained-button-file-2">
                            <Button
                              component="span"
                              disabled={!edit}
                              className={classes.uploadBtn2}
                              classes={{
                                disabled: classes.addFileDisabled,
                              }}
                            >
                              <AddOutlinedIcon fontSize="small" />
                            </Button>
                          </label>
                        </>
                      </div>
                      {internalFiles.map((x: any) => (
                        <div className={classes.file}>
                          <div className={classes.fileName}>
                            <p style={{ margin: 0 }}>{x.split("_")[2]}</p>
                          </div>
                          {edit ? (
                            <Button
                              onClick={() => deleteReport(x, false)}
                              className={classes.fileDownloadBtn}
                            >
                              <DeleteOutlineOutlinedIcon fontSize="small" />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => getReport(x, false)}
                              className={classes.fileDownloadBtn}
                            >
                              <CloudDownloadOutlinedIcon fontSize="small" />
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className={classes.leadContainer}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className={classes.label}>Mail till kund</p>
                        {work?.inspectorEmailSent && (
                          <p className={classes.label}>
                            {`Antal mail skickade - ${work?.emailToCustomerDeliveryInformation?.length}
                          `}
                          </p>
                        )}
                      </div>
                      <Button
                        style={{ color: colors.white }}
                        classes={{ disabled: classes.disBtn }}
                        className={classes.uploadBtn}
                        onClick={() => setOpenMailClient(true)}
                        // disabled={clientEmailSent}
                      >
                        Skicka mail
                      </Button>
                      {openMailClient && (
                        <MailModal
                          title="Kund"
                          open={openMailClient}
                          work={work}
                          sendToCustomer
                          handleClose={() => setOpenMailClient(false)}
                          reqCallback={() => window.location.reload()}
                        />
                      )}
                      {work?.emailToCustomerDeliveryInformation &&
                        work?.emailToCustomerDeliveryInformation?.length >
                          0 && (
                          <EmailDeliveryInformation
                            emails={work?.emailToCustomerDeliveryInformation}
                          />
                        )}
                    </div>
                    <div className={classes.leadContainer}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className={classes.label}>
                          Mail till besiktningsman
                        </p>
                        {work?.inspectorEmailSent && (
                          <p className={classes.label}>
                            {`Antal mail skickade - ${work?.emailToInspectorDeliveryInformation?.length}`}
                          </p>
                        )}
                      </div>
                      <Button
                        style={{ color: colors.white }}
                        classes={{ disabled: classes.disBtn }}
                        className={classes.uploadBtn}
                        onClick={() => setOpenMailInspector(true)}
                      >
                        Skicka mail
                      </Button>
                      {openMailInspector && (
                        <MailModal
                          title="Besiktningsman"
                          sendToInspector
                          open={openMailInspector}
                          work={work}
                          inspectors={defaultInspectorIds}
                          handleClose={() => setOpenMailInspector(false)}
                          reqCallback={() => window.location.reload()}
                        />
                      )}
                      {work?.emailToInspectorDeliveryInformation &&
                        work?.emailToInspectorDeliveryInformation?.length >
                          0 && (
                          <EmailDeliveryInformation
                            emails={work?.emailToInspectorDeliveryInformation}
                          />
                        )}
                    </div>
                    <div className={classes.leadContainer}>
                      <p className={classes.label}>Faktura inkommen</p>

                      <FormControlLabel
                        classes={{ label: classes.checkboxLabel }}
                        disabled={!edit}
                        control={
                          <Checkbox
                            checked={invoiceReceived}
                            onChange={(e) =>
                              setInvoiceReceived(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Faktura inkommen"
                      />
                    </div>
                  </FormSection>
                  <Grid
                    className={classes.buttonContainer}
                    xs={12}
                    container
                    item
                  >
                    {deleteWorkResponse.error && <ErrorDisplay />}
                    <DeleteWorkDialog
                      workId={job}
                      open={isDeleteOpen}
                      handleClose={() => setDeleteOpen(false)}
                    />

                    {edit ? (
                      <div className={classes.editModeButtonsContainer}>
                        <Button
                          onClick={() => setDeleteOpen(true)}
                          disabled={deleteWorkResponse.loading}
                          className={classes.button}
                          classes={{ disabled: classes.addDisabled }}
                          style={{
                            marginRight: "10px",
                            backgroundColor: deleteWorkResponse.loading
                              ? "lightgray"
                              : "red",
                            color: colors.white,
                          }}
                        >
                          Radera
                        </Button>
                        <div className={classes.abortSaveContainer}>
                          <Button
                            onClick={() => setEdit(false)}
                            style={{
                              marginRight: "10px",
                              backgroundColor: colors.white,
                              border: `1px solid ${colors.picton}`,
                              color: colors.picton,
                            }}
                            className={classes.button}
                          >
                            Avbryt
                          </Button>
                          <Button
                            type="submit"
                            disabled={updatedWork.loading}
                            className={classes.button}
                            style={{
                              backgroundColor: updatedWork.loading
                                ? "lightgray"
                                : colors.picton,
                            }}
                            classes={{ disabled: classes.addDisabled }}
                          >
                            {updatedWork.loading ? (
                              <CircularProgress
                                size="25px"
                                style={{ color: "white" }}
                              />
                            ) : (
                              "Spara"
                            )}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {work?.createdAt && work?.createdBy && (
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ margin: "0 5px 0 0", fontWeight: 500 }}
                              >
                                Skapad av:
                              </p>
                              <p style={{ margin: 0 }}>{`${
                                work?.createdBy?.firstName
                              } ${
                                work?.createdBy?.lastName
                              } - ${convertDateToLocaleDateString(
                                work?.createdAt
                              )}`}</p>
                            </div>
                          )}

                          {work?.updatedAt && work?.updatedBy && (
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ margin: "0 5px 0 0", fontWeight: 500 }}
                              >
                                Uppdaterad av:
                              </p>
                              <p style={{ margin: 0 }}>{`${
                                work?.updatedBy?.firstName
                              } ${
                                work?.updatedBy?.lastName
                              } - ${convertDateToLocaleDateString(
                                work?.updatedAt
                              )}`}</p>
                            </div>
                          )}
                        </div>

                        <Button
                          onClick={() => setEdit(true)}
                          className={classes.button}
                        >
                          Redigera
                        </Button>
                      </div>
                    )}
                    <p
                      style={{
                        color: colors.error,
                        margin: "10px 0 0 0",
                        wordWrap: "break-word",
                      }}
                    >
                      {dateError.error &&
                        "Något gick fel. Kolla så att all information ovan är korrekt."}
                    </p>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <div className={classes.error}>
                <ErrorOutlineOutlinedIcon style={{ fontSize: "28px" }} />
                <p>Error: Hämtning av arbetet misslyckades.</p>
              </div>
            )}
          </>
        )
      )}
    </>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "30px 5px",
      color: colors.zodiac,
      backgroundColor: colors.white,
      [breakpoints.down("md")]: {
        paddingTop: "0px",
      },
    },
    formContainer: {
      backgroundColor: "transparent",
      padding: "0 25px",
      alignContent: "flex-start",
    },

    divider: {
      width: "100%",
      height: "0.5px",
      backgroundColor: "#E4E4E4",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      alignItems: "flex-end",
      padding: "50px 25px 0 25px",
      [breakpoints.down("sm")]: {
        padding: 0,
        marginTop: "20px",
      },
    },
    button: {
      width: "200px",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: "orange",
      [breakpoints.down("sm")]: {
        width: "100%",
        marginTop: "15px",
      },
    },
    timePicker: {
      width: "50%",
      marginTop: "7.5px",
    },
    inputPicker: {
      backgroundColor: "#F5F7F9",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
    },
    outline: {
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
    },
    input: {
      width: "100%",
      cursor: "pointer",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#F5F7F9",
      margin: "7.5px 0",
      maxHeight: "56px",
    },
    select: {
      width: "100%",
      padding: "18.5px 14px",
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
      maxHeight: "56px",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      color: "gray",
      fontSize: "14px",
    },
    leadContainer: {
      position: "relative",
      marginTop: "20px",
      width: "100%",
    },

    uploadBtn: {
      height: "55px",
      border: "1px solid #E4E4E4",
      backgroundColor: colors.picton,
      borderRadius: "2.5px",
      margin: "7.5px 0",
      width: "100%",
      color: colors.white,
      fontFamily: "Montserrat",
    },
    uploadBtn2: {
      borderRadius: "50%",
      color: colors.zodiac,
      fontFamily: "Montserrat",
      minWidth: "0px",
    },
    pickTimeContainer: {
      marginTop: "20px",
      width: "50%",
      marginRight: "10px",
    },
    pickTimeBtn: {
      height: "55px",
      border: "1px solid #E4E4E4",
      backgroundColor: "#f5f7f9",
      borderRadius: "2.5px",
      margin: "7.5px 0",
      width: "50%",
      color: "#0000008a",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Montserrat",
      paddingRight: "10px",
    },
    editBtn: {
      // width: "32px",
      minWidth: "32px",
      position: "absolute",
      top: 0,
      right: "25px",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      [breakpoints.down("sm")]: {
        top: "30px",
        right: 0,
      },
    },
    editModeButtonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      [breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    abortSaveContainer: {
      [breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column-reverse",
        marginBottom: "35px",
      },
    },
    additionalServiceContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      [breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    additionalServiceSelect: {
      marginRight: "10px",
      [breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: "-20px",
      },
    },
    additionalServiceBtn: {
      margin: "7.5px 0 7.5px 10px",
      height: "56px",
      backgroundColor: colors.picton,
      color: colors.white,
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    error: {
      display: "flex",
      width: "100%",
      height: "50vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
      fontSize: "18px",
      fontFamily: "Montserrat",
      color: "red",
      "& p": {
        margin: 0,
        marginTop: "5px",
      },
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
    addFileDisabled: {
      color: "lightgray",
    },
    timepickerPopoverContainer: {
      display: "flex",
      padding: "10px",
      fontFamily: "Montserrat",
      fontSize: "16px",
    },
    mailBtn: {
      backgroundColor: colors.picton,
      color: colors.white,
    },
    disBtn: {
      backgroundColor: "#6cd037",
      color: colors.white,
    },
    checkboxLabel: { fontFamily: "Montserrat" },
    fileName: {
      display: "-webkit-box",
      width: "70%",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    file: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      margin: "5px 0",
    },
    fileDownloadBtn: {
      borderRadius: "50%",
      color: colors.zodiac,
      fontFamily: "Montserrat",
      minWidth: "0px",
    },
    mailInformationContainer: {
      borderBottom: "1px solid lightgray",
      padding: "10px 0",
    },
    mailInformationItem: {
      display: "flex",
      justifyContent: "space-between",
      "& p": {
        margin: "5px 0 0 0 ",
      },
      "& :first-child": {
        fontWeight: "500",
      },
    },
    additionalServiceItem: {
      fontFamily: "Montserrat",
    },
  })

export default withStyles(styles)(Job)
