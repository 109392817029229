import axios, { AxiosRequestConfig } from "axios"
export type Inspector = {
  _id: string
  firstName: string
  lastName: string
  phone: string
  email: string
  city: string
  role: string
  extraInformation?: string
}

const addUser = async (inspector: Inspector) => {
  const token = localStorage.getItem("token")

  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/user`,
    inspector,
    config
  )
}

export default addUser
