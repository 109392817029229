import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"

import { Theme, TextField } from "@material-ui/core"
import classNames from "classnames"

type Props = {
  register?: any
  name: string
  defaultValue?: any | null
  label?: string
  multiline?: boolean
  disabled?: boolean
  error?: boolean
  capitalize?: boolean
  uppercase?: boolean
  onChange?: (text: any) => void
} & WithStyles<typeof styles>

const JobInput: React.FC<Props> = ({
  classes,
  name,
  label,
  register,
  defaultValue,
  multiline,
  disabled,
  error,
  capitalize,
  uppercase,
  onChange,
}) => {
  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <TextField
        autoComplete="off"
        inputRef={register}
        defaultValue={defaultValue}
        margin="normal"
        name={name}
        type="text"
        multiline={multiline}
        variant="outlined"
        disabled={disabled}
        error={error}
        onChange={onChange}
        classes={{ root: classes.textField }}
        InputProps={{
          classes: {
            notchedOutline: classes.outline,
            input: classNames(
              classes.input,
              capitalize && classes.capitalize,
              uppercase && classes.uppercase
            ),
          },
        }}
        InputLabelProps={{
          classes: {
            focused: classes.labelFocused,
          },
        }}
      />
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "20px",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "42px",
      color: colors.zodiac,
      backgroundColor: colors.white,
      minHeight: "700px",
    },
    textField: {
      width: "100%",
      margin: "7.5px 0",
      backgroundColor: "#F5F7F9",
      fontFamily: "Montserrat",
    },
    input: {
      fontFamily: "Montserrat",
    },
    capitalize: {
      textTransform: "capitalize",
    },
    uppercase: {
      textTransform: "uppercase",
    },
    outline: {
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      fontSize: "14px",
      color: "gray",
    },
    labelFocused: {},
  })
export default withStyles(styles)(JobInput)
