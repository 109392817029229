import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, CircularProgress } from "@material-ui/core"
import colors from "ui"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

import mailStatusTranslation from "utils/mailStatusTranslation"

const useStyles = makeStyles<{}, StyleProps>({
  mailInformationContainer: {
    borderBottom: "1px solid lightgray",
    padding: "10px 0",
    marginTop: "10px",
  },
  mailInformationItem: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      margin: "7.5px 0 0 0 ",
    },
    "& :first-child": {
      fontWeight: "500",
    },
  },
  deliveryStatus: {
    display: "flex",
    justifyContent: "center",

    padding: "5px 0",
    marginTop: "10px",

    color: "white",
    backgroundColor: (props) =>
      props.statusOnDelivery === "DELIVERED" ? colors.success : colors.error,

    "& p": {
      margin: "0",
      fontWeight: "500",
      fontSize: "14px",
    },
  },
})

type StyleProps = { statusOnDelivery: string }

type DeliveryInformation = {
  date: Date
  statusOnDelivery: string
  targetEmail: string
}

type Props = { information: DeliveryInformation }

const EmailDeliveryInformation: FC<Props> = ({
  information: { statusOnDelivery, date, targetEmail },
}) => {
  const classes = useStyles({ statusOnDelivery })

  const convertDateToLocaleDateString = (date: Date) =>
    new Date(date).toLocaleDateString("sv-SE")

  const convertDateToLocaleTimeString = (date: Date) =>
    new Date(date).toLocaleTimeString("sv-SE")

  return (
    <div className={classes.mailInformationContainer}>
      <div className={classes.mailInformationItem}>
        <p>Skickad till</p>
        <p>{targetEmail}</p>
      </div>

      <div className={classes.mailInformationItem}>
        <p>Tid</p>
        <p>{convertDateToLocaleTimeString(date)}</p>
      </div>
      <div className={classes.mailInformationItem}>
        <p>Datum</p>
        <p>{convertDateToLocaleDateString(date)}</p>
      </div>
      <div className={classes.deliveryStatus}>
        <p>{mailStatusTranslation(statusOnDelivery)}</p>
      </div>
    </div>
  )
}

export default EmailDeliveryInformation
