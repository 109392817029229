import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, CircularProgress } from "@material-ui/core"
import colors from "ui"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"
const useStyles = makeStyles<{}, StyleProps>({
  button: {
    marginTop: "20px",
    width: "100%",
    height: "50px",
    borderRadius: 0,
    fontSize: "14px",
    textTransform: "initial",
    fontFamily: "Montserrat",
    color: colors.white,
    // backgroundColor: (props) => (props.success ? "green" : colors.picton),
    backgroundColor: colors.picton,

    "&$disabled": {
      backgroundColor: (props) => (props.success ? "#4BB543" : "lightgray"),
      color: colors.white,
    },
  },
  disabled: {
    backgroundColor: (props) => (props.success ? "lawngreen" : "lightgray"),
  },
})

type StyleProps = { success: boolean }

type Props = { isLoading: boolean; success: boolean }

const SubmitButton: FC<Props> = ({ success, isLoading }) => {
  const classes = useStyles({ success })

  return (
    <Button
      type="submit"
      disabled={isLoading || success}
      className={classes.button}
      classes={{ disabled: classes.disabled }}
    >
      {isLoading ? (
        <CircularProgress size="25px" style={{ color: "white" }} />
      ) : success ? (
        <CheckOutlinedIcon />
      ) : (
        "Lägg till"
      )}
    </Button>
  )
}

export default SubmitButton
