import React, { FC } from "react"

import { Dialog, useMediaQuery, useTheme } from "@material-ui/core"

import AddBlockedDate from "components/BlockedDateModal/AddBlockedDate"

type Props = {
  open: boolean
  blockedDateId: string
  handleClose: () => void
}

const BlockedDateModal: FC<Props> = ({ open, blockedDateId, handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <AddBlockedDate blockedDateId={blockedDateId} close={handleClose} />
    </Dialog>
  )
}

export default BlockedDateModal
