import React from "react"
import { withStyles } from "@material-ui/core/styles"

import { Redirect, Route } from "react-router-dom"
import authStore from "../store/authStore"
import useStore from "global-hook-store"

type Props = { component: any; roles: string[]; exact?: boolean; path: string }

const PrivateRoute = ({ component: Component, roles, ...rest }: Props) => {
  const {
    state: { role },
  } = useStore(authStore)
  return (
    <Route
      {...rest}
      render={(props) => {
        const isRoleIncluded = roles && roles.includes(role)
        // check if route is restricted by role
        if (!isRoleIncluded) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />
        }
        // authorised so return component
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
