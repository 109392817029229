import axios, { AxiosRequestConfig } from "axios"

const getStatistics = async (
  dateInterval: string,
  date: Date,
  user: string[],
  status: string
) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  const usersQuery = user.length > 0 ? `&userIds=${user}` : ""
  const statusQuery = status ? `&status=${status}` : ""

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/statistics/${dateInterval}?date=${date}${usersQuery}${statusQuery}`,
    config
  )
  return res.data
}

export default getStatistics
