import * as React from "react"
import colors from "../../ui"
import {
  createStyles,
  withStyles,
  WithStyles,
  AppBar,
  Toolbar,
  IconButton,
  Theme,
  Button,
} from "@material-ui/core"
import { history } from "../../pages/Admin"
import MenuIcon from "@material-ui/icons/Menu"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import useStore from "global-hook-store"
import navStore from "../../store/navStore"
import PrePurchaseInspectionStore from "../../store/PrePurchaseInspectionStore"
import { Switch, Route, Link } from "react-router-dom"
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined"
import JobHeaderContent from "../JobHeaderContent"
import authStore from "../../store/authStore"
import AllowedRole from "components/AllowedRole"

type Props = {} & WithStyles<typeof styles>
const drawerWidth = 100

const Header: React.FC<Props> = ({ classes }) => {
  const {
    state: { navOpen, searchOpen, addBlockedDateOpen },
    actions: { setNavOpen, setSearchOpen, setAddBlockedDateOpen },
  } = useStore(navStore)

  const {
    state: { isAddSection, isAddFloor },
    actions: { setAddSectionOpen, setAddFloorOpen },
  } = useStore(PrePurchaseInspectionStore)

  const handleDrawerToggle = () => {
    setNavOpen(!navOpen)
  }

  const handleSearchDrawer = () => {
    setSearchOpen(!searchOpen)
  }

  const handleBlockedDateToggle = () => {
    setAddBlockedDateOpen(!addBlockedDateOpen)
  }

  const handleAddButton = () => {
    if (isAddSection) {
      setAddSectionOpen(true)
    } else if (isAddFloor) {
      setAddFloorOpen(true)
    }
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Switch>
          <Route
            path="/jobs/prepurchaseinspection"
            render={() => (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => history.goBack()}
                  className={classes.menuButton}
                >
                  <ArrowBackOutlinedIcon />
                </IconButton>
                {(isAddFloor || isAddSection) && (
                  <IconButton
                    onClick={handleAddButton}
                    edge="start"
                    color="inherit"
                    className={classes.addButton}
                  >
                    <AddOutlinedIcon />
                  </IconButton>
                )}
              </>
            )}
          />
          <Route
            exact
            path="/jobs"
            render={() => (
              <JobHeaderContent
                handleSearchDrawer={handleSearchDrawer}
                handleDrawerToggle={handleDrawerToggle}
                path="/jobs"
              />
            )}
          />
          <Route
            exact
            path="/users"
            render={() => (
              <JobHeaderContent
                handleSearchDrawer={handleSearchDrawer}
                handleDrawerToggle={handleDrawerToggle}
                path="/users"
                users
              />
            )}
          />
          <Route
            exact
            path="/calender"
            render={() => (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>

                <AllowedRole roles={["inspector"]}>
                  <Button
                    onClick={handleBlockedDateToggle}
                    // edge="start"
                    // color="inherit"
                    className={classes.addBlockedDate}
                  >
                    <AddOutlinedIcon fontSize="small" />
                    <p className={classes.btnText}>Upptagen</p>
                  </Button>
                </AllowedRole>
              </>
            )}
          />

          <Route
            render={() => (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            )}
          />
        </Switch>
      </Toolbar>
    </AppBar>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: colors.white,
      boxShadow: "none",
      [breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      [breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
    menuButton: {
      marginRight: spacing(2),
      color: colors.zodiac,

      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    addButton: { color: colors.zodiac },
    addJobBtn: {
      padding: "10px",
      marginRight: "25px",
      "& svg": {
        fontSize: "16px",
      },
    },
    btnText: {
      fontSize: "14px",
      letterSpacing: "1px",
      margin: 0,
      paddingLeft: "5px",
      fontFamily: "montserrat",
    },
    link: {
      textDecoration: "none",
      fontSize: "inherit",
    },
    addBlockedDate: {
      padding: "10px",
      "& svg": {
        fontSize: "16px",
      },
    },
  })

export default withStyles(styles)(Header)
