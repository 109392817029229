import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { Theme, Grid } from "@material-ui/core"
import clsx from "clsx"

type Props = {
  title?: string
  paddingTop?: boolean
  fullWidth?: boolean
} & WithStyles<typeof styles>

const FormSection: React.FC<Props> = ({
  classes,
  children,
  title,
  paddingTop,
  fullWidth,
}) => {
  return (
    <Grid
      className={clsx(classes.formContainer, paddingTop && classes.paddingTop)}
      xs={12}
      md={fullWidth ? 12 : 6}
      container
      item
    >
      {title && (
        <Grid className={classes.rowHeader}>
          <h2>{title}</h2>
        </Grid>
      )}
      {children}
    </Grid>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    formContainer: {
      position: "relative",
      backgroundColor: "transparent",
      padding: "0 25px",
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      [breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    rowHeader: {
      backgroundColor: "transparent",
      "& h2": {
        fontSize: "20px",
        margin: 0,
        marginBottom: "10px",
        fontWeight: 500,
      },
    },
    paddingTop: {
      [breakpoints.down("sm")]: {
        paddingTop: "30px",
      },
    },
  })

export default withStyles(styles)(FormSection)
