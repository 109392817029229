import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import moment from "moment"
import "moment/locale/sv"

import {
  Theme,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Input,
  Chip,
  ListItemText,
  Checkbox,
  InputLabel,
  CircularProgress,
} from "@material-ui/core"

import fileStore from "../store/fileStore"
import useStore from "global-hook-store"
import { WorkData } from "../api/addWork"
import sendEmail, { EmailData } from "../api/sendEmail"
import JobInput from "components/JobInput"
moment.locale("sv")

type Props = {
  open: boolean
  sendToInspector?: boolean
  sendToCustomer?: boolean
  title?: string
  work: WorkData | null
  inspectors?: []
  handleClose: () => void
  reqCallback: () => void
} & WithStyles<typeof styles>

type emailFile = {
  createdAt: string
  name: string
  updatedAt: string
  _id: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const MailModal: React.FC<Props> = ({
  classes,
  open,
  sendToInspector = false,
  sendToCustomer = false,
  work,
  title = "",
  inspectors,
  handleClose,
  reqCallback,
}) => {
  const {
    state: {
      emailFiles: { loading, data: files },
    },
    actions: { setEmailFiles },
  } = useStore(fileStore)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [emailSent, setEmailSent] = React.useState<boolean>(false)
  const [loadingRequest, setLoadingRequest] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<string>("")

  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([])
  const [selectedFilesInternal, setSelectedFilesInternal] = React.useState<
    string[]
  >([])

  const handleChange = (event: React.ChangeEvent<any>) => {
    setSelectedFiles(event.target.value as any[])
  }

  const handleTextChange = (event: React.ChangeEvent<any>) => {
    setMessage(event.target.value)
  }

  const handleChangeInternal = (event: React.ChangeEvent<any>) => {
    setSelectedFilesInternal(event.target.value as any[])
  }

  useEffect(() => {
    setEmailFiles()
  }, [])
  const calcAdditionalPrice = (nein: any) => {
    let sum = 0

    nein?.length > 0 &&
      nein.map((x: any) => {
        sum = sum + x.price
      })

    return sum
  }

  const totPrice = (price?: number, additionalServicePrice?: number) => {
    let parsedPrice = price ? price : 0
    let parsedAdditionalServicePrice = additionalServicePrice
      ? additionalServicePrice
      : 0

    return parsedPrice + parsedAdditionalServicePrice
  }
  const handleSend = async () => {
    const additionalServicePrice = calcAdditionalPrice(work?.additionalService)
    const totalPrice = totPrice(work?.price, additionalServicePrice)
    const timeOfInspection = moment(work?.dateOfInspection).format("LT")

    const data: EmailData = {
      clientName: work?.clientName,
      clientPhone: work?.clientPhone,
      clientMail: work?.clientMail,
      inspection: work?.inspection,
      additionalService: work?.additionalService,
      objectAddress: work?.objectAddress,
      clientAddress: work?.clientAddress,
      dateOfInspection: work?.dateOfInspection,
      timeOfInspection: timeOfInspection,
      price: work?.price,
      inspector: inspectors,
      inspectorFee: work?.inspectorFee,
      fileNames: selectedFiles,
      otherInformation: work?.otherInformation,
      internalDocuments: selectedFilesInternal,
      objectCity: work?.objectCity,
      objectZipCode: work?.objectZipCode,
      clientCity: work?.clientCity,
      clientZipCode: work?.clientZipCode,
      propertyDesignation: work?.propertyDesignation,
      totalPrice: totalPrice,
      contactPerson: work?.contactPerson,
      messageToCustomer: message,
    }
    setLoadingRequest(true)
    try {
      await sendEmail(sendToInspector, sendToCustomer, data, work?._id)
      setEmailSent(true)
      setLoadingRequest(false)
    } catch (err) {
      setLoadingRequest(false)

      console.log(err)
    }
  }

  return (
    <Dialog
      maxWidth={"md"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogContent style={{ fontFamily: "montserrat" }}>
        {loading || loadingRequest ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: "20px 0",
            }}
          >
            <CircularProgress />
          </div>
        ) : emailSent ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ margin: "0 0 10px 0" }}>Mail skickat.</p>
            <p style={{ margin: "0 0 16px 0" }}>
              Ladda om sidan för att få ny information om mail.
            </p>

            <Button
              style={{
                color: "white",
                backgroundColor: "#40A8E2",
                borderRadius: 0,
                padding: "8px 16px",
                margin: "5px 0 10px",
              }}
              onClick={() => window.location.reload()}
            >
              Ladda om sidan
            </Button>
          </div>
        ) : (
          <>
            <p>{title}</p>
            {sendToInspector && (
              <>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="interna-dokument">
                    Interna dokument
                  </InputLabel>
                  <Select
                    labelId="interna-dokument"
                    id="interna-dokument"
                    multiple
                    style={{ backgroundColor: "#F5F7F9", marginBottom: "20px" }}
                    value={selectedFilesInternal}
                    onChange={handleChangeInternal}
                    input={<Input />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={value.split("_")[2]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {work?.internalDocuments?.length > 0 &&
                      work?.internalDocuments.map((file: string) => (
                        <MenuItem
                          style={{ fontFamily: "montserrat" }}
                          key={file}
                          value={file}
                          classes={{ selected: classes.selected }}
                        >
                          <Checkbox
                            checked={selectedFilesInternal.indexOf(file) > -1}
                          />
                          <ListItemText primary={file.split("_")[2]} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="kunddokument">
                    Kunddokument
                  </InputLabel>
                  <Select
                    labelId="kunddokument"
                    id="kunddokument"
                    style={{ backgroundColor: "#F5F7F9", marginBottom: "20px" }}
                    multiple
                    value={selectedFiles}
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {files?.length > 0 &&
                      files.map((file) => (
                        <MenuItem
                          style={{ fontFamily: "montserrat" }}
                          key={file.name}
                          value={file.name}
                          classes={{ selected: classes.selected }}
                        >
                          <Checkbox
                            checked={selectedFiles.indexOf(file.name) > -1}
                          />
                          <ListItemText primary={file.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
            {sendToCustomer && (
              <>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="kunddokument2">
                    Kunddokument
                  </InputLabel>
                  <Select
                    labelId="kunddokument2"
                    id="kunddokument2"
                    style={{ backgroundColor: "#F5F7F9" }}
                    multiple
                    value={selectedFiles}
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {files?.length > 0 &&
                      files.map((file) => (
                        <MenuItem
                          style={{ fontFamily: "montserrat" }}
                          key={file.name}
                          value={file.name}
                          classes={{ selected: classes.selected }}
                        >
                          <Checkbox
                            checked={selectedFiles.indexOf(file.name) > -1}
                          />
                          <ListItemText primary={file.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <JobInput
                  name="messageToCustomer"
                  label="Meddelande till kund"
                  multiline
                  onChange={handleTextChange}
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      {!loading && !loadingRequest && !emailSent && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={handleSend} color="primary">
            Skicka
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    dialog: {
      width: "500px",
      position: "relative",
    },
    formControl: {
      width: "100%",
      margin: "7.5px 0",
    },
    selected: { fontWeight: 500 },

    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })

export default withStyles(styles)(MailModal)
