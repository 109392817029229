const serviceToColor = (work?: string) => {
  switch (work) {
    case "överlåtelsebesiktning(s)":
      return "#6a2c70"

    case "överlåtelsebesiktning(s) - bas":
      return "#6a2c70"

    case "överlåtelsebesiktning(s) - utökad":
      return "#6a2c70"

    case "överlåtelsebesiktning(s) - premium":
      return "#6a2c70"

    case "överlåtelsebesiktning(k)":
      return "#6a2c70"

    case "överlåtelsebesiktning(k) - bas":
      return "#6a2c70"

    case "överlåtelsebesiktning(k) - utökad":
      return "#6a2c70"

    case "statusbesiktning":
      return "#f37121"

    case "energideklaration":
      return "#b83b5e"

    case "ovk":
      return "#f08a5d"

    case "slutbesiktning":
      return "#cc7351"

    case "garantibesiktning":
      return "#e08f62"

    case "lägenhetsbesiktning":
      return "#ded7b1"

    case "köpargenomgång":
      return "#9dab86"

    case "teknisk utredning":
      return "#153e90"

    case "radonmätning":
      return "#54e346"

    case "underhållsplan":
      return "#0e49b5"

    case "KA":
      return "#00c100"

    case "övrig":
      return "#393e46"
    default:
      return "lightgray"
  }
}

export default serviceToColor
