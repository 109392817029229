import React from "react"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import Typography from "@material-ui/core/Typography"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import DesktopItem, { Job } from "./DesktopItem"
import MobileItem from "./MobileItem"
import useStore from "global-hook-store"
import authStore from "../../store/authStore"

type Props = {
  onClick?: () => void
  width: Breakpoint
  id?: string
} & Job

const JobItem: React.FC<Props> = ({
  width,
  client,
  address,
  city,
  status,
  inspection,
  dateOfInspection,
  id,
  price,
  additionalServicePrice,
  timeOfInspection,
}) => {
  const {
    state: { isAdmin },
  } = useStore(authStore)
  return (
    <>
      {isWidthUp("md", width) ? (
        <>
          <DesktopItem
            client={client}
            address={address}
            city={city}
            status={status}
            inspection={inspection}
            dateOfInspection={dateOfInspection}
            price={price}
            additionalServicePrice={additionalServicePrice}
            timeOfInspection={timeOfInspection}
            isAdmin={isAdmin}
            id={id}
          />
        </>
      ) : (
        <MobileItem
          client={client}
          address={address}
          city={city}
          status={status}
          inspection={inspection}
          dateOfInspection={dateOfInspection}
          price={price}
          additionalServicePrice={additionalServicePrice}
          timeOfInspection={timeOfInspection}
          isAdmin={isAdmin}
          id={id}
        />
      )}
    </>
  )
}

export default withWidth()(JobItem)
