import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../../ui"
import { history } from "../../pages/Admin"

import { Theme } from "@material-ui/core"
import { Email } from "@material-ui/icons"

export type User = {
  firstName: string
  lastName: string
  phone?: string
  email?: string
  city?: string
}

type Props = { id: string } & User & WithStyles<typeof styles>

const DesktopItem: React.FC<Props> = ({
  classes,
  firstName,
  lastName,
  phone,
  email,
  city,
  id,
}) => {
  return (
    <div className={classes.itemContainer}>
      <div className={classes.item} onClick={() => history.push(`users/${id}`)}>
        <div className={classes.name}>
          <p className={classes.highlight} style={{ marginRight: "5px" }}>
            {firstName}
          </p>
          <p className={classes.highlight}>{lastName}</p>
        </div>
        <p className={classes.subItem}>{phone}</p>
        <p className={classes.subItem} style={{ textTransform: "none" }}>
          {email}
        </p>
        <p className={classes.subItem}>{city}</p>
      </div>
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "75px",
      backgroundColor: colors.white,
      margin: "15px 0",
      "&:hover": {
        boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.1)",
      },
    },
    item: {
      cursor: "pointer",
      display: "flex",
      padding: "0 15px",
      alignItems: "center",
      justifyContent: "space-between",
      width: "99%",
      height: "75px",
      borderRadius: "5px",
      "& p": {
        textTransform: "capitalize",
      },
    },
    subItem: {
      width: "16%",
    },
    highlight: {
      fontSize: "16px",
      fontWeight: 500,
    },
    price: {
      display: "flex",
      justifyContent: "flex-end",
    },
    status: {
      color: "white",
      padding: "5px 15px",
      borderRadius: "20px",
      fontSize: "12px",
      letterSpacing: 1,
    },
    name: { display: "flex", width: "20%" },
  })
export default withStyles(styles)(DesktopItem)
