import React, { FC, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress } from "@material-ui/core"
import colors from "ui"

const useStyles = makeStyles({
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    backgroundColor: colors.white,
    margin: "15px 0",
    "&:hover": {
      boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.1)",
    },
  },
  item: {
    cursor: "pointer",
    display: "flex",
    padding: "0 15px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "75px",
    "& p": {
      width: "16%",
      padding: "0 2.5px",
      textTransform: "capitalize",
    },
  },
  highlight: {
    fontSize: "16px",
    fontWeight: 500,
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-end",
  },
})

type Props = {
  clientAddress?: string
  clientCity?: string
  clientMail?: string
  clientName?: string
  clientPhone?: string
  clientZipCode?: string
}

const CustomerItem: FC<Props> = ({
  clientAddress,
  clientCity,
  clientMail,
  clientName,
  clientPhone,
  clientZipCode,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.itemContainer}>
      <div className={classes.item}>
        <p style={{ width: "18%" }} className={classes.highlight}>
          {clientName}
        </p>
        <p style={{ width: "14%" }}>{clientAddress}</p>
        <p style={{ width: "14%" }}>{clientCity}</p>
        <p
          style={{
            width: "14%",
          }}
        >
          {clientZipCode}
        </p>
        <p style={{ width: "12%" }} className={classes.alignLeft}>
          {clientMail}
        </p>
        <p style={{ width: "12%" }} className={classes.alignLeft}>
          {clientPhone}
        </p>
      </div>
    </div>
  )
}

export default CustomerItem
