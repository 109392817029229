import { createStore } from "global-hook-store"

const navStore = createStore(
  {
    navOpen: false,
    searchOpen: false,
    addInspectorOpen: false,
    addBlockedDateOpen: false,
  },
  {
    setNavOpen: (state, navOpen: boolean) => ({
      ...state,
      navOpen,
    }),
    setSearchOpen: (state, searchOpen: boolean) => ({
      ...state,
      searchOpen,
    }),
    setAddInspectorOpen: (state, addInspectorOpen: boolean) => ({
      ...state,
      addInspectorOpen,
    }),
    setAddBlockedDateOpen: (state, addBlockedDateOpen: boolean) => ({
      ...state,
      addBlockedDateOpen,
    }),
  }
)

export default navStore
