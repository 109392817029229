import axios, { AxiosRequestConfig } from "axios"

const updateBlockedDate = async (id: string, data: any) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/user/blockedDate/${id}`,
    data,
    config
  )
  return response.data
}

export default updateBlockedDate
