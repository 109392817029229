import React, { useEffect, useRef, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"
import axios, { AxiosRequestConfig } from "axios"

import DateFnsUtils from "@date-io/date-fns"
import {
  Theme,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  Hidden,
  CircularProgress,
  Popover,
  Input,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form"
import JobInput from "../components/JobInput"
import FormSection from "../components/FormSection"
import Divider from "../components/Divider"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import firstLetterUppercase from "utils/firstLetterUppercase"

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import addWork, { WorkData } from "../api/addWork"
import useStore from "global-hook-store"
import InspectorStore from "../store/InspectorStore"
import AllowedRole from "../components/AllowedRole"
import classNames from "classnames"
import workStore from "../store/workStore"
import authStore from "../store/authStore"
import AdditionalServices from "../data/AdditionalServices"
import Services from "../data/Services"
import moment from "moment"
import "moment/locale/sv"
import ErrorDisplay from "components/ErrorDisplay"
import { history } from "../pages/Admin"

import Leads from "../data/Leads"

type DateError = {
  error: boolean
  message: string
}

type AdditionalService = {
  price?: number
  service?: string
}
type Props = {} & WithStyles<typeof styles>

const NewJob: React.FC<Props> = ({ classes }) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm<WorkData>()

  const clientToObject = () => {
    const watchFields = getValues([
      "clientName",
      "clientAddress",
      "clientZipCode",
      "clientCity",
    ])

    watchFields["clientName"]?.length &&
      setValue("propertyOwner", watchFields["clientName"])

    watchFields["clientAddress"]?.length &&
      setValue("objectAddress", watchFields["clientAddress"])

    watchFields["clientZipCode"]?.length &&
      setValue("objectZipCode", watchFields["clientZipCode"])

    watchFields["clientCity"]?.length &&
      setValue("objectCity", watchFields["clientCity"])
  }

  const objectToClient = () => {
    const watchFields = getValues([
      "propertyOwner",
      "objectAddress",
      "objectZipCode",
      "objectCity",
    ])

    watchFields["propertyOwner"]?.length &&
      setValue("clientName", watchFields["propertyOwner"])

    watchFields["objectAddress"]?.length &&
      setValue("clientAddress", watchFields["objectAddress"])

    watchFields["objectZipCode"]?.length &&
      setValue("clientZipCode", watchFields["objectZipCode"])

    watchFields["objectCity"]?.length &&
      setValue("clientCity", watchFields["objectCity"])
  }

  const {
    state: { availableInspectors },
    actions: { setAvailableInspectors },
  } = useStore(InspectorStore)

  const {
    state: { workError, createdWork },
    actions: { createWork, resetWork },
  } = useStore(workStore)

  const {
    state: { role, id: userId },
  } = useStore(authStore)

  // ---- INITIAL DATE FOR CALENDAR
  const initialDate: any = new Date()
  initialDate.setHours(12, 0)

  // ---- START TIME OF INSPECTION
  const [selectedDate, setSelectedDate] = useState<null | Date>(null)

  // ---- END TIME OF INSPECTION
  const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null)

  // SAVE CUSTOMER STATE
  const [saveToCustomerRegistry, setSaveToCustomerRegistry] = useState<boolean>(
    false
  )

  const [additionalServices, setAdditionalServices] = useState<
    AdditionalService[]
  >([])

  const [dateError, setDateError] = useState<DateError>({
    message: "",
    error: false,
  })

  const addAdditionalService = () => {
    const watchFields = getValues(["servicePrice", "service"])
    const price = watchFields["servicePrice"]
    const service = watchFields["service"]
    const additionalService: AdditionalService = { price, service }
    setAdditionalServices([...additionalServices, additionalService])
    setValue("servicePrice", "")
    setValue("service", "")
  }

  useEffect(() => {
    setAvailableInspectors({
      fromDate: null,
      toDate: null,
    })

    if (role === "inspector") {
      setValue("status", "booked")
    }

    return () => {
      resetWork()
    }
  }, [])

  const inspectorValue = watch("inspector")

  useEffect(() => {
    // This could be refactored
    if (role !== "inspector") {
      if (inspectorValue?.length > 0 && selectedDate && selectedEndDate) {
        setValue("status", "booked")
      } else {
        setValue("status", "unprocessed")
      }
    } else if (role === "inspector") {
      if (selectedDate && selectedEndDate) {
        setValue("status", "booked")
      } else {
        setValue("status", "unprocessed")
      }
    }
  }, [inspectorValue, selectedDate, selectedEndDate])

  // Convert date to string so that useEffect triggers on all changes
  const selectedDateString = selectedDate?.toString()
  const selectedEndDateString = selectedEndDate?.toString()

  useEffect(() => {
    if (selectedDate && selectedEndDate) {
      // IF A START AND END DATE IS CHOOSEN THEN GET INSPECTORS FOR THAT PERIOD
      setAvailableInspectors({
        fromDate: selectedDate,
        toDate: selectedEndDate,
      })
    } else {
      // IF A START AND END DATE IS NOT CHOOSEN THEN GET ALL INSPECTORS
      setAvailableInspectors({
        fromDate: null,
        toDate: null,
      })
    }
  }, [selectedDateString, selectedEndDateString])

  // Creates jobs and redirects to correct job
  const addWorkAndRedirect = async (data: WorkData) => {
    // Reset error state if there is any active error
    setDateError({ error: false, message: "" })

    const res = await createWork({
      data,
      saveToCustomerRegistry,
    })

    const createdWorkResponse = res.createdWork

    // IF ERROR - RETURN
    if (createdWorkResponse.error) {
      // Set error messsage here if needed
      return
    }

    // TODO - FINNS DET BÄTTRE SÄTT ATT LÖSA DETTA PÅ? -
    history.push(`/jobs/${createdWorkResponse.data?._id}`)
  }

  const onSubmit = async (data: WorkData) => {
    data["additionalService"] = additionalServices

    if (selectedDate && selectedEndDate) {
      if (selectedEndDate > selectedDate) {
        data["dateOfInspection"] = selectedDate
        data["endDateOfInspection"] = selectedEndDate

        if (role === "inspector") {
          data["inspector"] = [userId]
        }

        await addWorkAndRedirect(data)
      } else {
        // User must apply an end date that i sgreater than the start date
        setDateError({
          message: "Slutdatum måste vara senare än startdatum",
          error: true,
        })
      }
    } else {
      // If no date is applied
      await addWorkAndRedirect(data)
    }
  }

  // ---- START TIME OF INSPECTION
  const handleDateChange = (date: Date) => {
    const year: number = date?.getFullYear()
    const month: number = date?.getMonth()
    const day: number = date?.getDate()

    // When selectedDate is changed we set the end date to selectedDate +1 hour
    const changedDate = new Date(year, month, day, hour, minute)
    const changedEndDate = new Date(year, month, day, hour + 1, minute)

    setSelectedDate(changedDate)

    if (!selectedEndDate) {
      setEndHour(hour + 1)
      setSelectedEndDate(changedEndDate)
    }
  }

  const timeFromDate = moment(selectedDate).format("LT")

  // ---- END TIME OF INSPECTION
  const handleEndDateChange = (date: Date) => {
    const year: number = date?.getFullYear()
    const month: number = date?.getMonth()
    const day: number = date?.getDate()

    const changedDate = new Date(year, month, day, endHour, endMinute)
    setSelectedEndDate(changedDate)
  }

  const timeFromEndDate = moment(selectedEndDate).format("LT")

  const deleteService = (index: number) => {
    const s = [...additionalServices]
    s.splice(index, 1)
    setAdditionalServices(s)
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    const date = selectedDate && selectedDate
    date?.setHours(hour, minute)
    setSelectedDate(date)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const [hour, setHour] = useState<number>(
    selectedDate ? selectedDate?.getHours() : 12
  )

  const [minute, setMinute] = useState<number>(
    selectedDate ? selectedDate?.getMinutes() : 0
  )

  const increaseHours = () => {
    const maxHour = 23
    if (hour < maxHour) {
      setHour(hour + 1)
    } else {
      setHour(0)
    }
  }

  const decreaseHours = () => {
    const minHour = 0
    if (hour > minHour) {
      setHour(hour - 1)
    } else {
      setHour(23)
    }
  }

  const increaseMinute = () => {
    const maxMinute = 45
    if (minute < maxMinute) {
      setMinute(minute + 15)
    } else {
      setMinute(0)
    }
  }

  const decreaseMinute = () => {
    const minMinute = 0
    if (minute > minMinute) {
      setMinute(minute - 15)
    } else {
      setMinute(45)
    }
  }
  // END TIME ---- OBV ALL OF THIS SHOULD BE BETTER
  const [
    anchorElEnd,
    setAnchorElEnd,
  ] = React.useState<HTMLButtonElement | null>(null)
  const openEnd = Boolean(anchorElEnd)
  const idEnd = openEnd ? "simple-popover-end" : undefined

  const handleClickEnd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElEnd(event.currentTarget)
  }

  const handleEndClose = () => {
    setAnchorElEnd(null)
    const date = selectedEndDate && selectedEndDate
    date?.setHours(endHour, endMinute)
    setSelectedEndDate(date)
  }

  const [endHour, setEndHour] = useState<number>(
    selectedEndDate ? selectedEndDate?.getHours() : 12
  )
  const [endMinute, setEndMinute] = useState<number>(
    selectedEndDate ? selectedEndDate?.getMinutes() : 0
  )

  const increaseEndHours = () => {
    const maxHour = 23
    if (endHour < maxHour) {
      setEndHour(endHour + 1)
    } else {
      setEndHour(0)
    }
  }

  const decreaseEndHours = () => {
    const minHour = 0
    if (endHour > minHour) {
      setEndHour(endHour - 1)
    } else {
      setEndHour(23)
    }
  }

  const increaseEndMinute = () => {
    const maxMinute = 45
    if (endMinute < maxMinute) {
      setEndMinute(endMinute + 15)
    } else {
      setEndMinute(0)
    }
  }

  const decreaseEndMinute = () => {
    const minMinute = 0
    if (endMinute > minMinute) {
      setEndMinute(endMinute - 15)
    } else {
      setEndMinute(45)
    }
  }

  const resetDates = () => {
    setSelectedDate(null)
    setSelectedEndDate(null)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveToCustomerRegistry(event.target.checked)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
      {workError && <ErrorDisplay />}

      <Grid container>
        <FormSection paddingTop={true} title="Beställare">
          <Hidden smDown>
            <Button
              style={{
                zIndex: 5,
                width: "32px",
                minWidth: "32px",
                position: "absolute",
                top: "100px",
                right: "-15px",
              }}
              onClick={() => clientToObject()}
            >
              <ArrowForwardIosOutlinedIcon />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Button
              className={classes.editBtn}
              style={{
                backgroundColor: colors.picton,
                color: colors.white,
              }}
              onClick={() => clientToObject()}
            >
              <ArrowForwardIosOutlinedIcon
                style={{
                  transform: "rotate(90deg) ",
                }}
              />
            </Button>
          </Hidden>
          <JobInput
            name="clientName"
            label="Namn"
            error={errors.clientName && true}
            register={register()}
          />

          <JobInput
            name="clientAddress"
            label="Adress"
            error={errors.clientAddress && true}
            register={register()}
          />
          <JobInput
            name="clientZipCode"
            label="Postnummer"
            error={errors.clientZipCode && true}
            register={register({
              minLength: 1,
              setValueAs: (value) => value && value.replace(/\s/g, ""),
            })}
            // register={register({
            //   minLength: 1,
            //   setValueAs: (value) =>
            //     value && parseInt(value.replace(/\s/g, "")),
            // })}
          />
          <JobInput
            name="clientCity"
            label="Ort"
            error={errors.clientCity && true}
            register={register()}
          />
          <JobInput
            name="clientPhone"
            label="Telefon"
            error={errors.clientPhone && true}
            register={register()}
          />
          <JobInput
            name="clientMail"
            label="Mail"
            error={errors.clientMail && true}
            register={register()}
          />
          <JobInput
            name="clientIdNumber"
            label="OrgNr / PersNr"
            error={errors.clientIdNumber && true}
            register={register()}
          />
        </FormSection>
        <FormSection paddingTop={true} title="Objekt">
          <Hidden smDown>
            <Button
              style={{
                zIndex: 5,
                width: "32px",
                minWidth: "32px",
                position: "absolute",
                top: "205px",
                left: "-15px",
              }}
              onClick={() => objectToClient()}
            >
              <ArrowBackIosOutlinedIcon />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Button
              className={classes.editBtn}
              style={{
                backgroundColor: colors.picton,
                color: colors.white,
              }}
              onClick={() => clientToObject()}
            >
              <ArrowForwardIosOutlinedIcon
                style={{
                  transform: "rotate(-90deg) ",
                }}
              />
            </Button>
          </Hidden>

          <JobInput
            name="propertyOwner"
            label="Fastighetsägare"
            error={errors.propertyOwner && true}
            register={register()}
          />
          <JobInput
            name="objectAddress"
            label="Adress"
            error={errors.objectAddress && true}
            register={register()}
          />
          <JobInput
            name="objectZipCode"
            label="Postnummer"
            error={errors.objectZipCode && true}
            register={register({
              minLength: 1,
              setValueAs: (value) => value && value.replace(/\s/g, ""),
            })}
          />
          <JobInput
            name="objectCity"
            label="Ort"
            error={errors.objectCity && true}
            register={register()}
          />
          <JobInput
            uppercase
            name="propertyDesignation"
            label="Fastighetsbeteckning"
            error={errors.propertyDesignation && true}
            register={register()}
          />
          <JobInput
            name="assignmentNumber"
            label="Uppdragsnummer"
            error={errors.assignmentNumber && true}
            register={register()}
          />
        </FormSection>
        <Divider />
        <FormSection title="Kontaktperson">
          <JobInput
            name="contactPerson.name"
            label="Namn"
            register={register()}
          />
          <JobInput
            name="contactPerson.phone"
            label="Telefon"
            register={register()}
          />
          <JobInput
            name="contactPerson.email"
            label="Mail"
            register={register()}
          />
        </FormSection>
        <Divider />
        <FormSection title="Besiktning">
          <JobInput
            name="price"
            label="Pris"
            error={errors.price && true}
            register={register({
              setValueAs: (value) => parseInt(value.replace(/\s/g, "")),
            })}
          />

          <div className={classes.leadContainer}>
            <p className={classes.label}>Tjänst</p>
            <FormControl
              className={classes.formControl}
              error={errors.inspection && true}
            >
              <Controller
                as={
                  <Select
                    disableUnderline
                    value=""
                    classes={{
                      root: classes.select,
                    }}
                  >
                    {Services &&
                      Services.map((service: any) => (
                        <MenuItem
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "Montserrat",
                          }}
                          key={service.service}
                          value={service.service}
                        >
                          {service.service}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="inspection"
                control={control}
                defaultValue=""
              />
            </FormControl>
          </div>
          <AllowedRole roles={["admin", "sales"]}>
            <div className={classes.leadContainer}>
              <p className={classes.label}>Besiktningsmän</p>
              <FormControl
                className={classes.formControl}
                error={errors.inspector && true}
              >
                <Controller
                  name="inspector"
                  control={control}
                  defaultValue={[]}
                  render={({ onChange, value, name }) => (
                    <Select
                      name={name}
                      disableUnderline
                      multiple
                      value={value}
                      classes={{
                        root: classes.select,
                      }}
                      onChange={(e) => {
                        onChange(e)
                        // handleInspectorChange(e)
                      }}
                      input={<Input />}
                      renderValue={(selected) => {
                        var findCorrectInspectors = (selected as any[]).map(
                          (value) => {
                            let inspector = availableInspectors?.data.find(
                              (x) => x._id === value
                            )
                            return `${inspector?.firstName} ${inspector?.lastName}`
                          }
                        )
                        return (findCorrectInspectors as string[]).join(", ")
                      }}
                    >
                      {availableInspectors &&
                        availableInspectors?.data?.map((inspector: any) => {
                          const isAvailableInObject = inspector.hasOwnProperty(
                            "available"
                          )
                          const isInspectorAvailable = () => {
                            if (isAvailableInObject) {
                              if (inspector?.available) {
                                return true
                              } else {
                                return false
                              }
                            } else {
                              return true
                            }
                          }

                          return (
                            <MenuItem
                              key={inspector._id}
                              value={inspector._id}
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ margin: 0 }}>
                                  {`${inspector.firstName} ${inspector.lastName}`}
                                </p>
                                {!isInspectorAvailable() && (
                                  <Tooltip title="Besiktningsmannen är antingen upptagen eller redan utbokad på arbete den här perioden">
                                    <ErrorOutlineOutlinedIcon
                                      fontSize="small"
                                      htmlColor={colors.error}
                                      style={{ marginLeft: "5px" }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              <p style={{ margin: 0, color: "gray" }}>
                                {inspector.city}
                              </p>
                            </MenuItem>
                          )
                        })}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <JobInput
              name="inspectorFee"
              label="Arvode"
              error={errors.inspectorFee && true}
              register={register({
                setValueAs: (value) => parseInt(value.replace(/\s/g, "")),
              })}
            />
          </AllowedRole>

          {/* ----- START TIME OF INSPECTION -----*/}
          {/* Make a component of date and time */}
          <div style={{ marginTop: "20px" }}>
            <p className={classes.label}>Startdatum</p>
            <Button
              aria-describedby={id}
              onClick={handleClick}
              className={classes.pickTimeBtn}
              disabled={!selectedDate}
            >
              {selectedDate ? timeFromDate : "Tid"}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className={classes.timepickerPopoverContainer}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button onClick={() => increaseHours()}>
                    <ArrowForwardIosOutlinedIcon
                      style={{
                        transform: "rotate(-90deg) ",
                      }}
                    />
                  </Button>
                  <div>{hour}</div>
                  <Button onClick={() => decreaseHours()}>
                    <ArrowForwardIosOutlinedIcon
                      style={{
                        transform: "rotate(90deg) ",
                      }}
                    />
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button onClick={() => increaseMinute()}>
                    <ArrowForwardIosOutlinedIcon
                      style={{
                        transform: "rotate(-90deg) ",
                      }}
                    />
                  </Button>
                  <div>{minute}</div>
                  <Button onClick={() => decreaseMinute()}>
                    <ArrowForwardIosOutlinedIcon
                      style={{
                        transform: "rotate(90deg) ",
                      }}
                    />
                  </Button>
                </div>
              </div>
            </Popover>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                initialFocusedDate={initialDate}
                value={selectedDate}
                emptyLabel="Datum"
                onChange={(date: any) => handleDateChange(date)}
                className={classes.timePicker}
                style={{ paddingLeft: "10px" }}
                InputProps={{
                  classes: {
                    root: classes.inputPicker,
                    input: classes.input,
                    notchedOutline: classes.outline,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {errors.dateOfInspection && (
            <p style={{ color: "red" }}>Välj ett datum</p>
          )}

          {/* ----- END TIME OF INSPECTION -----*/}
          {/* Make a component of date and time */}
          {selectedDate && (
            <div style={{ marginTop: "20px" }}>
              <p className={classes.label}>Slutdatum</p>
              <Button
                aria-describedby={idEnd}
                onClick={handleClickEnd}
                className={classes.pickTimeBtn}
                disabled={!selectedEndDate}
              >
                {selectedEndDate ? timeFromEndDate : "Tid"}
              </Button>
              <Popover
                id={idEnd}
                open={openEnd}
                anchorEl={anchorElEnd}
                onClose={handleEndClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className={classes.timepickerPopoverContainer}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={() => increaseEndHours()}>
                      <ArrowForwardIosOutlinedIcon
                        style={{
                          transform: "rotate(-90deg) ",
                        }}
                      />
                    </Button>
                    <div>{endHour}</div>
                    <Button onClick={() => decreaseEndHours()}>
                      <ArrowForwardIosOutlinedIcon
                        style={{
                          transform: "rotate(90deg) ",
                        }}
                      />
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={() => increaseEndMinute()}>
                      <ArrowForwardIosOutlinedIcon
                        style={{
                          transform: "rotate(-90deg) ",
                        }}
                      />
                    </Button>
                    <div>{endMinute}</div>
                    <Button onClick={() => decreaseEndMinute()}>
                      <ArrowForwardIosOutlinedIcon
                        style={{
                          transform: "rotate(90deg) ",
                        }}
                      />
                    </Button>
                  </div>
                </div>
              </Popover>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  initialFocusedDate={initialDate}
                  // initialFocusedDate={selectedDate}
                  value={selectedEndDate}
                  emptyLabel="Datum"
                  onChange={(date: any) => handleEndDateChange(date)}
                  className={classes.timePicker}
                  style={{ paddingLeft: "10px" }}
                  InputProps={{
                    classes: {
                      root: classes.inputPicker,
                      input: classes.input,
                      notchedOutline: classes.outline,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                color: colors.error,
                margin: 0,
              }}
            >
              {dateError.error && dateError.message}
            </p>

            {selectedDate && (
              <p
                onClick={() => resetDates()}
                style={{
                  alignSelf: "flex-end",
                  color: colors.azure,
                  margin: 0,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Rensa datum
              </p>
            )}
          </div>
        </FormSection>
        <FormSection paddingTop={true} title="Tilläggstjänst">
          <div className={classes.additionalServiceContainer}>
            <div
              className={classNames(
                classes.leadContainer,
                classes.additionalServiceSelect
              )}
            >
              <p className={classes.label}>Tilläggstjänst</p>
              <FormControl
                className={classes.formControl}
                error={errors.service && true}
              >
                <Controller
                  as={
                    <Select
                      disableUnderline
                      value="value"
                      classes={{
                        root: classes.select,
                      }}
                    >
                      {AdditionalServices &&
                        AdditionalServices.map((service: any) => (
                          <MenuItem
                            key={service.service}
                            value={service.service}
                            style={{
                              fontFamily: "Montserrat",
                            }}
                          >
                            {firstLetterUppercase(service.service)}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                  name="service"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </div>
            <JobInput
              name="servicePrice"
              label="Pris"
              error={errors.servicePrice && true}
              register={register({
                setValueAs: (value) => parseInt(value.replace(/\s/g, "")),
              })}
            />
            <Button
              onClick={() => addAdditionalService()}
              className={classes.additionalServiceBtn}
            >
              <AddOutlinedIcon style={{ color: colors.white }} />
            </Button>
          </div>
          {additionalServices.length > 0 &&
            additionalServices.map((x, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      marginRight: "10px",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {x.service}
                  </p>
                  <p>{x.price}</p>
                </div>
                <Button onClick={() => deleteService(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </div>
            ))}
        </FormSection>

        {/* Förslag på besiktningsman ska vara här */}
        {/* <FormSection title=""></FormSection> */}
        <Divider />
        <FormSection title="Info">
          <div className={classes.leadContainer}>
            <p className={classes.label}>Lead</p>
            <FormControl
              className={classes.formControl}
              error={errors.lead && true}
            >
              <Controller
                as={
                  <Select
                    disableUnderline
                    value=""
                    classes={{
                      root: classes.select,
                    }}
                  >
                    {Leads &&
                      Leads.map((lead: any) => (
                        <MenuItem
                          key={lead.lead}
                          value={lead.lead}
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {lead.lead}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="lead"
                control={control}
                defaultValue=""
              />
            </FormControl>
          </div>
          <div className={classes.leadContainer}>
            <p className={classes.label}>Status</p>
            <FormControl
              className={classes.formControl}
              error={errors.status && true}
            >
              <Controller
                as={
                  <Select
                    disableUnderline
                    value="bokad"
                    classes={{
                      root: classes.select,
                    }}
                  >
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                      }}
                      value="canceled"
                    >
                      Avbokad
                    </MenuItem>
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                      }}
                      value="unprocessed"
                    >
                      Obehandlad
                    </MenuItem>
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                      }}
                      value="booked"
                    >
                      Bokad
                    </MenuItem>
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                      }}
                      value="ongoing"
                    >
                      Pågående
                    </MenuItem>
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                      }}
                      value="sent"
                    >
                      Utskickad
                    </MenuItem>
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Montserrat",
                      }}
                      value="completed"
                    >
                      Klar
                    </MenuItem>
                  </Select>
                }
                name="status"
                control={control}
                defaultValue="unprocessed"
              />
            </FormControl>
          </div>
          <JobInput
            name="otherInformation"
            label="Övrigt"
            error={errors.otherInformation && true}
            multiline
            register={register()}
          />
          <div className={classes.leadContainer}>
            <FormControlLabel
              classes={{ label: classes.switchLabel }}
              control={
                <Switch
                  color="primary"
                  name="saveToCustomerRegistry"
                  checked={saveToCustomerRegistry}
                  onChange={handleSwitchChange}
                />
              }
              label="Spara kund"
            />
          </div>
        </FormSection>
        <Grid className={classes.buttonContainer} xs={12} container item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Button
              type="submit"
              disabled={createdWork.loading}
              className={classes.button}
              classes={{ disabled: classes.addDisabled }}
              style={{ alignSelf: "flex-end" }}
            >
              {createdWork.loading ? (
                <CircularProgress size="25px" style={{ color: "white" }} />
              ) : (
                "Skapa arbete"
              )}
            </Button>
            <p
              style={{
                color: colors.error,
                margin: "10px 0 0 0",
                wordWrap: "break-word",
              }}
            >
              {dateError.error &&
                "Något gick fel. Kolla så att all information ovan är korrekt."}
            </p>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "30px 5px",
      color: colors.zodiac,
      backgroundColor: colors.white,
      [breakpoints.down("md")]: {
        paddingTop: "0px",
      },
    },
    formContainer: {
      backgroundColor: "transparent",
      padding: "0 25px",
      alignContent: "flex-start",
    },

    divider: {
      width: "100%",
      height: "0.5px",
      backgroundColor: "#E4E4E4",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "50px 25px 0 25px",
      [breakpoints.down("sm")]: {
        justifyContent: "flex-start",

        padding: 0,
        marginTop: "20px",
      },
    },
    button: {
      width: "200px",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    timePicker: {
      width: "50%",
      marginTop: "7.5px",
    },
    inputPicker: {
      backgroundColor: "#F5F7F9",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
    },
    outline: {
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
    },
    input: {
      width: "100%",
      cursor: "pointer",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#F5F7F9",
      margin: "7.5px 0",
      maxHeight: "56px",
    },
    select: {
      width: "100%",
      maxHeight: "56px",
      padding: "17.5px 14px",
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      color: "gray",
      fontSize: "14px",
    },
    leadContainer: {
      marginTop: "20px",
      width: "100%",
    },
    uploadBtn: {
      height: "55px",
      border: "1px solid #E4E4E4",
      backgroundColor: "#F5F7F9",
      borderRadius: "2.5px",
      margin: "7.5px 0",
      width: "100%",
      color: "gray",
      fontFamily: "Montserrat",
    },

    editBtn: {
      // width: "32px",
      minWidth: "32px",
      position: "absolute",
      top: 0,
      right: "25px",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      [breakpoints.down("sm")]: {
        top: "30px",
        right: 0,
      },
    },
    additionalServiceContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      [breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    additionalServiceSelect: {
      marginRight: "10px",
      [breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: "-20px",
      },
    },
    additionalServiceBtn: {
      margin: "7.5px 0 7.5px 10px",
      height: "56px",
      backgroundColor: colors.picton,
      color: colors.white,
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
    pickTimeContainer: {
      marginTop: "20px",
      width: "50%",
      marginRight: "10px",
    },
    pickTimeBtn: {
      height: "55px",
      border: "1px solid #E4E4E4",
      backgroundColor: "#f5f7f9",
      borderRadius: "2.5px",
      margin: "7.5px 0 10px 0",
      width: "50%",
      color: "#0000008a",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Montserrat",
      paddingRight: "10px",
      textTransform: "capitalize",
    },
    timepickerPopoverContainer: {
      display: "flex",
      padding: "10px",
      fontFamily: "Montserrat",
      fontSize: "16px",
    },
    switchLabel: { fontFamily: "Montserrat", fontSize: "14px" },
  })
export default withStyles(styles)(NewJob)
