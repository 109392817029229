import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../../ui"
import { history } from "../../pages/Admin"
import statusToColor from "../../utils/statusToColor"

import { Button, Theme } from "@material-ui/core"

export type Job = {
  client?: string
  address?: string
  city?: string
  status?: string
  inspection?: string
  dateOfInspection?: null | Date | string
  price?: number
  additionalServicePrice?: number
  timeOfInspection?: string | null
}
type Props = {
  id?: string
  isAdmin: boolean
} & Job &
  WithStyles<typeof styles>

const DesktopItem: React.FC<Props> = ({
  classes,
  client,
  address,
  city,
  status,
  inspection,
  dateOfInspection,
  timeOfInspection,
  price,
  additionalServicePrice,
  isAdmin,
  id,
}) => {
  const tjena = () => {
    // TODO: GET ROLES FROM BACKEND

    if (isAdmin) {
      history.push(`/jobs/${id}`)
    } else {
      history.push("/jobs/prepurchaseinspection")
    }
  }

  let formatter = new Intl.NumberFormat("se-SE", {
    style: "currency",
    currency: "SEK",
  })

  const totPrice = (price?: number, additionalServicePrice?: number) => {
    let parsedPrice = price ? price : 0
    let parsedAdditionalServicePrice = additionalServicePrice
      ? additionalServicePrice
      : 0

    return parsedPrice + parsedAdditionalServicePrice
  }

  const totalPrice = formatter.format(totPrice(price, additionalServicePrice))
  return (
    <div className={classes.itemContainer}>
      <div className={classes.item} onClick={() => tjena()}>
        <div
          style={{ backgroundColor: statusToColor(status) }}
          className={classes.statusBar}
        />
        <p style={{ width: "18%" }} className={classes.highlight}>
          {client}
        </p>
        <p style={{ width: "14%" }}>{address}</p>
        <p style={{ width: "14%" }}>{city}</p>
        <p
          style={{
            width: "14%",
          }}
        >
          {inspection}
        </p>
        <p style={{ width: "12%" }} className={classes.alignLeft}>
          {dateOfInspection}
        </p>
        <p style={{ width: "12%" }} className={classes.alignLeft}>
          {timeOfInspection}
        </p>

        <p style={{ width: "16%" }} className={classes.alignLeft}>
          {isAdmin ? totalPrice : "10:00 - 15:00"}
        </p>
      </div>
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "75px",
      backgroundColor: colors.white,
      margin: "15px 0",
      "&:hover": {
        boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.1)",
      },
    },
    item: {
      cursor: "pointer",
      display: "flex",
      paddingRight: "15px",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "75px",
      "& p": {
        width: "16%",
        padding: "0 2.5px",
        textTransform: "capitalize",
      },
    },
    highlight: {
      fontSize: "16px",
      fontWeight: 500,
      display: "-webkit-box",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    alignLeft: {
      display: "flex",
      justifyContent: "flex-end",
    },
    status: {
      color: "white",
      padding: "5px 15px",
      borderRadius: "20px",
      fontSize: "12px",
      letterSpacing: 1,
      textTransform: "capitalize",
    },
    statusBar: {
      height: "100%",
      width: "6px",
      backgroundColor: "lightgray",
      marginRight: "10px",
    },
  })
export default withStyles(styles)(DesktopItem)
