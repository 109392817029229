import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { Theme, Grid } from '@material-ui/core';

type Props = {} & WithStyles<typeof styles>;

const Divider: React.FC<Props> = ({ classes }) => {
    return (
        <Grid className={classes.formContainer} xs={12} container item>
            <div className={classes.divider}></div>
        </Grid>
    );
};

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
    createStyles({
        formContainer: {
            backgroundColor: 'transparent',
            padding: '50px 20px',
            alignContent: 'flex-start',
            [breakpoints.down('md')]: {
                padding: '40px 20px'
            }
        },
        divider: {
            width: '100%',
            height: '0.5px',
            backgroundColor: '#E4E4E4'
        }
    });

export default withStyles(styles)(Divider);
