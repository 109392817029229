import React, { useEffect } from "react"
import { Router, Switch, Route } from "react-router-dom"

import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles"
import { createBrowserHistory } from "history"

import Jobs from "./Jobs"
import Statistics from "./Statistics"
import Users from "./Users"
import NewJob from "./NewJob"
import Calender from "./Calender"
import NotFound from "./NotFound"
import PrePurchaseInspection from "./PrePurchaseInspection"

import Header from "../components/Header"
import Navigation from "../components/Navigation"
import Job from "./Job"
import User from "./User"
import Information from "../components/PrePurchaseModule/Information"
import Outside from "../components/PrePurchaseModule/Outside"
import Protocol from "../components/PrePurchaseModule/Protocol"
import Section from "../components/PrePurchaseModule/AddSection"
import Floors from "../components/PrePurchaseModule/Floors"
import Sections from "../components/PrePurchaseModule/Sections"
import SearchDrawer from "../components/SearchDrawer"
import useStore from "global-hook-store"
import authStore from "../store/authStore"
import NewUser from "./NewUser"
import PrivateRoute from "../components/PrivateRoute"
import ScrollToTop from "../components/ScrollToTop"
import CustomerRegistry from "./CustomerRegistry"
import { Hidden } from "@material-ui/core"

export const history = createBrowserHistory()
type Props = {} & WithStyles<typeof styles>

const Admin: React.FC<Props> = ({ classes }) => {
  const {
    state: { isAdmin },
  } = useStore(authStore)

  return (
    <Router history={history}>
      <ScrollToTop />
      <div className={classes.root}>
        <Header />
        <Navigation />
        <SearchDrawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <PrivateRoute
              roles={["admin", "sales", "inspector"]}
              exact
              path="/jobs"
              component={Jobs}
            />

            <PrivateRoute
              roles={["admin", "sales", "inspector"]}
              exact
              path="/jobs/new"
              component={NewJob}
            />
            <PrivateRoute
              roles={["admin", "sales", "inspector"]}
              path="/jobs/:job"
              component={Job}
            />

            <PrivateRoute
              roles={["admin", "sales", "inspector"]}
              exact
              path="/calender"
              component={Calender}
            />

            <PrivateRoute
              roles={["admin", "sales"]}
              exact
              path="/statistics"
              component={Statistics}
            />

            <PrivateRoute
              roles={["admin", "sales"]}
              exact
              path="/users"
              component={Users}
            />

            <PrivateRoute
              roles={["admin", "sales"]}
              exact
              path="/users/new"
              component={NewUser}
            />

            <PrivateRoute
              roles={["admin", "sales"]}
              path="/users/:user"
              component={User}
            />

            <PrivateRoute
              roles={["admin", "sales"]}
              path="/customer-registry"
              component={CustomerRegistry}
            />

            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    </Router>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: spacing(3),
      [breakpoints.down("md")]: {
        padding: "0px",
      },
    },
  })

export default withStyles(styles)(Admin)
