import React, { FC, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import colors from "ui"
import {
  AppBar,
  Dialog,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Theme,
  createStyles,
} from "@material-ui/core"

import AddWork from "components/CalendarModal/AddWork"
import AddBlockedDate from "components/CalendarModal/AddBlockedDate"
import workStore from "store/workStore"
import useStore from "global-hook-store"
import blockedDateStore from "store/blockedDateStore"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: colors.alice,
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)",
    },
    indicator: { backgroundColor: colors.picton },
    tabs: {
      color: "#3e3e3e",
      fontFamily: "Montserrat",
      textTransform: "none",
      fontSize: "16px",
    },
    tab: {
      backgroundColor: "blue",
    },
    selected: {
      backgroundColor: colors.white,
    },
    loadingContainer: {
      [theme.breakpoints.up("md")]: {
        minHeight: "700px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
)

type Props = { open: boolean; date?: Date; handleClose: () => void }

const CalendarModal: FC<Props> = ({ open, date, handleClose }) => {
  const classes = useStyles()

  const [tabState, setTabState] = useState<string>("work")

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabState(newValue)
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <AppBar classes={{ root: classes.appBar }} position="static">
        <Tabs
          value={tabState}
          onChange={handleChange}
          aria-label="calender-tabs"
          variant="fullWidth"
          classes={{ root: classes.tabs, indicator: classes.indicator }}
        >
          <Tab
            classes={{ root: classes.tabs, selected: classes.selected }}
            label="Arbete"
            value="work"
          />
          <Tab
            label="Upptagen"
            value="blockedDate"
            classes={{ root: classes.tabs, selected: classes.selected }}
          />
        </Tabs>
      </AppBar>
      {tabState === "work" && <AddWork dateFrom={date} close={handleClose} />}
      {tabState === "blockedDate" && (
        <AddBlockedDate dateFrom={date} close={handleClose} />
      )}
    </Dialog>
  )
}

export default CalendarModal
