import * as React from "react"
import List from "@material-ui/core/List"
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core"

import classNames from "classnames"
import NavListItem from "./NavListItem"

type Props = { onClose?: () => void } & WithStyles<typeof styles>

const NavList: React.FC<Props> = ({ classes, onClose }) => {
  return (
    <div>
      <div className={classNames(classes.root, classes.toolbar)}>
        <h1>D</h1>
      </div>
      <List className={classes.list}>
        <NavListItem onClose={onClose} />
      </List>
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Montserrat",
      "& h1": {
        fontSize: "32px",
        margin: 0,
      },
    },
    toolbar: mixins.toolbar,
    list: {
      padding: 0,
    },
  })

export default withStyles(styles)(NavList)
