import firstLetterUppercase from "./firstLetterUppercase"

const completeCalenderTitle = (work?: string, city?: string) => {
  switch (work) {
    case "överlåtelsebesiktning(s)":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "överlåtelsebesiktning(s) - bas":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "överlåtelsebesiktning(s) - utökad":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "överlåtelsebesiktning(s) - premium":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "överlåtelsebesiktning(k)":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "överlåtelsebesiktning(k) - bas":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "överlåtelsebesiktning(k) - utökad":
      return `ÖB - ${firstLetterUppercase(city)}`

    case "statusbesiktning":
      return `STATUS - ${firstLetterUppercase(city)}`

    case "energideklaration":
      return `ED - ${firstLetterUppercase(city)}`

    case "ovk":
      return `OVK - ${firstLetterUppercase(city)}`

    case "slutbesiktning":
      return `SLUT - ${firstLetterUppercase(city)}`

    case "garantibesiktning":
      return `G - ${firstLetterUppercase(city)}`

    case "lägenhetsbesiktning":
      return `LGH - ${firstLetterUppercase(city)}`

    case "köpargenomgång":
      return `KÖP - ${firstLetterUppercase(city)}`

    case "teknisk utredning":
      return `TU - ${firstLetterUppercase(city)}`

    case "radonmätning":
      return `RADON - ${firstLetterUppercase(city)}`

    case "underhållsplan":
      return `UHP - ${firstLetterUppercase(city)}`

    case "KA":
      return `KA - ${firstLetterUppercase(city)}`

    case "övrig":
      return `ÖVRIG - ${firstLetterUppercase(city)}`

    default:
      return firstLetterUppercase(city)
  }
}

export default completeCalenderTitle
