import React, { FC } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import workStore from "store/workStore"
import useStore from "global-hook-store"
import { history } from "../pages/Admin"
import { CircularProgress } from "@material-ui/core"

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    "& p, h2, span": {
      fontFamily: "Montserrat",
    },
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "450px",
    minHeight: "170px",
  },
  paper: {
    minWidth: "450px",
    minHeight: "170px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
  },
  cancelButton: {
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  deleteButton: {
    backgroundColor: "red",
    color: "white",
    padding: "6px 32px",
    textTransform: "capitalize",
    borderRadius: "2.5px",
    "&:hover": {
      backgroundColor: "red",
      opacity: 0.8,
    },
  },
})

type Props = { workId: string; open: boolean; handleClose: () => void }

const DeleteWorkDialog: FC<Props> = ({ workId, open, handleClose }) => {
  const classes = useStyles()
  const {
    state: {
      deleteWorkResponse: { loading, error },
    },
    actions,
  } = useStore(workStore)

  const deleteWorkById = async () => {
    await actions.deleteWork(workId)

    // IF ERROR - RETURN
    if (error) {
      // Set error messsage here if needed
      return
    }

    // Work deleted - Now user is redirected to jobs
    history.push("/jobs")
  }

  return (
    <div>
      <Dialog
        classes={{ root: classes.root, paper: classes.paper }}
        open={open}
        onClose={handleClose}
      >
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size="32px" />
          </div>
        ) : (
          <>
            <DialogTitle
              classes={{ root: classes.title }}
              id="alert-dialog-title"
            >
              Radera arbete
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              <DialogContentText id="alert-dialog-description">
                Är du säker på att du vill ta bort det här arbetet?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disableRipple
                classes={{ root: classes.cancelButton }}
                onClick={handleClose}
              >
                Avbryt
              </Button>
              <Button
                disableRipple
                classes={{ root: classes.deleteButton }}
                onClick={deleteWorkById}
              >
                Radera
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  )
}

export default DeleteWorkDialog
