import React, { useEffect } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"

import { Theme } from "@material-ui/core"
import NewCalendar from "../components/NewCalendar"
import workStore from "../store/workStore"
import useStore from "global-hook-store"
import AddBlockedDateModal from "components/AddBlockedDateModal"

type Props = {} & WithStyles<typeof styles>

const NewCalender: React.FC<Props> = ({ classes }) => {
  return (
    <div className={classes.container}>
      <NewCalendar />
      <AddBlockedDateModal />
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      backgroundColor: colors.white,
      minHeight: "700px",
      position: "relative",
    },
  })
export default withStyles(styles)(NewCalender)
