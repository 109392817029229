import React, { FC, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Popover from "@material-ui/core/Popover"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import "moment/locale/sv"
import DateFnsUtils from "@date-io/date-fns"

const useStyles = makeStyles({
  root: { width: "100%", marginTop: "15px" },
  openTimeButton: {
    height: "55px",
    border: "1px solid #E4E4E4",
    backgroundColor: "#f5f7f9",
    borderRadius: "2.5px",
    margin: "7.5px 0 10px 0",
    width: "50%",
    color: "#0000008a",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    paddingRight: "10px",
    textTransform: "capitalize",
  },
  label: {
    fontFamily: "Montserrat",
    margin: 0,
    color: "gray",
    fontSize: "14px",
  },
  popoverContentContainer: {
    display: "flex",
    padding: "10px",
    fontFamily: "Montserrat",
    fontSize: "16px",
  },
  popoverButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  // MATERIAL DATE PICKER STUFF
  datePicker: {
    width: "50%",
    marginTop: "7.5px",
  },
  inputPicker: {
    backgroundColor: "#F5F7F9",
    color: "rgba(0, 0, 0, 0.54)",
    fontFamily: "Montserrat",
  },
  outline: {
    border: "1px solid #E4E4E4",
    borderRadius: "2.5px",
  },
  input: {
    width: "100%",
    cursor: "pointer",
  },
})

moment.locale("sv")

type Props = {
  title: string
  id: string
  selectedDate: Date | null
  initialDate: Date
  changeDate: (date: Date | null) => void
}

const DateTimePicker: FC<Props> = ({
  title,
  selectedDate,
  initialDate,
  id,
  changeDate,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [hour, setHour] = useState<number>(
    selectedDate ? selectedDate.getHours() : 12
  )

  const [minute, setMinute] = useState<number>(
    selectedDate ? selectedDate.getMinutes() : 0
  )

  const open = Boolean(anchorEl)
  const buttonId = open ? id : undefined

  const timeFromDate = moment(selectedDate).format("LT")

  useEffect(() => {
    if (selectedDate) {
      setHour(selectedDate.getHours())
      setMinute(selectedDate.getMinutes())
    }
  }, [selectedDate])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    const date = selectedDate && selectedDate
    date?.setHours(hour, minute)
    changeDate(date)
  }

  const increaseHours = () => {
    const maxHour = 23
    if (hour < maxHour) {
      setHour(hour + 1)
    } else {
      setHour(0)
    }
  }

  const decreaseHours = () => {
    const minHour = 0
    if (hour > minHour) {
      setHour(hour - 1)
    } else {
      setHour(23)
    }
  }

  const increaseMinute = () => {
    const maxMinute = 45
    if (minute < maxMinute) {
      setMinute(minute + 15)
    } else {
      setMinute(0)
    }
  }

  const decreaseMinute = () => {
    const minMinute = 0
    if (minute > minMinute) {
      setMinute(minute - 15)
    } else {
      setMinute(45)
    }
  }

  const handleDateChange = (date: Date) => {
    const year: number = date?.getFullYear()
    const month: number = date?.getMonth()
    const day: number = date?.getDate()

    const changedDate = new Date(year, month, day, hour, minute)
    changeDate(changedDate)
  }

  return (
    <div className={classes.root}>
      <p className={classes.label}>{title}</p>
      <Button
        onClick={handleClick}
        aria-describedby={buttonId}
        className={classes.openTimeButton}
        disabled={!selectedDate}
      >
        {selectedDate ? timeFromDate : "Tid"}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverContentContainer}>
          <div className={classes.popoverButtonContainer}>
            <Button onClick={() => increaseHours()}>
              <ArrowForwardIosOutlinedIcon
                style={{
                  transform: "rotate(-90deg) ",
                }}
              />
            </Button>
            <div>{hour}</div>
            <Button onClick={() => decreaseHours()}>
              <ArrowForwardIosOutlinedIcon
                style={{
                  transform: "rotate(90deg) ",
                }}
              />
            </Button>
          </div>
          <div className={classes.popoverButtonContainer}>
            <Button onClick={() => increaseMinute()}>
              <ArrowForwardIosOutlinedIcon
                style={{
                  transform: "rotate(-90deg)",
                }}
              />
            </Button>
            <div>{minute}</div>
            <Button onClick={() => decreaseMinute()}>
              <ArrowForwardIosOutlinedIcon
                style={{
                  transform: "rotate(90deg) ",
                }}
              />
            </Button>
          </div>
        </div>
      </Popover>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          initialFocusedDate={initialDate}
          value={selectedDate}
          emptyLabel="Datum"
          onChange={(date: any) => handleDateChange(date)}
          className={classes.datePicker}
          style={{ paddingLeft: "10px" }}
          InputProps={{
            classes: {
              root: classes.inputPicker,
              input: classes.input,
              notchedOutline: classes.outline,
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DateTimePicker
