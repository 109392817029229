import { asyncState, createStore } from "global-hook-store"
import getCurrentUser from "../api/getCurrentUser"
import login from "../api/login"
import { history } from "../pages/Admin"
import InspectorStore from "store/InspectorStore"

type User = {
  email: string
}
type loggedIn = {
  role: string
  token: string
  user: User
}
const authStore = createStore(
  {
    currentUser: "",
    isLoggedIn: false,
    isLoading: true,
    isLoginLoading: false,
    isAdmin: true,
    email: "",
    password: "",
    role: "",
    id: "",
  },
  {
    setCurrentUser: async (state) => {
      try {
        const currentUser = await getCurrentUser()

        if (currentUser.role === "inspector") {
          InspectorStore.actions.setSortedInspector(currentUser.id)
        }

        return {
          ...state,
          isLoggedIn: true,
          isLoading: false,
          role: currentUser?.role,
          id: currentUser?.id,
        }
      } catch {
        history.push("/")
        return { ...state, isLoggedIn: false, isLoading: false }
      }
    },
    setEmail: (state, email: string) => ({ ...state, email }),
    login: async (state, { email, password }) => {
      try {
        const loginData = await login(email, password)

        if (loginData.role === "inspector") {
          InspectorStore.actions.setSortedInspector(loginData.user.id)
        }

        localStorage.setItem("token", loginData.token)
        history.push("/jobs")
        return {
          ...state,
          isLoggedIn: true,
          isLoginLoading: false,
          email: loginData?.user?.email,
          role: loginData?.role,
          id: loginData?.user?.id,
        }
      } catch {
        return { ...state, isLoggedIn: false, isLoginLoading: false }
      }
    },
    setPassword: (state, password: string) => ({ ...state, password }),
    setLoggedIn: (state, isLoggedIn: boolean) => ({
      ...state,
      isLoggedIn,
      isLoading: false,
      isLoginLoading: false,
    }),
    setLoginLoading: (state, isLoginLoading: boolean) => ({
      ...state,
      isLoginLoading,
    }),
    setLoading: (state, loading: boolean) => ({ ...state, isLoading: loading }),
  }
)

export default authStore
