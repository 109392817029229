import axios, { AxiosRequestConfig } from "axios"

const addBlockedDate = async (data: any, recurringEvent: string) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  if (recurringEvent === "none") {
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/user/blockedDate`,
      data,
      config
    )
  }

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/user/blockedDate?recurringInterval=${recurringEvent}`,
    data,
    config
  )
}

export default addBlockedDate
