import { createStore } from "global-hook-store"

export type ClientInfo = {
  clientName: string
  clientAddress: string
  clientCity: string
  clientZipCode: string
  clientPhone: string
  clientMail: string
  clientIdNumber: string
}

export type ObjectInfo = {
  assignmentNumber: string
  propertyDesignation: string
  objectAddress: string
  objectCity: string
  objectZipCode: string
  municipality: string
  propertyOwner: string
}

export type BuildingDescription = {
  buildingType: string
  yearOfConstruction: string
  yearOfReconstruction: string
  foundation: string
  framework: string
  facade: string
  window: string
  roof: string
  heating: string
  ventilation: string
}

export type Information = {
  weather: string
  temperature: string
  specialConditions: string
  oralInformation: string
  providedInformation: string
  acquisition: string
}

export type Section = {
  section: string
}

export type Floor = {
  floor: string
  section?: null | Section[]
}
// type Floor = { [key: string]: boolean }

// export type Floors = {
//   floor: Floor
//   //   section?: null | Section[]
// }

const PrePurchaseInspectionStore = createStore(
  {
    isAddFloor: false,
    isAddSection: false,
    isAddFloorOpen: false,
    isAddSectionOpen: false,
    client: {} as ClientInfo,
    object: {} as ObjectInfo,
    buildingDescription: {} as BuildingDescription,
    information: {} as Information,
    floors: [] as Floor[],
  },
  {
    // setPassword: (state, password: string) => ({ ...state, password }),
    setAddFloor: (state, addFloor: boolean) => ({
      ...state,
      isAddFloor: addFloor,
    }),
    setAddSection: (state, addSection: boolean) => ({
      ...state,
      isAddSection: addSection,
    }),
    setAddFloorOpen: (state, addFloorOpen: boolean) => ({
      ...state,
      isAddFloorOpen: addFloorOpen,
    }),
    setAddSectionOpen: (state, addSectionOpen: boolean) => ({
      ...state,
      isAddSectionOpen: addSectionOpen,
    }),
    setClientInfo: (state, payload: ClientInfo) => ({
      ...state,
      client: payload,
    }),
    setObjectInfo: (state, payload: ObjectInfo) => ({
      ...state,
      object: payload,
    }),
    setBuildingDescription: (state, payload: BuildingDescription) => ({
      ...state,
      buildingDescription: payload,
    }),
    setInformation: (state, payload: Information) => ({
      ...state,
      information: payload,
    }),
    // addFloor: (state, payload: Floors[]) => ({
    //   ...state,
    //   floors: payload
    // }),
    addFloor: (state, floor: string) => {
      const floorObject: Floor = { floor: floor }
      const floorArr: Floor[] = state.floors
      floorArr.push(floorObject)
      return { ...state, floors: floorArr }
    },
    addSection: (state, section: string) => {
      const floorObject: Floor = { floor: section }
      const floorArr: Floor[] = state.floors
      floorArr.push(floorObject)
      return { ...state, floors: floorArr }
    },
    // loading: (state, loading: boolean) => ({ ...state, isLoading: loading })
  }
)

export default PrePurchaseInspectionStore
