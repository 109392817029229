import { asyncState, createStore } from "global-hook-store"
import getCustomers from "../api/getCustomers"

type Customers = {
  clientAddress?: string
  clientCity?: string
  clientIdNumber?: string
  clientMail?: string
  clientName?: string
  clientPhone?: string
  clientZipCode?: string
  createdAt: Date
  updatedAt?: Date
  _id?: string
}

const CustomerRegistryStore = createStore(
  {
    customers: asyncState<Customers[]>([]),
  },
  {
    setCustomers: async (_, __: null, { asyncAction }) =>
      asyncAction("customers", getCustomers()),
  }
)

export default CustomerRegistryStore
