import React, { FC, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress } from "@material-ui/core"
import colors from "ui"
import CustomerRegistryStore from "store/CustomerRegistryStore"
import useStore from "global-hook-store"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import CustomerItem from "components/CustomerItem"

const useStyles = makeStyles({
  container: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "85vh",
    width: "100%",
    // boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
    color: colors.zodiac,
    // backgroundColor: colors.white,
  },
  customerContainer: {
    overflow: "auto",
    overflowAnchor: "none",
  },
  error: {
    display: "flex",
    width: "100%",
    height: "50vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",
    fontSize: "18px",
    fontFamily: "Montserrat",
    color: "red",
    "& p": {
      margin: 0,
      marginTop: "5px",
    },
  },
})

type Props = {}

const CustomerRegistry: FC<Props> = () => {
  const classes = useStyles()

  const {
    state: {
      customers: { data, loading, error },
    },
    actions: { setCustomers },
  } = useStore(CustomerRegistryStore)

  useEffect(() => {
    setCustomers()
  }, [])

  return (
    <div className={classes.container}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {!error ? (
            <div className={classes.customerContainer}>
              {data?.length === 0 && <p>Inga tillgängliga användare</p>}

              {data.map((customer) => (
                <CustomerItem
                  key={customer._id}
                  clientAddress={customer.clientAddress}
                  clientCity={customer.clientCity}
                  clientMail={customer.clientMail}
                  clientName={customer.clientName}
                  clientPhone={customer.clientPhone}
                  clientZipCode={customer.clientZipCode}
                />
              ))}
            </div>
          ) : (
            <div className={classes.error}>
              <ErrorOutlineOutlinedIcon style={{ fontSize: "28px" }} />
              <p>Error: Hämtning av användare misslyckades.</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CustomerRegistry
