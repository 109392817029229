import { asyncState, createStore } from "global-hook-store"
import addBlockedDate from "api/addBlockedDate"
import deleteBlockedDate from "api/deleteBlockedDate"
import updateBlockedDate from "api/updateBlockedDate"
import getBlockedDate from "api/getBlockedDate"

const blockedDateStore = createStore(
  {
    blockedDate: asyncState<any>(),
    createdBlockedDate: asyncState<any>(),
    updatedBlockedDate: asyncState<any>(),
    deletedBlockedDate: asyncState<any>(),
  },
  {
    setBlockedDate: async (_state, id, { asyncAction }) =>
      asyncAction("blockedDate", getBlockedDate(id)),
    createBlockedDate: async (
      _state,
      { data, recurringEvent },
      { asyncAction }
    ) =>
      asyncAction("createdBlockedDate", addBlockedDate(data, recurringEvent)),
    updateBlockedDate: async (_state, { id, data }, { asyncAction }) =>
      asyncAction("updatedBlockedDate", updateBlockedDate(id, data)),
    deleteBlockedDate: async (_state, id, { asyncAction }) =>
      asyncAction("deletedBlockedDate", deleteBlockedDate(id)),
    resetBlockedDate: (_, __: null, { reset }) => reset("blockedDate"),
  }
)

export default blockedDateStore
