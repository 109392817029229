import axios, { AxiosRequestConfig } from "axios"

const getWorks = async (
  status?: string,
  clientName?: string,
  objectAddress?: string,
  fromIndex?: number,
  inspector?: string
) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  // Authmiddleware for all calls. Should be in a standalone file but works fine here for now
  // If auth fails we reload page wich triggers the auth call and we remove token.
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 403) {
        localStorage.removeItem("token")
        window.location.reload()
      }
      return Promise.reject(error)
    }
  )
  const clientNamee = clientName ? clientName : ""

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }

  // If searching
  const ifSearch =
    (clientName && clientName.length > 0) ||
    (objectAddress && objectAddress?.length > 0)

  if (ifSearch) {
    // Om vi söker
    // Om fromIndex är true så hämtar vi mer jobb
    if (fromIndex) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/work?fromIndex=${fromIndex}&clientName=${clientNamee}&objectAddress=${objectAddress}&inspectorId=${inspector}`,
        config
      )
      return response.data
    } else {
      // Om from index inte existerar så hämtar vi bara clients
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/work?clientName=${clientNamee}&objectAddress=${objectAddress}&inspectorId=${inspector}`,
        config
      )
      return response.data
    }
  } else {
    // Om vi inte söker
    if (fromIndex) {
      // Ifall vi söker samt att fromIndex existerar
      // Här hämtar vi jobb utifrån status och fromIndex
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/work?status=${status}&&fromIndex=${fromIndex}&inspectorId=${inspector}`,
        config
      )
      return response.data
    } else {
      // Om inget fromIndex existerar hämtar vi bara jobb utifrån status
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/work?status=${status}&inspectorId=${inspector}`,
        config
      )
      return response.data
    }
  }
}

export default getWorks
