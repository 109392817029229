import axios, { AxiosRequestConfig } from "axios"

const getAvailableInspectors = async (
  fromDate: Date | null,
  toDate: Date | null
) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  if (fromDate && toDate) {
    const res = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/user/role/inspector?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`,
      config
    )
    return res.data
  }
  if (!fromDate && !toDate) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/user/role/inspector`,
      config
    )

    return res.data
  }
}

export default getAvailableInspectors
