import * as React from "react"
import {
  createStyles,
  withStyles,
  WithStyles,
  Hidden,
  useTheme,
  Theme,
  Button,
} from "@material-ui/core"
import { Drawer } from "@material-ui/core"
import NavList from "./NavList"
import useStore from "global-hook-store"
import navStore from "../../store/navStore"
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined"
type Props = {} & WithStyles<typeof styles>

const drawerWidth = 100

const Navigation: React.FC<Props> = ({ classes, children }) => {
  const theme = useTheme()

  /* NEED TO CREATE GLOBAL STORE FOR THIS */
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    state: { navOpen },
    actions: { setNavOpen },
  } = useStore(navStore)

  const handleDrawerToggle = () => {
    setNavOpen(!navOpen)
  }

  const logout = () => {
    localStorage.removeItem("token")
    window.location.reload()
  }

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={navOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavList onClose={handleDrawerToggle} />
          <Button onClick={logout} style={{ marginBottom: "15px" }}>
            <ExitToAppOutlinedIcon />
          </Button>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <NavList />
          <Button onClick={logout} style={{ marginBottom: "15px" }}>
            <ExitToAppOutlinedIcon />
          </Button>
        </Drawer>
      </Hidden>
    </nav>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    drawer: {
      [breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRight: "none",
      width: drawerWidth,
      [breakpoints.down("sm")]: {
        width: "300px",
        flexShrink: 0,
      },
    },
  })

export default withStyles(styles)(Navigation)
