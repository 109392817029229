import axios, { AxiosRequestConfig } from "axios"

type AdditionalService = {
  name?: string
  price?: number
}

type ContactPerson = {
  name?: string
  phone?: string
  email?: string
}

export type EmailData = {
  clientName?: string
  clientPhone?: string
  clientMail?: string
  inspection?: string
  additionalService?: AdditionalService[]
  objectAddress?: string
  clientAddress?: string
  dateOfInspection?: Date | null
  timeOfInspection?: string
  price?: number
  inspector?: string[]
  fileNames?: string[]
  otherInformation?: string
  inspectorFee?: number
  internalDocuments?: string[]
  objectCity?: string
  objectZipCode?: string
  clientCity?: string
  clientZipCode?: string
  propertyDesignation?: string
  totalPrice?: number
  contactPerson?: ContactPerson
  messageToCustomer?: string
}

const sendEmail = async (
  sendToInspector: boolean,
  sendToCustomer: boolean,
  data: EmailData,
  workId?: string
) => {
  const token = localStorage.getItem("token")
  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token
  }
  if (sendToInspector) {
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/email/${workId}?sendToInspector=${sendToInspector}`,
      data,
      config
    )
  }

  if (sendToCustomer) {
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/email/${workId}?sendToCustomer=${sendToCustomer}`,
      data,
      config
    )
  }
}

export default sendEmail
