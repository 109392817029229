import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../../ui"
import { history } from "../../pages/Admin"

import { Theme } from "@material-ui/core"

type User = {
  firstName: string
  lastName: string
  phone?: string
  email?: string
  city?: string
}
type Props = { id: string } & User & WithStyles<typeof styles>

const JobItem: React.FC<Props> = ({
  classes,
  id,
  firstName,
  lastName,
  phone,
  email,
  city,
}) => {
  return (
    <div
      onClick={() => history.push(`users/${id}`)}
      className={classes.itemContainer}
    >
      <div className={classes.item}>
        <div className={classes.left}>
          <p className={classes.highlight}>{firstName}</p>
          <p className={classes.highlight}>{lastName}</p>
          <p>{phone}</p>
          <p>{email}</p>
          <p>{city}</p>
        </div>
      </div>
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    itemContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "175px",
      backgroundColor: "transparent",
    },
    item: {
      cursor: "pointer",
      display: "flex",

      alignItems: "center",
      justifyContent: "space-between",
      // boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.1)",
      width: "99%",
      height: "150px",
      backgroundColor: colors.white,
      borderRadius: "5px",
      "& p": {
        margin: 0,
        fontSize: "16px",
      },
    },
    highlight: {
      fontSize: "16px",
      fontWeight: 500,
    },
    status: {
      width: "7.5px",
      height: "100%",
    },
    left: {
      padding: "15px 0 15px 15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "65%",
      height: "100%",
    },
    right: {
      padding: "15px 15px 15px 0",

      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "35%",
      height: "100%",
    },
  })
export default withStyles(styles)(JobItem)
