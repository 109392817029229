import React from "react"
import { withStyles } from "@material-ui/core/styles"

import { Redirect, Route } from "react-router-dom"
import authStore from "../store/authStore"
import useStore from "global-hook-store"

type Props = { roles: string[]; children: any }

const AllowedRole = ({ children, roles }: Props) => {
  const {
    state: { role },
  } = useStore(authStore)
  const isRoleIncluded = roles && roles.includes(role)

  // authorised so return component
  if (isRoleIncluded) {
    return children
  }

  return null
}

export default AllowedRole
