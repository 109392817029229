import React, { FC, useEffect, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import colors from "ui"
import DialogContent from "@material-ui/core/DialogContent"
import {
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Theme,
  Tooltip,
} from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import JobInput from "components/JobInput"
import DateTimePicker from "components/AddBlockedDate/DateTimePicker"
import UserStore from "store/UserStore"
import useStore from "global-hook-store"

import AllowedRole from "components/AllowedRole"
import authStore from "store/authStore"
import addBlockedDate from "api/addBlockedDate"
import blockedDateStore from "store/blockedDateStore"
import usePrevious from "utils/usePrevious"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up("md")]: {
        maxHeight: "700px",
        minHeight: "700px",
        height: "700px",
        padding: "8px 24px",
      },

      padding: "8px 12px",
      display: "flex",
      width: "100%",
      flex: 1,
      "&::-webkit-scrollbar": {
        width: 0,
        background: "transparent",
      },
    },
    form: {
      height: "100%",
      width: "100%",

      display: "flex",
      flexDirection: "column",
    },
    switchContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    switchLabel: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      color: colors.zodiac,
    },
    switchTrack: {
      backgroundColor: "lightgray",
    },
    switchBase: {
      color: "lightgray",

      "&.Mui-checked": {
        color: colors.picton,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: colors.picton,
        opacity: 0.5,
      },
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      flex: 1,
      alignItems: "flex-end",
      padding: "15px 0 10px",
    },
    submitButton: {
      backgroundColor: colors.picton,
      color: colors.white,
      textTransform: "none",
      fontFamily: "Montserrat",
      marginLeft: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      borderRadius: "2px",
    },
    cancelButton: {
      color: colors.picton,
      textTransform: "none",
      fontFamily: "Montserrat",
      padding: "6px 12px",
      fontSize: "14px",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#F5F7F9",
      margin: "7.5px 0",
      maxHeight: "56px",
    },
    select: {
      width: "100%",
      maxHeight: "56px",
      padding: "18.5px 14px",
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      color: "gray",
      fontSize: "14px",
    },
    sectionContainer: {
      marginTop: "20px",
      width: "100%",
    },
    errorMessage: {
      color: "red",
      fontFamily: "Montserrat",
      margin: 0,
    },
    button: {
      width: "100%",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
    selectMenu: { maxHeight: "300px" },
  })
)

type RequestState = { success: boolean; error: boolean; errorMessage: string }

type RecurringEvents = { name: string; value: string }

type Props = { dateFrom?: Date; close: () => void }

const AddBlockedDate: FC<Props> = ({ dateFrom, close }) => {
  const classes = useStyles()
  const { register, handleSubmit, errors, control } = useForm<any>()

  const {
    state: { users },
    actions: { setUsers },
  } = useStore(UserStore)

  const {
    state: { createdBlockedDate },
    actions: { createBlockedDate },
  } = useStore(blockedDateStore)

  const {
    state: { role, id },
  } = useStore(authStore)

  const [requestState, setRequestState] = useState<RequestState>({
    success: false,
    error: false,
    errorMessage: "",
  })

  const initialDate: any = new Date()

  // ---- START TIME OF INSPECTION
  const [selectedDate, setSelectedDate] = useState<null | Date>(null)

  // ---- END TIME OF INSPECTION
  const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null)

  // ---- END TIME OF INSPECTION
  const [recurringEvent, setRecurringEvent] = useState<string>("none")

  // ---- END TIME OF INSPECTION
  const [isFullDay, setIsFullDay] = useState<boolean>(false)

  useEffect(() => {
    // ----- DEFAULT HOURS LOGIC -----
    // Default hours to 12:00 when date is comming from calendar
    const dateDefaultHours = dateFrom && new Date(dateFrom.setHours(12, 0))

    // Set start date to dateDefaultHours
    dateDefaultHours && setSelectedDate(dateDefaultHours)

    // ----- PLUS ONE HOUR LOGIC -----
    // new Date() so that we dont manipulate "dateFrom"
    const datePlusOneHour = dateFrom && new Date(dateFrom)

    // Increase hour by one
    datePlusOneHour?.setHours(datePlusOneHour?.getHours() + 1)

    // Update endDate with datePlusOneHour
    datePlusOneHour && setSelectedEndDate(datePlusOneHour)

    // Get available users
    setUsers()
  }, [])

  const prevSelectedDate = usePrevious(selectedDate)
  const prevSelectedEndDate = usePrevious(selectedEndDate)

  useEffect(() => {
    const isDateEqual = prevSelectedDate === selectedDate
    const isEndDateEqual = prevSelectedEndDate === selectedEndDate
    const hasDateChanged = isDateEqual || isEndDateEqual

    if (isFullDay && hasDateChanged) {
      setIsFullDay(false)
    }
  }, [selectedDate, selectedEndDate])

  // Creates jobs and redirects to correct job
  const addBlockedDateAndRedirect = async (data: any) => {
    // @TODO: Skicka med recurring status här
    const res = await createBlockedDate({ data, recurringEvent })

    const createdRes = res.createdBlockedDate

    // IF ERROR - RETURN
    if (createdRes.error) {
      // Set error messsage here if needed
      return
    }

    // TODO - FINNS DET BÄTTRE SÄTT ATT LÖSA DETTA PÅ? -
    // history.push(`/jobs/${createdWorkResponse.data?._id}`)
    close()
  }

  // SUBMIT DATA
  const onSubmit = (data: any) => {
    if (selectedDate && selectedEndDate) {
      if (selectedEndDate > selectedDate) {
        const body = {
          inspectorId: role === "inspector" ? id : data.user,
          description: data.description,
          to: selectedEndDate,
          from: selectedDate,
        }

        addBlockedDateAndRedirect(body)
      } else {
        // SET ERROR STATE
        setRequestState({
          success: false,
          error: true,
          errorMessage: "Slutdatum måste vara senare än startdatum",
        })
      }
    } else {
      // SET ERROR STATE
      setRequestState({
        success: false,
        error: true,
        errorMessage: "Ange både start- och sluttid",
      })
    }
  }

  const handleRecurringEvent = (event: any) => {
    setRecurringEvent(event.target.value)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFullDay(event.target.checked)

    if (event.target.checked && selectedEndDate && selectedDate) {
      const startTime = selectedDate.setHours(0, 0)
      const endTime = selectedEndDate.setHours(23, 59)

      setSelectedDate(new Date(startTime))
      setSelectedEndDate(new Date(endTime))
    }
  }

  const recurringEvents: RecurringEvents[] = [
    {
      name: "Ej återkommande",
      value: "none",
    },
    {
      name: "Vecka",
      value: "week",
    },
    // {
    //   name: "Månad",
    //   value: "month",
    // },
  ]

  return (
    <>
      <DialogContent classes={{ root: classes.content }}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <AllowedRole roles={["admin", "sales"]}>
            <div className={classes.sectionContainer}>
              <p className={classes.label}>Användare</p>
              <FormControl
                className={classes.formControl}
                error={errors.inspector && true}
              >
                <Controller
                  name="user"
                  control={control}
                  defaultValue={[]}
                  rules={{ required: true }}
                  render={({ onChange, value, name }) => (
                    <Select
                      disabled={createdBlockedDate.loading}
                      name={name}
                      disableUnderline
                      multiple
                      value={value}
                      classes={{
                        root: classes.select,
                      }}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      input={<Input />}
                      renderValue={(selected) => {
                        var findCorrectInspectors = (selected as any[]).map(
                          (value) => {
                            let inspector = users?.data.find(
                              (x) => x._id === value
                            )
                            return `${inspector?.firstName} ${inspector?.lastName}`
                          }
                        )
                        return (findCorrectInspectors as string[]).join(", ")
                      }}
                    >
                      {users &&
                        users?.data?.map((inspector: any) => {
                          return (
                            <MenuItem
                              key={inspector._id}
                              value={inspector._id}
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ margin: 0 }}>
                                  {`${inspector.firstName} ${inspector.lastName}`}
                                </p>
                              </div>
                            </MenuItem>
                          )
                        })}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          </AllowedRole>

          <JobInput
            name="description"
            label="Notering"
            register={register()}
            multiline
          />
          <DateTimePicker
            title="Startdatum"
            id="start-date"
            initialDate={initialDate}
            selectedDate={selectedDate}
            changeDate={setSelectedDate}
          />

          <DateTimePicker
            title="Slutdatum"
            id="end-date"
            initialDate={selectedDate ? selectedDate : initialDate}
            selectedDate={selectedEndDate}
            changeDate={setSelectedEndDate}
          />
          {requestState.error && (
            <p className={classes.errorMessage}>{requestState.errorMessage}</p>
          )}

          <div className={classes.sectionContainer}>
            <p className={classes.label}>Återkommande</p>
            <FormControl className={classes.formControl}>
              <Select
                disableUnderline
                value={recurringEvent}
                classes={{
                  root: classes.select,
                }}
                MenuProps={{ classes: { paper: classes.selectMenu } }}
                onChange={handleRecurringEvent}
                defaultValue=""
              >
                {recurringEvents?.map((event) => (
                  <MenuItem
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                    }}
                    key={event.name}
                    value={event.value}
                  >
                    {event.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.switchContainer}>
            <FormControlLabel
              classes={{ label: classes.switchLabel }}
              control={
                <Switch
                  color="primary"
                  name="allDay"
                  checked={isFullDay}
                  onChange={handleSwitchChange}
                />
              }
              label="Heldag"
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={close}
              style={{
                marginRight: "10px",
                backgroundColor: colors.white,
                border: `1px solid ${colors.picton}`,
                color: colors.picton,
              }}
              className={classes.button}
            >
              Avbryt
            </Button>
            <Button
              type="submit"
              disabled={createdBlockedDate.loading}
              className={classes.button}
              classes={{ disabled: classes.addDisabled }}
              key={`${createdBlockedDate.loading}`}
            >
              {createdBlockedDate.loading ? (
                <CircularProgress size="25px" style={{ color: "white" }} />
              ) : (
                "Lägg till"
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </>
  )
}

export default AddBlockedDate
