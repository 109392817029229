import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"

import { Theme } from "@material-ui/core"
import StatChart from "components/StatChart"

type Props = {} & WithStyles<typeof styles>

const Statistics: React.FC<Props> = ({ classes }) => {
  return (
    <div className={classes.container}>
      <StatChart />
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      width: "100%",
    },
  })
export default withStyles(styles)(Statistics)
