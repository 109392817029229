import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { history } from "../pages/Admin"
import DateFnsUtils from "@date-io/date-fns"

import {
  Theme,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Popover,
} from "@material-ui/core"
import JobInput from "./JobInput"
import { Controller, useForm } from "react-hook-form"
import useStore from "global-hook-store"
import Services from "../data/Services"

import InspectorStore from "../store/InspectorStore"
import addWork from "../api/addWork"
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers"
import AllowedRole from "./AllowedRole"
import colors from "../ui"
import navStore from "../store/navStore"
import authStore from "../store/authStore"

import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import moment from "moment"
import ErrorDisplay from "components/ErrorDisplay"
import AddBlockedDate from "components/AddBlockedDate"

type Props = {} & WithStyles<typeof styles>

const InspectorModal: React.FC<Props> = ({ classes }) => {
  const {
    state: { addBlockedDateOpen },
    actions: { setAddBlockedDateOpen },
  } = useStore(navStore)

  const {
    state: { id },
    actions: {},
  } = useStore(authStore)

  const theme = useTheme()

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const handleClose = () => {
    setAddBlockedDateOpen(false)
  }

  return (
    <Dialog
      maxWidth={"md"}
      fullScreen={fullScreen}
      open={addBlockedDateOpen}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      {id ? (
        <AddBlockedDate userId={id} fullWidth />
      ) : (
        <div className={classes.loading}>
          <CircularProgress size="25px" />
        </div>
      )}
    </Dialog>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    dialog: {
      fontFamily: "montserrat",
      width: "700px",
      position: "relative",
    },
    loading: {
      width: "700px",
      height: "300px",
    },
  })

export default withStyles(styles)(InspectorModal)
