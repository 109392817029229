import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { Theme, Popover, Button } from "@material-ui/core"
import { history } from "../pages/Admin"
import colors from "../ui"
import authStore from "store/authStore"
import AllowedRole from "./AllowedRole"

type Props = { data: any; openBlockedDate: (id: string) => void } & WithStyles<
  typeof styles
>

const CalendarPopover: React.FC<Props> = ({
  classes,
  children,
  data,
  openBlockedDate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const userId = authStore.state.id
  return (
    <>
      <div aria-describedby={id} onClick={handleClick}>
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.text}>
          {data.type === "work" && (
            <>
              <p>{data?.clientName}</p>
              <p>{data?.objectAddress}</p>
              <p>{data?.objectCity}</p>
              <p>{data?.price}</p>
              <p>{data?.status}</p>
              <p>{data?.inspection}</p>
            </>
          )}

          {data.type === "blocked" && (
            <>
              <p style={{ fontWeight: 600 }}>Besiktningsman</p>
              {data?.inspector?.map((x: any) => (
                <p
                  style={{ marginBottom: "2.5px" }}
                >{`${x?.firstName} ${x?.lastName}`}</p>
              ))}

              <p style={{ fontWeight: 600 }}>Notering</p>
              <p style={{ marginBottom: "15px" }}>{data?.description}</p>
            </>
          )}

          <p style={{ fontWeight: 600, marginTop: "10px" }}>Startdatum</p>

          <p>
            {data.type === "blocked" && data?.blockedFrom}
            {data.type === "work" && data?.timeOfInspection}
          </p>
          <p style={{ fontWeight: 600 }}>Slutdatum</p>

          <p>
            {data.type === "blocked" && data?.blockedTo}
            {data.type === "work" && data?.endTimeOfInspection}
          </p>
          {data.type === "work" && (
            <Button
              className={classes.button}
              onClick={() => history.push(`jobs/${data?.id}`)}
            >
              Gå till arbete
            </Button>
          )}
          {/* Sloppy solution */}
          <AllowedRole roles={["admin", "sales"]}>
            {data.type === "blocked" && (
              <Button
                style={{ backgroundColor: "orange" }}
                className={classes.button}
                onClick={() => openBlockedDate(data?.id)}
              >
                Redigera
              </Button>
            )}
          </AllowedRole>

          <AllowedRole roles={["inspector"]}>
            {data.type === "blocked" && userId === data?.inspector?._id && (
              <Button
                style={{ backgroundColor: "orange" }}
                className={classes.button}
                onClick={() => openBlockedDate(data?.id)}
              >
                Redigera
              </Button>
            )}
          </AllowedRole>
        </div>
      </Popover>
    </>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    text: {
      fontFamily: "montserrat",
      width: "300px",
      padding: "15px",
      textTransform: "capitalize",
      "& p": {
        marginTop: "2.5px",
        margin: 0,
        fontSize: "14px",
      },
    },
    button: {
      fontSize: "12px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
      marginTop: "10px",
      borderRadius: "2.5px",
      width: "100%",
    },
  })

export default withStyles(styles)(CalendarPopover)
