import React, { useEffect, useState } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"

import { Button, CircularProgress, Grid, Theme } from "@material-ui/core"
import { useForm } from "react-hook-form"
import FormSection from "../components/FormSection"
import JobInput from "../components/JobInput"
import { useParams } from "react-router-dom"
import useStore from "global-hook-store"

import UserStore from "../store/UserStore"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import translateRole from "../utils/translateRole"
import ErrorDisplay from "components/ErrorDisplay"
import AddBlockedDate from "components/AddBlockedDate"
import Divider from "components/Divider"

type Inspector = {
  _id: string
  firstName: string
  lastName: string
  phone: string
  email: string
  city: string
  role: string
  extraInformation: string
}

type BlockedDates = {
  from: Date
  to: Date
  description: string
}

type ParamTypes = {
  user: string
}
type Props = {} & WithStyles<typeof styles>

const User: React.FC<Props> = ({ classes }) => {
  const { register, handleSubmit, errors } = useForm<Inspector>()

  const {
    state: { user, isRequestLoading, isDeleteRequestLoading, userError },
    actions: {
      setUser,
      updateUser,
      deleteUser,
      setUserError,
      setDeleteRequestLoading,
      setRequestLoading,
    },
  } = useStore(UserStore)
  const [edit, setEdit] = React.useState(false)

  let { user: userId } = useParams<ParamTypes>()

  useEffect(() => {
    setUser(userId)
  }, [])

  useEffect(() => {
    if (userError) {
      setTimeout(() => {
        setUserError(false)
      }, 5000)
    }
  }, [userError])

  const deleteUserById = () => {
    setDeleteRequestLoading(true)
    deleteUser(userId)
  }

  const onSubmit = (data: Inspector) => {
    setRequestLoading(true)
    updateUser({ id: userId, user: data })
  }

  return (
    <>
      {user?.loading ? (
        <div
          style={{
            width: "100%",
            height: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {!user.error ? (
            <div style={{ backgroundColor: "white" }}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.container}
              >
                {userError && <ErrorDisplay />}
                <Grid container>
                  <FormSection paddingTop title="Användare">
                    <p className={classes.role}>
                      {translateRole(user?.data?.role)}
                    </p>
                    <JobInput
                      capitalize={true}
                      name="firstName"
                      label="Förnamn"
                      error={errors.firstName && true}
                      register={register({
                        required: "REQUIRED",
                        minLength: 1,
                      })}
                      disabled={!edit}
                      defaultValue={user?.data?.firstName}
                    />
                    <JobInput
                      capitalize={true}
                      name="lastName"
                      label="Efternamn"
                      error={errors.lastName && true}
                      register={register({
                        required: "REQUIRED",
                        minLength: 1,
                      })}
                      disabled={!edit}
                      defaultValue={user?.data?.lastName}
                    />

                    <JobInput
                      capitalize={true}
                      name="city"
                      label="Ort"
                      error={errors.city && true}
                      register={register()}
                      disabled={!edit}
                      defaultValue={user?.data?.city}
                    />
                  </FormSection>
                  <FormSection paddingTop={true} title="Uppgifter">
                    <JobInput
                      name="phone"
                      label="Telefon"
                      error={errors.phone && true}
                      register={register()}
                      disabled={!edit}
                      defaultValue={user?.data?.phone}
                    />
                    <JobInput
                      name="email"
                      label="Mail"
                      error={errors.email && true}
                      register={register({
                        required: "REQUIRED",
                        minLength: 1,
                      })}
                      disabled={!edit}
                      defaultValue={user?.data?.email}
                    />
                    <JobInput
                      name="extraInformation"
                      label="Övrig information"
                      error={errors.extraInformation && true}
                      register={register()}
                      multiline
                      disabled={!edit}
                      defaultValue={user?.data?.extraInformation}
                    />
                  </FormSection>

                  <Grid
                    className={classes.buttonContainer}
                    xs={12}
                    container
                    item
                  >
                    {edit ? (
                      <div className={classes.editModeButtonsContainer}>
                        <Button
                          onClick={() => deleteUserById()}
                          disabled={isDeleteRequestLoading}
                          className={classes.button}
                          classes={{ disabled: classes.addDisabled }}
                          style={{
                            marginRight: "10px",
                            backgroundColor: isDeleteRequestLoading
                              ? "lightgray"
                              : "red",
                            color: colors.white,
                          }}
                        >
                          {isDeleteRequestLoading ? (
                            <CircularProgress
                              size="25px"
                              style={{ color: "white" }}
                            />
                          ) : (
                            "Radera"
                          )}
                        </Button>
                        <div className={classes.abortSaveContainer}>
                          <Button
                            onClick={() => setEdit(false)}
                            style={{
                              marginRight: "10px",
                              backgroundColor: colors.white,
                              border: `1px solid ${colors.picton}`,
                              color: colors.picton,
                            }}
                            className={classes.button}
                          >
                            Avbryt
                          </Button>
                          <Button
                            type="submit"
                            disabled={isRequestLoading}
                            className={classes.button}
                            style={{
                              backgroundColor: isRequestLoading
                                ? "lightgray"
                                : colors.picton,
                            }}
                            classes={{ disabled: classes.addDisabled }}
                          >
                            {isRequestLoading ? (
                              <CircularProgress
                                size="25px"
                                style={{ color: "white" }}
                              />
                            ) : (
                              "Spara"
                            )}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        onClick={() => setEdit(true)}
                        className={classes.button}
                      >
                        Redigera
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
              {/* @TODO: DON'T THINK THIS IS USED MUCH, REMOVING */}
              {/* <Divider />
              <AddBlockedDate userId={userId} /> */}
            </div>
          ) : (
            <div className={classes.error}>
              <ErrorOutlineOutlinedIcon style={{ fontSize: "28px" }} />
              <p>Error: Hämtning av användaren misslyckades.</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "30px 5px",
      color: colors.zodiac,
      backgroundColor: colors.white,
      [breakpoints.down("sm")]: {
        paddingTop: "0px",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "100px 25px 0 25px",
    },
    button: {
      width: "200px",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
    },
    error: {
      display: "flex",
      width: "100%",
      height: "50vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
      fontSize: "18px",
      fontFamily: "Montserrat",
      color: "red",
      "& p": {
        margin: 0,
        marginTop: "5px",
      },
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
    editModeButtonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      [breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    abortSaveContainer: {
      [breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column-reverse",
        marginBottom: "35px",
      },
    },
    role: {
      position: "absolute",
      top: "5px",
      right: "25px",
      textTransform: "capitalize",
      margin: 0,
      [breakpoints.down("sm")]: {
        top: "35px",
        right: "25px",
      },
    },
  })
export default withStyles(styles)(User)
