import { asyncState, createStore } from "global-hook-store"
import getStatistics from "api/getStatistics"

type Statistics = {
  x: string
  y: number
}

const statisticsStore = createStore(
  {
    statistics: asyncState<Statistics[]>([]),
  },
  {
    setStatistics: async (state, payload, { asyncAction }) =>
      asyncAction(
        "statistics",
        getStatistics(
          payload.dateInterval,
          payload.date.toISOString(),
          payload.user,
          payload.status
        )
      ),
  }
)

export default statisticsStore
