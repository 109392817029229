import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    height: "35px",
    width: "100%",
    backgroundColor: "red",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1200,
    fontFamily: "Montserrat",
    color: "white",
    fontSize: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
  },
})

const ErrorDisplay = () => {
  const classes = useStyles()

  return <div className={classes.root}>Error: Något gick fel. Försök igen.</div>
}

export default ErrorDisplay
