const colors = {
  white: "#fff",
  picton: "#40A8E2",
  azure: "#274595",
  zodiac: "#0E1D44",
  alice: "#F5F7F9",
  error: "#C3000A",
  success: "#4BB543",
}

export default colors
