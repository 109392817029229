import { useRef, useEffect } from "react"

// Keeps the previous value of the passed parameter
// Useful for keeping previous state to compare to the new state
const usePrevious = (value: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export default usePrevious
