import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import colors from '../ui';

import { Theme } from '@material-ui/core';

type Props = {} & WithStyles<typeof styles>;

const NotFound: React.FC<Props> = ({ classes }) => {
    return <div className={classes.container}>NOT FOUND</div>;
};

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '42px',
            color: colors.zodiac,
            backgroundColor: colors.white,
            minHeight: '700px'
        }
    });
export default withStyles(styles)(NotFound);
