import React, { FC, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import colors from "ui"
import Chart from "react-apexcharts"

import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import moment from "moment"
import useStore from "global-hook-store"
import statisticsStore from "store/statisticsStore"
import UserStore from "store/UserStore"
import JobItem from "components/JobItem"

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: "5px",
  },
  // Kolla genom orders
  chartContainer: {
    // order: 1,
    // [theme.breakpoints.down("sm")]: {
    //   order: 2,
    // },
  },
  totalsContainer: {
    // order: 2,
    // [theme.breakpoints.down("sm")]: {
    //   order: 1,
    // },
  },
  chart: {
    width: "100%",
    backgroundColor: colors.white,
  },
  total: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.white,
    padding: "16px 16px 50px",
  },

  headingTotal: {
    "& h2": {
      margin: 0,
      padding: 0,
      fontWeight: 500,
    },

    "& p": {
      opacity: 0.75,
      margin: 0,
      textTransform: "capitalize",
    },
  },
  totalSum: {
    width: "100%",
    fontSize: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    "& h4": {
      fontSize: "60px",
      fontWeight: 500,
      margin: 0,
    },
  },
  header: {
    width: "100%",
    padding: "16px 16px 0px",
  },
  textContainer: {
    // display: "flex",
    // flexDirection: "column",
    "& h2": {
      margin: 0,
      padding: 0,
      fontSize: "16px",
      fontWeight: 500,
    },

    // "& p": {
    //   fontSize: "14px",
    //   opacity: 0.75,
    //   margin: 0,
    //   textTransform: "capitalize",
    // },
  },
  iconButton: { fontSize: "16px" },
  todayButton: {
    fontSize: "16px",
    textTransform: "capitalize",
    fontFamily: "Montserrat",
  },
  select: {
    width: "100px",
    padding: "8px",
    fontSize: "14px",
    fontWeight: 400,
    color: colors.zodiac,
    border: `1.5px solid #0e1d4478`,
    borderRadius: "5px",
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    "&:focus": {
      backgroundColor: colors.white,
      borderRadius: "5px",
    },
  },
  menuItem: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textTransform: "capitalize",
  },
}))

type Props = {}

const StatChart: FC<Props> = ({}) => {
  const classes = useStyles()
  const MenuProps = {
    PaperProps: {
      style: {
        boxShadow: "0px 4px 8px 0 rgb(0 0 0 / 8%)",
        borderRadius: 0,
        borderTop: "1px solid lightgray",
        padding: 0,
      },
    },
    MenuListProps: {
      style: {
        padding: 0,
      },
    },
  }
  const {
    state: { statistics },
    actions: { setStatistics },
  } = useStore(statisticsStore)

  const {
    state: { users },
    actions: { setUsers },
  } = useStore(UserStore)

  const getStatsByCategory = (category: string) =>
    statistics?.data?.find((x: any) => x?.name === category)

  const profit: any = statistics && getStatsByCategory("profit")
  const profitData = profit?.data || []

  const revenue: any = statistics && getStatsByCategory("revenue")
  const revenueData = revenue?.data

  const bookings: any = statistics && getStatsByCategory("numberOfWork")
  const bookingsData = bookings?.data || []

  const works: any = statistics && getStatsByCategory("listOfWork")
  const worksData = works?.data || []
  // This can be done better - Holds options and series for chart
  const [state, setState] = useState({
    options: {
      stroke: { curve: "smooth" as "smooth", width: 3 },
      chart: {
        id: "apexchart-example",
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter: function (val: any) {
            return new Date(val).toLocaleString("sv-SE", {
              month: "short",
              day: "2-digit",
            })
          },
        },
      },
    },
    series: [
      {
        name: "Bokningar",
        data: bookingsData,
        type: "category",
      },
    ],
  })

  // This can be done better - Holds options and series for chart
  const [columnState, setColumnState] = useState({
    options: {
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter: function (val: any) {
            return new Date(val).toLocaleString("sv-SE", {
              month: "short",
              day: "2-digit",
            })
          },
        },
      },
      chart: {
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          // columnWidth: "55%",
          // endingShape: "rounded",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Omsättning",
        data: revenueData,
      },
      {
        name: "Vinst",
        data: profitData,
      },
    ],
  })

  const [
    dateInterval,
    setDateInterval,
  ] = useState<moment.unitOfTime.DurationConstructor>("week")

  const [date, setDate] = useState<Date>(new Date())
  const [user, setUser] = useState<string[]>([])
  const [status, setStatus] = useState<string>("")

  // Get stats on mount
  useEffect(() => {
    const currentDate = new Date()
    setStatistics({ dateInterval, date: currentDate, user, status })
    setUsers()
  }, [])

  // This can be done much better - Sets new series and options when data from API changes
  useEffect(() => {
    if (dateInterval === "week") {
      setState({
        ...state,
        options: {
          ...state.options,
          xaxis: {
            tickAmount: 10,
            labels: {
              formatter: function (val: any) {
                return new Date(val).toLocaleString("sv-SE", {
                  month: "short",
                  day: "2-digit",
                })
              },
            },
          },
        },
        series: [
          {
            name: "Bokningar",
            data: bookingsData,
            type: "category",
          },
        ],
      })

      setColumnState({
        ...columnState,
        series: [
          {
            name: "Omsättning",
            data: revenueData,
          },
          {
            name: "Vinst",
            data: profitData,
          },
        ],
      })

      // SET COLUMN STATE FOR WEEK HERE
    } else if (dateInterval === "month") {
      setState({
        ...state,
        options: {
          ...state.options,
          xaxis: {
            tickAmount: 10,
            labels: {
              formatter: function (val: any) {
                return new Date(val).toLocaleString("sv-SE", {
                  month: "short",
                  day: "2-digit",
                })
              },
            },
          },
        },
        series: [
          {
            name: "Bokningar",
            data: bookingsData,
            type: "category",
          },
        ],
      })

      setColumnState({
        ...columnState,
        series: [
          {
            name: "Omsättning",
            data: revenueData,
          },
          {
            name: "Vinst",
            data: profitData,
          },
        ],
      })

      // SET COLUMN STATE FOR MONTH HERE
    } else if (dateInterval === "year") {
      setState({
        ...state,
        options: {
          ...state.options,
          xaxis: {
            tickAmount: 10,
            labels: {
              formatter: function (val: any) {
                return new Date(val).toLocaleString("sv-SE", {
                  month: "short",
                })
              },
            },
          },
        },
        series: [
          {
            name: "Bokningar",
            data: bookingsData,
            type: "category",
          },
        ],
      })
    }

    setColumnState({
      ...columnState,
      series: [
        {
          name: "Omsättning",
          data: revenueData,
        },
        {
          name: "Vinst",
          data: profitData,
        },
      ],
    })

    // SET COLUMN STATE FOR YEAR HERE
  }, [statistics.data])

  // Go to previous
  const prev = () => {
    const updatedDate = new Date(
      moment(date).subtract(1, dateInterval).toDate()
    )

    setStatistics({ dateInterval, date: updatedDate, user, status })

    setDate(updatedDate)
  }

  // Go to current date
  const today = () => {
    const currentDate = new Date()
    // getStatistics(dateInterval, currentDate)
    setStatistics({ dateInterval, date: currentDate, user, status })

    setDate(currentDate)
  }

  // Go to next
  const next = () => {
    const updatedDate = new Date(moment(date).add(1, dateInterval).toDate())

    setStatistics({ dateInterval, date: updatedDate, user, status })
    setDate(updatedDate)
  }

  // Change date interval - week/month/year
  const handleChangeDateInterval = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as moment.unitOfTime.DurationConstructor

    setDateInterval(value)

    setStatistics({ dateInterval: value, date, user, status })
  }

  // Change date interval - week/month/year
  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string

    setStatus(value)

    setStatistics({ dateInterval, date, user, status: value })
  }

  // Change inspector
  const handleChangeInspectors = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string[]

    setUser(value)

    setStatistics({ dateInterval, date, user: value, status })
  }

  // Display the current dateInterval with date
  const currentString = () => {
    switch (dateInterval) {
      case "week":
        return `Vecka ${moment(date).week()}`
      case "month":
        return moment(date).format("MMMM")
      case "year":
        return moment(date).year()

      default:
        return dateInterval
    }
  }

  // Get total sum from statistic data
  const totalSum =
    bookingsData?.reduce((accum: any, item: any) => accum + item.y, 0) || 0

  const profitSum =
    profitData?.reduce((accum: any, item: any) => accum + item.y, 0) || 0

  const revenueSum =
    revenueData?.reduce((accum: any, item: any) => accum + item.y, 0) || 0

  let formatter = new Intl.NumberFormat("se-SE", {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
  })

  // Functions to make JobItems work
  const datess = (k: Date) => {
    let hej = new Date(k)
    return hej.toLocaleDateString("sv-SE")
  }

  const calcAdditionalPrice = (nein: any) => {
    let sum = 0

    nein?.length > 0 &&
      nein.map((x: any) => {
        sum = sum + x.price
      })

    return sum
  }

  // The Grids can be done much better below - Prob onlt need one container and two items
  return (
    <Grid spacing={2} container className={classes.container}>
      <Grid className={classes.chartContainer} container item xs={12} md={8}>
        <div className={classes.chart}>
          {/* START -- LINE CHART */}

          <Grid
            container
            item
            direction="row"
            justify="center"
            // alignItems="center"
            className={classes.header}
          >
            <Grid container item xs={4} className={classes.textContainer}>
              <h2>Bokningar</h2>
            </Grid>
            <Grid container item justify="center" xs={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <Button disabled={statistics.loading} onClick={prev}>
                    <ArrowBackIosOutlinedIcon className={classes.iconButton} />
                  </Button>
                  <Button
                    disabled={statistics.loading}
                    onClick={today}
                    className={classes.todayButton}
                  >
                    Idag
                  </Button>
                  <Button disabled={statistics.loading} onClick={next}>
                    <ArrowForwardIosOutlinedIcon
                      className={classes.iconButton}
                    />
                  </Button>
                </div>
                <p
                  style={{
                    fontSize: "14px",
                    opacity: 0.75,
                    margin: 0,
                    textTransform: "capitalize",
                  }}
                >
                  {currentString()}
                </p>
              </div>
            </Grid>
            <Grid
              container
              item
              alignItems="flex-start"
              justify="flex-end"
              xs={4}
            >
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                classes={{ root: classes.select }}
                value={user}
                MenuProps={MenuProps}
                onChange={handleChangeInspectors}
                disabled={users.loading}
                style={{ marginRight: "10px" }}
                multiple
                displayEmpty
                disableUnderline
              >
                {users?.data?.map((user: any) => (
                  <MenuItem
                    key={user?._id}
                    value={user._id}
                    className={classes.menuItem}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </MenuItem>
                ))}
              </Select>
              <Select
                displayEmpty
                disableUnderline
                classes={{ root: classes.select }}
                value={status}
                MenuProps={MenuProps}
                onChange={handleChangeStatus}
                disabled={statistics.loading}
                style={{ marginRight: "10px" }}
              >
                <MenuItem value="" className={classes.menuItem}>
                  Status
                </MenuItem>
                <MenuItem value="booked" className={classes.menuItem}>
                  Bokad
                </MenuItem>
                <MenuItem value="ongoing" className={classes.menuItem}>
                  Pågående
                </MenuItem>
                <MenuItem value="sent" className={classes.menuItem}>
                  Skickad
                </MenuItem>
                <MenuItem value="completed" className={classes.menuItem}>
                  Klar
                </MenuItem>
                <MenuItem value="unprocessed" className={classes.menuItem}>
                  Obehandlad
                </MenuItem>
              </Select>
              <Select
                displayEmpty
                disableUnderline
                classes={{ root: classes.select }}
                value={dateInterval}
                MenuProps={MenuProps}
                onChange={handleChangeDateInterval}
                disabled={statistics.loading}
              >
                <MenuItem value="week" className={classes.menuItem}>
                  Vecka
                </MenuItem>
                <MenuItem value="month" className={classes.menuItem}>
                  Månad
                </MenuItem>
                <MenuItem value="year" className={classes.menuItem}>
                  År
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid style={{ position: "relative" }} item xs={12}>
            {statistics.loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  opacity: 0.5,
                  zIndex: 999,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size="35px" />
              </div>
            )}
            <Chart
              options={state.options}
              series={state.series}
              type="line"
              height="300px"
            />
          </Grid>
        </div>
      </Grid>
      <Grid className={classes.totalsContainer} container item xs={12} md={4}>
        <div className={classes.total}>
          <div className={classes.headingTotal}>
            <h2>Antal bokningar</h2>
          </div>
          <div className={classes.totalSum}>
            <h4>{totalSum}</h4>
          </div>
        </div>
      </Grid>
      {/* END -- LINE CHART */}
      {/* START -- COLUMN CHART */}
      <Grid
        style={{ position: "relative" }}
        className={classes.totalsContainer}
        container
        item
        xs={12}
        md={8}
      >
        <div style={{ width: "100%", background: "white" }}>
          {statistics.loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                opacity: 0.5,
                zIndex: 999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size="35px" />
            </div>
          )}

          <h2
            style={{
              margin: 0,
              padding: "16px",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Ekonomi
          </h2>
          <Chart
            options={columnState.options}
            series={columnState.series}
            type="bar"
            height="450px"
          />
        </div>
      </Grid>
      <Grid className={classes.chartContainer} container item xs={12} md={4}>
        <div style={{ marginBottom: "8px" }} className={classes.total}>
          <div className={classes.headingTotal}>
            <h2>Total vinst</h2>
          </div>
          <div className={classes.totalSum}>
            <h4 style={{ fontSize: "48px", fontWeight: 500, margin: 0 }}>
              {formatter.format(profitSum)}
            </h4>
          </div>
        </div>
        <div style={{ marginTop: "8px" }} className={classes.total}>
          <div className={classes.headingTotal}>
            <h2>Total omsättning</h2>
          </div>
          <div className={classes.totalSum}>
            <h4 style={{ fontSize: "48px", fontWeight: 500, margin: 0 }}>
              {formatter.format(revenueSum)}
            </h4>
          </div>
        </div>
      </Grid>
      {dateInterval === "week" && (
        <div
          style={{
            width: "100%",

            maxHeight: "750px",
            overflow: "auto",
          }}
        >
          {worksData?.length > 0 &&
            worksData.map((work: any, index: number) => (
              <JobItem
                key={index}
                id={work?._id}
                client={work.clientName}
                address={work.objectAddress}
                city={work.objectCity}
                status={work.status}
                inspection={work.inspection}
                timeOfInspection={
                  work?.dateOfInspection &&
                  moment(work.dateOfInspection).format("LT")
                }
                dateOfInspection={
                  work?.dateOfInspection && datess(work.dateOfInspection)
                }
                price={work?.price}
                additionalServicePrice={calcAdditionalPrice(
                  work?.additionalService
                )}
              />
            ))}
        </div>
      )}
      {/* END -- COLUMN CHART */}
    </Grid>
  )
}

export default StatChart
