import { asyncState, createStore } from "global-hook-store"
import { WorkData } from "../api/addWork"
import getInspector from "../api/getUser"
import getInspectors from "../api/getInspectors"
import getAvailableInspectors from "../api/getAvailableInspectors"

import getWork from "../api/getWork"

type Inspector = {
  _id: string
  firstName: string
  lastName: string
  phone: string
  email: string
  city: string
  role: string
}

const InspectorStore = createStore(
  {
    inspectors: asyncState<Inspector[]>([]),
    availableInspectors: asyncState<Inspector[]>([]),
    sortedInspector: "",
    statusCalender: "",
  },
  {
    setInspectors: async (_, __: null, { asyncAction }) =>
      asyncAction("inspectors", getInspectors()),
    setAvailableInspectors: async (_state, payload, { asyncAction }) =>
      asyncAction(
        "availableInspectors",
        getAvailableInspectors(payload?.fromDate, payload?.toDate)
      ),
    setSortedInspector: (state, sortedInspector: string) => ({
      ...state,
      sortedInspector,
    }),
    setStatus: (state, statusCalender: string) => ({
      ...state,
      statusCalender,
    }),
  }
)

export default InspectorStore
