import { asyncState, createStore } from "global-hook-store"
import addWork, { WorkData } from "../api/addWork"
import deleteWork from "../api/deleteWork"
import getWork from "../api/getWork"
import getWorkFiles from "../api/getWorkFiles"
import getWorks from "../api/getWorks"
import getWorksByMonth from "../api/getWorksByMonth"
import updateWork from "../api/updateWork"
import { history } from "../pages/Admin"

type Search = { isSearching: boolean; name: string; address: string }
type AdditionalService = {
  name?: string
  price?: number
}
export type BlockedDate = {
  createdAt: Date
  description: string
  from: Date
  inspectorId: string
  to: Date
  updatedAt: Date
  _id: string
}

// export type CalendarEvents = [BlockedDate & WorkData]
export type Job = {
  objectAddress?: string
  objectCity?: string
  clientName?: string
  price?: number
  inspection?: string
  dateOfInspection?: null | Date
  endDateOfInspection?: null | Date
  status?: string
  _id?: string
  additionalService?: AdditionalService[]
}
type CalendarWorks = {
  jobs: Job[]
  blockedDates: BlockedDate[]
}
const workStore = createStore(
  {
    work: asyncState<WorkData>(),
    workId: "",
    // work: {} as WorkData,
    // works: [] as WorkData[],
    works: asyncState<WorkData[]>([]),
    calendarWorks: asyncState<CalendarWorks>({ jobs: [], blockedDates: [] }),
    createdWork: asyncState<WorkData>(),
    updatedWork: asyncState<WorkData>(),
    isRequestLoading: false,
    isDeleteRequestLoading: false,
    createWorkError: undefined,
    isSearching: false,
    searchValueName: "",
    searchValueAddress: "",
    // isLoadingWorks: false,
    isLoadingMore: false,
    workError: false,
    isPdfLoading: false,
    loadMore: true,
    deleteWorkResponse: asyncState<any>(),
  },
  {
    setWork: async (state, workIdd: string, { asyncAction }) => {
      return asyncAction("work", getWork(workIdd))
    },
    createWork: async (_state, payload, { asyncAction }) =>
      asyncAction(
        "createdWork",
        addWork(payload.data, payload.saveToCustomerRegistry)
      ),
    updateWork: async (_state, { id, job }, { asyncAction }) =>
      asyncAction("updatedWork", updateWork(id, job)),
    deleteWork: async (_state, id, { asyncAction }) =>
      asyncAction("deleteWorkResponse", deleteWork(id)),
    loadMoreWorks: async (state, payload) => {
      try {
        const moreWorks = await getWorks(
          payload.status,
          payload.clientName,
          payload.objectAddress,
          payload.fromIndex,
          payload.inspector
        )
        const wholeObj = state.works
        wholeObj["data"] = [...state.works.data, ...moreWorks]

        return {
          ...state,
          works: wholeObj,
          isLoadingMore: false,
          loadMore: moreWorks.length < 20 ? false : true,
        }
      } catch {
        return { ...state, isLoadingMore: false }
      }
    },
    setWorks: async (_state, payload, { asyncAction }) =>
      asyncAction(
        "works",
        getWorks(
          payload.status,
          payload.clientName,
          payload.objectAddress,
          payload.fromIndex,
          payload.inspector
        )
      ),
    setCalendarWorks: async (_state, payload, { asyncAction }) =>
      asyncAction(
        "calendarWorks",
        getWorksByMonth(
          payload.month,
          payload.year,
          payload.sortedInspector,
          payload.statusCalender
        )
      ),
    setWorkId: (state, workId: string) => ({ ...state, workId }),
    setSearch: (state, isSearching: Search) => ({
      ...state,
      isSearching: isSearching.isSearching,
      searchValueName: isSearching.name,
      searchValueAddress: isSearching.address,
    }),

    setLoadingWorks: (state, isLoadingWorks: boolean) => ({
      ...state,
      isLoadingWorks,
    }),
    setRequestLoading: (state, isRequestLoading: boolean) => ({
      ...state,
      isRequestLoading,
    }),
    setDeleteRequestLoading: (state, isDeleteRequestLoading: boolean) => ({
      ...state,
      isDeleteRequestLoading,
    }),
    setWorkError: (state, workError: boolean) => ({
      ...state,
      workError,
    }),
    setPdfLoading: (state, isPdfLoading: boolean) => ({
      ...state,
      isPdfLoading,
    }),
    setLoadMore: (state, loadMore: boolean) => ({
      ...state,
      loadMore,
    }),
    resetWork: (_, __: null, { reset }) => reset("work"),
    resetWorks: (_, __: null, { reset }) => reset("works"),
  }
)

export default workStore
