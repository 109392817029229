import { asyncState, createStore } from "global-hook-store"
import addUser, { Inspector } from "../api/addUser"
import getUsers from "../api/getUsers"
import getUser from "../api/getUser"
import { history } from "../pages/Admin"
import updateUser from "../api/updateUser"
import deleteUser from "../api/deleteUser"
import addBlockedDate from "../api/addBlockedDate"

const userStore = createStore(
  {
    users: asyncState<Inspector[]>([]),
    user: asyncState<Inspector>(),
    searchValue: "",
    userError: false,
    isRequestLoading: false,
    isDeleteRequestLoading: false,
    blockedError: false,
    isBlockedRequestLoading: false,
  },
  {
    setUsers: async (_, __: null, { asyncAction }) =>
      asyncAction("users", getUsers()),
    setUser: async (state, userId: string, { asyncAction }) =>
      asyncAction("user", getUser(userId)),
    setSearchValue: (state, searchValue: string) => ({ ...state, searchValue }),
    createUser: async (state, payload) => {
      try {
        await addUser(payload)
        history.push("/users")
        return {
          ...state,
          isRequestLoading: false,
        }
      } catch {
        return { ...state, isRequestLoading: false, userError: true }
      }
    },
    updateUser: async (state, payload) => {
      try {
        await updateUser(payload.id, payload.user)
        history.push("/users")
        return {
          ...state,
          isRequestLoading: false,
        }
      } catch {
        return { ...state, isRequestLoading: false, userError: true }
      }
    },
    deleteUser: async (state, id: string) => {
      try {
        await deleteUser(id)
        history.push("/users")
        return {
          ...state,
          isDeleteRequestLoading: false,
        }
      } catch {
        return { ...state, isDeleteRequestLoading: false, userError: true }
      }
    },
    setRequestLoading: (state, isRequestLoading: boolean) => ({
      ...state,
      isRequestLoading,
    }),
    setDeleteRequestLoading: (state, isDeleteRequestLoading: boolean) => ({
      ...state,
      isDeleteRequestLoading,
    }),
    setUserError: (state, userError: boolean) => ({
      ...state,
      userError,
    }),
  }
)

export default userStore
