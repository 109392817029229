import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import DateTimePicker from "components/AddBlockedDate/DateTimePicker"
import SubmitButton from "components/AddBlockedDate/SubmitButton"

import colors from "ui"
import { useForm } from "react-hook-form"
import FormSection from "components/FormSection"
import JobInput from "components/JobInput"

import addBlockedDate from "api/addBlockedDate"

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "30px 5px",
    color: colors.zodiac,
  },
  errorMessage: {
    color: "red",
    fontFamily: "Montserrat",
    margin: 0,
  },
}))

type RequestState = { success: boolean; error: boolean; errorMessage: string }
type Props = { userId: string; fullWidth?: boolean }

const AddBlockedDate: React.FC<Props> = ({ userId, fullWidth }) => {
  const classes = useStyles()
  const { register, handleSubmit, setValue, errors } = useForm<any>()

  const initialDate: any = new Date()

  // ---- START TIME OF INSPECTION
  const [selectedDate, setSelectedDate] = useState<null | Date>(null)

  // ---- END TIME OF INSPECTION
  const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const [requestState, setRequestState] = useState<RequestState>({
    success: false,
    error: false,
    errorMessage: "",
  })

  const onSubmit = async (data: any) => {
    if (selectedDate && selectedEndDate) {
      if (selectedEndDate > selectedDate) {
        // RESET requestState
        setRequestState({
          success: false,
          error: false,
          errorMessage: "",
        })

        setLoading(true)
        try {
          const body = {
            inspectorId: userId,
            description: data.description,
            to: selectedEndDate,
            from: selectedDate,
          }

          // await addBlockedDate(body)
          setLoading(false)

          // CLEAR DATA
          setSelectedDate(null)
          setSelectedEndDate(null)
          setValue("description", "")

          // SET STATE
          setRequestState({
            error: false,
            errorMessage: "",
            success: true,
          })

          // RESET STATE AFTER 2s
          setTimeout(() => {
            setRequestState({
              success: false,
              error: false,
              errorMessage: "",
            })
          }, 2000)
        } catch (err) {
          console.error(err)
          setLoading(false)

          // SET ERROR STATE
          setRequestState({
            success: false,
            error: true,
            errorMessage: "Något gick fel, försök igen",
          })

          // RESET ERROR STATE AFTER 5s
          setTimeout(() => {
            setRequestState({
              success: false,
              error: false,
              errorMessage: "",
            })
          }, 5000)
        }
      } else {
        // SET ERROR STATE
        setRequestState({
          success: false,
          error: true,
          errorMessage: "Slutdatum måste vara senare än startdatum",
        })

        // RESET ERROR STATE AFTER 5s
        setTimeout(() => {
          setRequestState({
            success: false,
            error: false,
            errorMessage: "",
          })
        }, 5000)
      }
    } else {
      // SET ERROR STATE
      setRequestState({
        success: false,
        error: true,
        errorMessage: "Ange både start- och sluttid",
      })

      // RESET ERROR STATE AFTER 5s
      setTimeout(() => {
        setRequestState({
          success: false,
          error: false,
          errorMessage: "",
        })
      }, 5000)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
      <FormSection title="Upptagen" fullWidth={fullWidth}>
        <DateTimePicker
          title="Startdatum"
          id="start-date"
          initialDate={initialDate}
          selectedDate={selectedDate}
          changeDate={setSelectedDate}
        />
        <DateTimePicker
          title="Slutdatum"
          id="end-date"
          initialDate={initialDate}
          selectedDate={selectedEndDate}
          changeDate={setSelectedEndDate}
        />
        <JobInput
          name="description"
          label="Notering"
          register={register()}
          multiline
        />
        {requestState.error && (
          <p className={classes.errorMessage}>{requestState.errorMessage}</p>
        )}

        <SubmitButton isLoading={loading} success={requestState.success} />
      </FormSection>
    </form>
  )
}

export default AddBlockedDate
