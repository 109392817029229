import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { CircularProgress, Theme } from "@material-ui/core"

type Props = WithStyles<typeof styles>

const FileLoading: React.FC<Props> = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    wrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      opacity: 0.5,
      zIndex: 1,
    },
  })

export default withStyles(styles)(FileLoading)
