import React, { useEffect } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"
import { useForm } from "react-hook-form"
import { history } from "./Admin"

import { TextField, Button, Theme, CircularProgress } from "@material-ui/core"
import authStore from "../store/authStore"
import useStore from "global-hook-store"

type Props = {} & WithStyles<typeof styles>

const Login: React.FC<Props> = ({ classes }) => {
  const { register, handleSubmit, errors } = useForm()
  const {
    state: { isLoginLoading },
    actions: { setLoginLoading, login },
  } = useStore(authStore)

  const onSubmit = (data: any): void => {
    setLoginLoading(true)
    const email: string = data.email
    const password: string = data.password
    login({ email, password })
  }

  useEffect(() => {
    history.push("/")
  }, [])
  return (
    <>
      {isLoginLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={classes.form}
          >
            <h1>Välkommen till Doem</h1>
            <p>logga in för att fortsätta</p>
            <TextField
              inputRef={register({
                required: true,
                minLength: 5,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
              margin="normal"
              name="email"
              placeholder="Mail"
              type="email"
              variant="outlined"
              error={errors.email && true}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
            />
            <TextField
              inputRef={register({
                required: "PASSWORD REQUIRED",
                minLength: 5,
              })}
              margin="normal"
              name="password"
              placeholder="Lösenord"
              type="password"
              variant="outlined"
              error={errors.password && true}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
            />
            <Button type="submit" className={classes.loginButton}>
              Logga in
            </Button>
          </form>
        </div>
      )}
    </>
  )
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors.alice,
      [breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    loading: {
      display: "flex",
      height: "100vh",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "inherit",
      color: colors.zodiac,
      width: "400px",
      "& h1": {
        margin: 0,
      },
      "& p": {
        margin: 0,
        padding: "0 0 50px",
      },
      [breakpoints.down("xs")]: {
        width: "80%",
        paddingTop: "30%",
        "& h1": {
          fontSize: "20px",
        },
      },
    },
    textField: {
      width: "100%",
      marginBottom: "10px",
      margin: 0,
    },
    input: {
      padding: "15px 10px",
      fontFamily: "Montserrat",
    },
    outline: {
      borderRadius: "0",
      border: "none",
      borderBottom: "1px solid lightgray",
    },
    forgotPassword: {
      fontSize: "15px",
      color: "#5d5d5d",
      cursor: "pointer",
      margin: 0,
      padding: "5px 0 15px 0 !important",
      alignSelf: "flex-start",
    },
    loginButton: {
      width: "100%",
      backgroundColor: colors.picton,
      color: "white",
      borderRadius: "2.5px",
      padding: "10px 0",
      marginTop: "10px",
      fontWeight: 700,
      letterSpacing: "1px",
    },
  })

export default withStyles(styles)(Login)
