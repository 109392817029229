import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { Theme, Grid } from "@material-ui/core"

type Props = {} & WithStyles<typeof styles>

const ToolbarContainer: React.FC<Props> = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 0",
      [breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        padding: "15px 0",
      },
    },
  })

export default withStyles(styles)(ToolbarContainer)
