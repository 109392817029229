const translateRole = (role?: string) => {
  switch (role) {
    case "inspector":
      return "Besiktningsman"

    case "admin":
      return "Admin"

    case "sales":
      return "Säljare"

    default:
      return ""
  }
}

export default translateRole
