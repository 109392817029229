import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import { Theme, Grid } from "@material-ui/core"

type Props = {} & WithStyles<typeof styles>

const DateContainer: React.FC<Props> = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      [breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
        marginBottom: "10px",
        "& :nth-child(1)": {
          margin: "2.5px 0px 0px 0px  !important",
        },
      },
    },
  })
export default withStyles(styles)(DateContainer)
