import React, { useEffect } from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../ui"

import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import FormSection from "../components/FormSection"
import JobInput from "../components/JobInput"
import addInspector from "../api/addUser"
import { history } from "./Admin"
import useStore from "global-hook-store"
import UserStore from "../store/UserStore"
import ErrorDisplay from "components/ErrorDisplay"

type Inspector = {
  _id: string
  firstName: string
  lastName: string
  phone: string
  email: string
  city: string
}

type Props = {} & WithStyles<typeof styles>

const NewUser: React.FC<Props> = ({ classes }) => {
  const {
    state: { isRequestLoading, userError },
    actions: { createUser, setRequestLoading },
  } = useStore(UserStore)

  const { register, handleSubmit, errors, control } = useForm<Inspector>()

  const onSubmit = (data: Inspector) => {
    setRequestLoading(true)
    createUser(data)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
      {userError && <ErrorDisplay />}
      <Grid container>
        <FormSection paddingTop={true} title="Användare">
          <JobInput
            name="firstName"
            label="Förnamn"
            error={errors.firstName && true}
            register={register({
              required: "REQUIRED",
              minLength: 1,
            })}
          />
          <JobInput
            name="lastName"
            label="Efternamn"
            error={errors.lastName && true}
            register={register({
              required: "REQUIRED",
              minLength: 1,
            })}
          />

          <JobInput
            name="city"
            label="Ort"
            error={errors.city && true}
            register={register()}
          />
        </FormSection>
        <FormSection paddingTop={true} title="Uppgifter">
          <JobInput
            name="phone"
            label="Telefon"
            error={errors.phone && true}
            register={register()}
          />
          <JobInput
            name="email"
            label="Mail"
            error={errors.email && true}
            register={register({
              required: "REQUIRED",
              minLength: 1,
            })}
          />
        </FormSection>
        <Grid className={classes.buttonContainer} xs={12} container item>
          <Button
            type="submit"
            disabled={isRequestLoading}
            className={classes.button}
            classes={{ disabled: classes.addDisabled }}
          >
            {isRequestLoading ? (
              <CircularProgress size="25px" style={{ color: "white" }} />
            ) : (
              "Skapa användare"
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "30px 5px",
      color: colors.zodiac,
      backgroundColor: colors.white,
      [breakpoints.down("sm")]: {
        paddingTop: "0px",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "100px 25px 0 25px",
    },
    button: {
      width: "200px",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
    },
    leadContainer: {
      marginTop: "20px",
      width: "100%",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#F5F7F9",
      margin: "7.5px 0",
      maxHeight: "56px",
    },
    select: {
      width: "100%",
      maxHeight: "56px",
      padding: "17.5px 14px",
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      color: "gray",
      fontSize: "14px",
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
  })
export default withStyles(styles)(NewUser)
