const statusToColor = (status?: string) => {
  switch (status) {
    case "canceled":
      return "#FD4D4D"

    case "unprocessed":
      return "#c5c5c5"

    case "booked":
      return "#63ADF2"

    case "ongoing":
      return "#F930F1"

    case "sent":
      return "#F9C030"

    case "completed":
      return "#1EE21A"

    default:
      return "white"
  }
}

export default statusToColor
