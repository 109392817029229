import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import colors from "../../ui"
import { history } from "../../pages/Admin"
import { Job } from "./DesktopItem"
import { Theme } from "@material-ui/core"
import statusToColor from "../../utils/statusToColor"
type Props = {
  isAdmin: boolean
  id?: string
} & Job &
  WithStyles<typeof styles>

const JobItem: React.FC<Props> = ({
  classes,
  client,
  address,
  city,
  status,
  inspection,
  dateOfInspection,
  price,
  isAdmin,
  additionalServicePrice,
  timeOfInspection,
  id,
}) => {
  const tjena = () => {
    // TODO: GET ROLES FROM BACKEND
    if (isAdmin) {
      history.push(`jobs/${id}`)
    } else {
      history.push("/jobs/prepurchaseinspection")
    }
  }

  let formatter = new Intl.NumberFormat("se-SE", {
    style: "currency",
    currency: "SEK",
  })

  const totPrice = (price?: number, additionalServicePrice?: number) => {
    let parsedPrice = price ? price : 0
    let parsedAdditionalServicePrice = additionalServicePrice
      ? additionalServicePrice
      : 0

    return parsedPrice + parsedAdditionalServicePrice
  }

  const totalPrice = formatter.format(totPrice(price, additionalServicePrice))
  return (
    <div onClick={() => tjena()} className={classes.itemContainer}>
      <div className={classes.item}>
        <div
          style={{ backgroundColor: statusToColor(status) }}
          className={classes.statusBar}
        />
        <div className={classes.left}>
          <p className={classes.highlight}>{client}</p>
          <p>{address}</p>
          <p>{city}</p>
          <p>{inspection}</p>
        </div>
        <div className={classes.right}>
          <p>{dateOfInspection}</p>
          <p>{timeOfInspection}</p>
          <p>{isAdmin ? totalPrice : "10:00 - 15:00"}</p>
        </div>
      </div>
    </div>
  )
}

const styles = ({ spacing, breakpoints, mixins }: Theme) =>
  createStyles({
    itemContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "175px",
      backgroundColor: "transparent",
    },
    item: {
      cursor: "pointer",
      display: "flex",

      alignItems: "center",
      justifyContent: "space-between",
      // boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.1)",
      width: "99%",
      height: "150px",
      backgroundColor: "white",
      zIndex: -1,
      borderRadius: "5px",
      "& p": {
        margin: 0,
        fontSize: "16px",
        textTransform: "capitalize",
      },
    },
    highlight: {
      fontSize: "16px",
      fontWeight: 500,
    },
    status: {
      color: "white",
      padding: "5px 15px",
      borderRadius: "20px",
      fontSize: "12px",
      letterSpacing: 1,
      textTransform: "capitalize",
    },
    left: {
      padding: "15px 0 15px 15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "65%",
      height: "100%",
    },
    right: {
      padding: "15px 15px 15px 0",

      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "35%",
      height: "100%",
    },
    statusBar: {
      width: "6px",
      height: "100%",
      backgroundColor: "lightgray",
    },
  })

export default withStyles(styles)(JobItem)
