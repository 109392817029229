import React, { FC, useEffect, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import colors from "ui"
import DialogContent from "@material-ui/core/DialogContent"
import {
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  Switch,
  Theme,
  Tooltip,
} from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import JobInput from "components/JobInput"
import { WorkData } from "api/addWork"
import DateTimePicker from "components/AddBlockedDate/DateTimePicker"
import InspectorStore from "store/InspectorStore"
import useStore from "global-hook-store"
import usePrevious from "utils/usePrevious"
import Services from "data/Services"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import AllowedRole from "components/AllowedRole"
import { history } from "pages/Admin"
import workStore from "store/workStore"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up("md")]: {
        maxHeight: "700px",
        minHeight: "700px",
        padding: "8px 24px",
      },

      padding: "8px 12px",
      "&::-webkit-scrollbar": {
        width: 0,
        background: "transparent",
      },
    },
    switchContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    switchLabel: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      color: colors.zodiac,
    },
    switchTrack: {
      backgroundColor: "lightgray",
    },
    switchBase: {
      color: "lightgray",

      "&.Mui-checked": {
        color: colors.picton,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: colors.picton,
        opacity: 0.5,
      },
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      padding: "20px 0 10px",
    },
    submitButton: {
      backgroundColor: colors.picton,
      color: colors.white,
      textTransform: "none",
      fontFamily: "Montserrat",
      marginLeft: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      borderRadius: "2px",
    },
    cancelButton: {
      color: colors.picton,
      textTransform: "none",
      fontFamily: "Montserrat",
      padding: "6px 12px",
      fontSize: "14px",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#F5F7F9",
      margin: "7.5px 0",
      maxHeight: "56px",
    },
    select: {
      width: "100%",
      maxHeight: "56px",
      padding: "18.5px 14px",
      border: "1px solid #E4E4E4",
      borderRadius: "2.5px",
      color: "rgba(0, 0, 0, 0.54)",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
    },
    label: {
      fontFamily: "Montserrat",
      margin: 0,
      color: "gray",
      fontSize: "14px",
    },
    sectionContainer: {
      marginTop: "20px",
      width: "100%",
    },
    button: {
      width: "100%",
      height: "50px",
      borderRadius: 0,
      fontSize: "14px",
      textTransform: "initial",
      fontFamily: "Montserrat",
      color: colors.white,
      backgroundColor: colors.picton,
    },
    addDisabled: {
      backgroundColor: "lightgray",
      color: "white",
    },
    selectMenu: { maxHeight: "250px" },
  })
)

type DateError = {
  error: boolean
  message: string
}

type Props = { dateFrom?: Date; close: () => void }

const AddWork: FC<Props> = ({ dateFrom, close }) => {
  const classes = useStyles()

  const { register, handleSubmit, errors, control } = useForm<any>()

  const {
    state: { availableInspectors },
    actions: { setAvailableInspectors },
  } = useStore(InspectorStore)

  const {
    state: { createdWork },
    actions: { createWork },
  } = useStore(workStore)

  // SAVE CUSTOMER STATE
  const [saveToCustomerRegistry, setSaveToCustomerRegistry] = useState<boolean>(
    false
  )

  const [dateError, setDateError] = useState<DateError>({
    message: "",
    error: false,
  })

  const initialDate: any = new Date()

  // ---- START TIME OF INSPECTION
  const [selectedDate, setSelectedDate] = useState<null | Date>(null)

  // ---- END TIME OF INSPECTION
  const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null)

  useEffect(() => {
    // ----- DEFAULT HOURS LOGIC -----
    // Default hours to 12:00 when date is comming from calendar
    const dateDefaultHours = dateFrom && new Date(dateFrom.setHours(12, 0))

    // Set start date to dateDefaultHours
    dateDefaultHours && setSelectedDate(dateDefaultHours)

    // ----- PLUS ONE HOUR LOGIC -----
    // new Date() so that we dont manipulate "dateFrom"
    const datePlusOneHour = dateFrom && new Date(dateFrom)

    // Increase hour by one
    datePlusOneHour?.setHours(datePlusOneHour?.getHours() + 1)

    // Update endDate with datePlusOneHour
    datePlusOneHour && setSelectedEndDate(datePlusOneHour)

    // Get available inspectors
    setAvailableInspectors({
      fromDate: null,
      toDate: null,
    })
  }, [])

  // Convert date to string so that useEffect triggers on all changes
  const selectedDateString = selectedDate?.toString()
  const selectedEndDateString = selectedEndDate?.toString()
  const prevSelectedDateString = usePrevious(selectedDateString)
  const prevSelectedEndDateString = usePrevious(selectedEndDateString)

  useEffect(() => {
    // RESET INSPECTORS
    if (prevSelectedDateString && prevSelectedEndDateString) {
      if (selectedDate && selectedEndDate) {
        // IF A START AND END DATE IS CHOOSEN THEN GET INSPECTORS FOR THAT PERIOD
        setAvailableInspectors({
          fromDate: selectedDate,
          toDate: selectedEndDate,
        })
      } else {
        // IF A START AND END DATE IS NOT CHOOSEN THEN GET ALL INSPECTORS
        setAvailableInspectors({
          fromDate: null,
          toDate: null,
        })
      }
    }
  }, [selectedDateString, selectedEndDateString])

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveToCustomerRegistry(event.target.checked)
  }

  // SUBMIT DATA
  const onSubmit = async (data: WorkData) => {
    if (selectedDate && selectedEndDate) {
      data["dateOfInspection"] = selectedDate
      data["endDateOfInspection"] = selectedEndDate

      if (selectedEndDate > selectedDate) {
        // Reset error state if there is any active error
        setDateError({ error: false, message: "" })

        // SET STATUS DEPENDING IF INSPECTOR IS CHOOSEN
        if (data.inspector?.length > 0) {
          data["status"] = "booked"
        } else {
          data["status"] = "unprocessed"
        }

        const res = await createWork({
          data,
          saveToCustomerRegistry,
        })

        const createdWorkResponse = res.createdWork

        // IF ERROR - RETURN
        if (createdWorkResponse.error) {
          // Set error messsage here if needed
          return
        }

        // TODO - FINNS DET BÄTTRE SÄTT ATT LÖSA DETTA PÅ?
        history.push(`/jobs/${createdWorkResponse.data?._id}`)
      } else {
        // User must apply an end date that i sgreater than the start date
        setDateError({
          message: "Slutdatum måste vara senare än startdatum",
          error: true,
        })
      }
    } else {
      // User must apply a start and end date
      setDateError({
        message: "Välj ett slut- och startdatum",
        error: true,
      })
    }
  }

  return (
    <>
      <DialogContent classes={{ root: classes.content }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <JobInput
            name="clientName"
            label="Namn"
            error={errors.clientName && true}
            register={register({ required: "REQUIRED" })}
          />
          <JobInput
            name="clientPhone"
            label="Telefon"
            error={errors.clientPhone && true}
            register={register()}
          />
          <JobInput
            name="clientMail"
            label="Mail"
            error={errors.clientMail && true}
            register={register()}
          />
          <JobInput
            name="objectAddress"
            label="Adress"
            error={errors.objectAddress && true}
            register={register()}
          />
          <JobInput
            name="objectZipCode"
            label="Postnummer"
            error={errors.objectZipCode && true}
            register={register({
              setValueAs: (value) => parseInt(value.replace(/\s/g, "")),
            })}
          />
          <JobInput
            name="objectCity"
            label="Ort"
            error={errors.objectCity && true}
            register={register()}
          />

          {/* TODO: MAKE COMPONENT OUT OF THIS */}
          <div className={classes.sectionContainer}>
            <p className={classes.label}>Tjänst</p>
            <FormControl
              className={classes.formControl}
              error={errors.inspection && true}
            >
              <Controller
                as={
                  <Select
                    disableUnderline
                    value=""
                    classes={{
                      root: classes.select,
                    }}
                    MenuProps={{ classes: { paper: classes.selectMenu } }}
                  >
                    {Services &&
                      Services.map((service: any) => (
                        <MenuItem
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "Montserrat",
                          }}
                          key={service.service}
                          value={service.service}
                        >
                          {service.service}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="inspection"
                control={control}
                defaultValue=""
              />
            </FormControl>
          </div>

          {/* TODO: MAKE COMPONENT OUT OF THIS */}
          <AllowedRole roles={["admin", "sales"]}>
            <div className={classes.sectionContainer}>
              <p className={classes.label}>Besiktningsmän</p>
              <FormControl
                className={classes.formControl}
                error={errors.inspector && true}
              >
                <Controller
                  name="inspector"
                  control={control}
                  defaultValue={[]}
                  render={({ onChange, value, name }) => (
                    <Select
                      name={name}
                      disableUnderline
                      multiple
                      value={value}
                      disabled={createdWork.loading}
                      classes={{
                        root: classes.select,
                      }}
                      MenuProps={{ classes: { paper: classes.selectMenu } }}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      input={<Input />}
                      renderValue={(selected) => {
                        var findCorrectInspectors = (selected as any[]).map(
                          (value) => {
                            let inspector = availableInspectors?.data.find(
                              (x) => x._id === value
                            )
                            return `${inspector?.firstName} ${inspector?.lastName}`
                          }
                        )
                        return (findCorrectInspectors as string[]).join(", ")
                      }}
                    >
                      {availableInspectors &&
                        availableInspectors?.data?.map((inspector: any) => {
                          const isAvailableInObject = inspector.hasOwnProperty(
                            "available"
                          )
                          const isInspectorAvailable = () => {
                            if (isAvailableInObject) {
                              if (inspector?.available) {
                                return true
                              } else {
                                return false
                              }
                            } else {
                              return true
                            }
                          }

                          return (
                            <MenuItem
                              key={inspector._id}
                              value={inspector._id}
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ margin: 0 }}>
                                  {`${inspector.firstName} ${inspector.lastName}`}
                                </p>
                                {!isInspectorAvailable() && (
                                  <Tooltip title="Besiktningsmannen är antingen upptagen eller redan utbokad på arbete den här perioden">
                                    <ErrorOutlineOutlinedIcon
                                      fontSize="small"
                                      htmlColor={colors.error}
                                      style={{ marginLeft: "5px" }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              <p style={{ margin: 0, color: "gray" }}>
                                {inspector.city}
                              </p>
                            </MenuItem>
                          )
                        })}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          </AllowedRole>

          <DateTimePicker
            title="Startdatum"
            id="start-date"
            initialDate={initialDate}
            selectedDate={selectedDate}
            changeDate={setSelectedDate}
          />

          <DateTimePicker
            title="Slutdatum"
            id="end-date"
            initialDate={selectedDate ? selectedDate : initialDate}
            selectedDate={selectedEndDate}
            changeDate={setSelectedEndDate}
          />
          <p
            style={{
              fontFamily: "Montserrat",
              color: colors.error,
              margin: 0,
            }}
          >
            {dateError.error && dateError.message}
          </p>
          <div className={classes.switchContainer}>
            <FormControlLabel
              classes={{ label: classes.switchLabel }}
              control={
                <Switch
                  color="primary"
                  name="saveToCustomerRegistry"
                  classes={{
                    track: classes.switchTrack,
                    switchBase: classes.switchBase,
                  }}
                  checked={saveToCustomerRegistry}
                  onChange={handleSwitchChange}
                />
              }
              label="Spara kund"
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={close}
              style={{
                marginRight: "10px",
                backgroundColor: colors.white,
                border: `1px solid ${colors.picton}`,
                color: colors.picton,
              }}
              className={classes.button}
            >
              Avbryt
            </Button>
            <Button
              type="submit"
              disableRipple
              disabled={createdWork.loading}
              className={classes.button}
              classes={{ disabled: classes.addDisabled }}
              key={`${createdWork.loading}`}
            >
              {createdWork.loading ? (
                <CircularProgress size="25px" style={{ color: "white" }} />
              ) : (
                "Skapa arbete"
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </>
  )
}

export default AddWork
