import * as React from "react"

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

import Login from "./Login"
import Admin from "./Admin"
import authStore from "../store/authStore"
import useStore from "global-hook-store"
import { useEffect } from "react"
import getCurrentUser from "../api/getCurrentUser"
import { history } from "./Admin"
import CircularProgress from "@material-ui/core/CircularProgress"
import InspectorStore from "store/InspectorStore"

type Props = {} & WithStyles<typeof styles>

const App: React.FC<Props> = ({ classes }) => {
  /*USE REDIRECT INSTEAD OF CONDITIONAL RENDERING? */
  const {
    state: { isLoading, isLoggedIn, role, id },
    actions: { setCurrentUser },
  } = useStore(authStore)

  const {
    state: {},
    actions: { setSortedInspector },
  } = useStore(InspectorStore)

  useEffect(() => {
    setCurrentUser()
  }, [])

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>{isLoggedIn ? <Admin /> : <Login />}</div>
      )}
    </>
  )
}

const styles = () =>
  createStyles({
    root: {
      fontFamily: "Montserrat",
    },
    loading: {
      display: "flex",
      height: "100vh",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  })

export default withStyles(styles)(App)
